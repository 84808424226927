import React, { Component } from 'react';

import Label from '../../modules/label.jsx';

export default class SingleCampaignChannels extends Component{
  render(){
    const { currentCampaign, featuresStates } = this.props,
          channels = currentCampaign.get('canales');

    let labels = [];
    if(channels && channels.size){
      if(channels.includes('email'))
        labels.push(<Label key={ 'label-email' } type={ 'warning' } label={ 'Email' } />)
      if(channels.includes('sms'))
        labels.push(<Label key={ 'label-sms' } type={ 'warning' } label={ 'SMS' } />)
      if(channels.includes('whatsapp') && featuresStates.get('features').get('whatsapp'))
        labels.push(<Label type={ 'warning' } label={ 'Whatsapp' } />)
      if(channels.includes('voz') && featuresStates.get('features').get('voz'))
        labels.push(<Label type={ 'voz' } label={ 'Voz' } />)
    }

    if(labels.length){
      return (
        <li>
          <span className="term">Canales:</span>
          <span className="definition">
            { labels }
          </span>
        </li>
      );
    }

    return null;
  }
}