import React, { Component } from 'react';
import FilterSelector from '../modules/filter-selector';

export default class EnterpriseDropdownNavigation extends Component {
    shouldComponentUpdate = (nextProps) => {
        //Sólo cuando cambia la empresa debe recargar todo ??
        const { adminEnterprisesStates } = this.props,
        empresaId = adminEnterprisesStates.getIn(["selectedEnterprise", "id"]),
        nextEmpresaId = nextProps.adminEnterprisesStates.getIn(["selectedEnterprise", "id"]);
        if(empresaId !== nextEmpresaId){
            window.sessionStorage.setItem('enterprise',nextEmpresaId);
            this.props.history.go(0);
        }
        return true;
    }
    handledClick = (event) => {
        const { entities } = this.props,
            enterprises = entities.get('enterprises').toJS(),
            enterpriseSelected = enterprises.filter(e=>e.id==event.target.value)[0];
        
        this.props.changeEnterprise(enterpriseSelected);
    }
    content = () => {
        const { currentUser } = this.props,
            enterpriseSelected = currentUser.getIn(["userData","empresa"]);
        return (
            <FilterSelector
                labelClass="relative-position m-b-0"
                name="campaign-states"
                value={enterpriseSelected}
                onChangeFunc={this.handledClick}
                options={this.getEnterpriseOptions()}
            />
        )
    }
    getEnterpriseOptions() {
        const { entities, adminEnterprisesStates } = this.props,
            empresaId = adminEnterprisesStates.getIn(["selectedEnterprise", "id"]),
            empresaNombre = adminEnterprisesStates.getIn(["selectedEnterprise", "nombre"]);
        let options = [];
        options.push({
            key: empresaId,
            value: empresaNombre
        })
        entities.get('enterprises').forEach((data) => {
            if (empresaId !== data.get('id')) {
                options.push({
                    key: data.get('id'),
                    value: data.get('nombre')
                });
            }
        })
        return options;
    }
    render() {
        return this.content()
    }
}