import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

import Tabs from '../modules/tabs';
import IsFetching from '../modules/is-fetching';
import UserList from '../modules/user-list';

export default class CampaignUserList extends Component{
  componentWillMount(){
    const { currentUser, campaign } = this.props,
          userData = currentUser.get('userData');

    this.props.getClientsByCampaignId(userData.get('username'), campaign.get('id_campana'));
  }
  getContactedClients(filteredClients){
    return filteredClients.filter((data) => {
      return data.get('contacted');
    });
  }
  getUncontactedClients(filteredClients){
    return filteredClients.filter((data) => {
      return !data.get('contacted');
    });
  }
  getFilteredClients(){
    const { entities, campaignStates } = this.props,
          filterValue = campaignStates.get('userFilter');

    let list = [];

    entities.get('clients').forEach((data) => {
      if(filterValue && data.get('name').toLowerCase().search(filterValue.toLowerCase()))
        return;
  
      list.push(data);
    });
  
    return fromJS(list)
    
  }
  render(){
    const { campaignStates, clientsStates, campaign } = this.props,
          filteredClients = this.getFilteredClients(),
          contactedClients = this.getContactedClients(filteredClients),
          uncontactedClients = this.getUncontactedClients(filteredClients);

    return(
        <div className="gray-bg wrapper wrapper-vertical relative-position">
          <IsFetching isFetching={clientsStates.get('isFetching')}>
            <Tabs 
                activeTab={campaignStates.get('tabs').get('clientActivetab')} 
                changeDashboardTab={this.props.changeClientsCampaignTab}
                buttonsHolderClass={ 'tabs-buttons-holder small inner horizontal-padding' }
                tabsHolderClass={'tabs-panels-holder'}
                tabs = {[
                  {
                    title: 'Contactados',
                    counter: () => {
                      return <span className="badge small">{ contactedClients.size}</span>
                    },
                    content: () => {
                      return (
                        <div className="scrolled-box bigger min-box">
                          <UserList users={contactedClients} campaign={ campaign } {...this.props}/>
                        </div>
                      )
                    }
                  },
                  {
                    title: 'No contactados',
                    counter: () => {
                      return <span className="badge small">{ uncontactedClients.size}</span>
                    },
                    content: () => {
                      return (
                        <div className="scrolled-box bigger min-box">
                          <UserList users={uncontactedClients} />
                        </div>
                      )
                    }
                  },
                ]}
              />
            </IsFetching>
        </div>
    );
  }
}

CampaignUserList.propTypes = {
  campaign: PropTypes.object
}