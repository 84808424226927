import * as Immutable from 'immutable';
import moment from 'moment';

const campaignForm = {
    template: '',
    startDate: moment().startOf('day'),
    endDate: moment().startOf('day').add(7, 'days'),
    exceptionDays: [],
    sendTime: '08',
    concurrency: 'Todos los días',
    userCanSelectConcurrency: false,
    dias_envio: ['1', '2', '3', '4', '5'],
    email: false,
    sms: false,
    whatsapp: false,
    voz: false,
    webpay: false,
    urlPay: false,
    urlPayValue: '',
    sendDates: [],
    emailTemplate: '',
    emailMarkup: '',
    emailMarkupPlain: '',
    smsMarkup: '',
    smsMarkupPlain: '',
    buttonTextColor: "#00FFBF",
    buttonBackgroundColor: "#e91f2c",
    buttonText: "Contáctenos",
    campaignType: "preventiva",
    id_procesamiento: 0
}

const updateUploadActions = {
    fileName: '',
    file: false,
    isFetching: false,
    error: '',
    success: ''
}

export function campaignStates(state = Immutable.fromJS({
    isFetching: true,
    isFetchingForm: true,
    isFetchingMoreCampaign: false,
    isFetchingCampaignCSV: false,
    isFetchingClientCampaignCSV: false,
    isEditing: false,
    formError: '',
    error: '',
    tabs: {
        clientsContacted: [],
        responses: [],
        retainedPayments: [],
        recoverData: [],
        satisfaction: [],
        openingPercentage: [],
        paidCustomersAssigned: [],
        historyActivetab: 0,
        clientActivetab: 0,
        clientsActivetab: 0,
        historicCounter: false,
        isFetchingContacts: true,
        isFetchingSatisfaction: true,
        isFetchingRetainedPayments: true,
        isFetchingRecoverData: true,
        fetching: [],
        counters: {}
    },
    fetchingTabs: {
        clientsContacted: true,
        recoverData: true,
        retainedPayments: true,
        openingPercentage: true,
        paidCustomersAssigned: true
    },
    b64: {
        openingPercentage: '',
        clientsContacted: '',
        retainedPayments: '',
        recoverData: '',
        paidCustomersAssigned: ''
    },
    isFetchingData: false,
    activeStep: 0,
    activeChannel: false,
    completedSteps: 0,
    uploadActions: {
        fileName: '',
        file: false,
        isFetching: false,
        messages: {
            success: {},
            warnings: {},
            errors: {},
            token: '',
            clients: [],
            b64file: ''
        }
    },
    updateUploadActions: updateUploadActions,
    exclusionUploadActions: {
        fileName: '',
        file: false,
        isFetching: false,
        messages: {
            success: {},
            warnings: {},
            errors: {}
        }
    },
    campaignForm: campaignForm,
    userFilter: '',
    campaignFilter: '',
    campaignStatusFilter: '',
    campaignTemplateFilter: '',
    setCampaignFilter: '',
    setCampaignStatusFilter: '',
    setCampaignTemplateFilter: '',
    singleCampaignData: {
        clients: {
            isFetchingConversation: false,
            voucher: '',
            conversation: []
        }
    },
    clientsFilter: [],
    viewSwitch: 'card'
}), action) {
    switch (action.type) {
        case 'CLEAN_NEW_CAMPAIGN':
            return state.withMutations((map) => {
                map.set('activeStep', 0)
                    .set('campaignForm', Immutable.fromJS(campaignForm))
            });
        case 'SWITCH_CAMPAIGN_VIEW':
            return state.set('viewSwitch', action.value)
        case 'SUBMIT_CAMPAIGN_UPLOAD_FILE_START':
            return state.withMutations((map) => {
                map.setIn(['updateUploadActions', 'isFetching'], true)
                map.setIn(['updateUploadActions', 'error'], '')
                map.setIn(['updateUploadActions', 'success'], '')
            });
        case 'SUBMIT_CAMPAIGN_UPLOAD_FILE_ERROR':
            return state.withMutations((map) => {
                map.setIn(['updateUploadActions', 'isFetching'], false)
                map.setIn(['updateUploadActions', 'error'], action.error)
            });
        case 'SUBMIT_CAMPAIGN_UPLOAD_FILE_DATA':
            return state.withMutations((map) => {
                map.setIn(['updateUploadActions', 'isFetching'], false)
                map.setIn(['updateUploadActions', 'success'], action.msg)
            });
        case 'SET_MODAL':
            return state.withMutations((map) => {
                if (!action.modalSlug) {
                    map.set('formError', '')
                        .set('activeStep', 0)
                        .set('uploadActions', Immutable.fromJS({
                            fileName: '',
                            file: false,
                            isFetching: false,
                            messages: {
                                success: {},
                                warnings: {},
                                errors: {},
                                token: '',
                                clients: [],
                                b64file: ''
                            }
                        }))
                        .set('campaignForm', Immutable.fromJS(campaignForm))
                } else {
                    return state;
                }
            })
        case 'GET_CAMPAIGNS_START':
            return state.withMutations((map) => {
        map.set('isFetching', true)
            .set('error', '');  
    });
        case 'GET_CAMPAIGNS_END':
            return state.set('isFetching', false);
        case 'SET_CAMPAIGN_STEP_FORM':
            return state.set('activeStep', action.step)
        case 'GET_TEMPLATES':
            return state.set('isFetchingForm', true)
        case 'GET_TEMPLATE_END':
            return state.withMutations((map) => {
                map.set('isFetchingForm', false)
            });
        case 'INPUT_FORM_CAMPAIGN':
            return state.setIn(['campaignForm', action.target], action.value);
        case 'SELECT_TEMPLATE_CAMPAIGN':
            return state.withMutations((map) => {
                map.setIn(['campaignForm', 'template'], action.value)
                    .set('uploadActions', Immutable.fromJS({
                        fileName: '',
                        file: false,
                        isFetching: false,
                        messages: {
                            success: {},
                            warnings: {},
                            errors: {},
                            token: '',
                            clients: [],
                            b64file: ''
                        }
                    }))
            });
        case 'SELECT_CAMPAIGN_CSV_FILE':
            return state.withMutations((map) => {
                map.setIn(['uploadActions', 'fileName'], action.file.name)
                    .setIn(['uploadActions', 'file'], Immutable.fromJS(action.file))
            });
        case 'SELECT_CAMPAIGN_UPDATE_FILE':
            return state.withMutations((map) => {
                map.setIn(['updateUploadActions', 'fileName'], action.file.name)
                    .setIn(['updateUploadActions', 'file'], Immutable.fromJS(action.file))
            });
        case 'SELECT_SINGLE_CAMPAIGN_CSV_FILE':
            return state.withMutations((map) => {
                map.setIn(['exclusionUploadActions', 'fileName'], action.file.name)
                    .setIn(['exclusionUploadActions', 'file'], Immutable.fromJS(action.file))
            });
        case 'SUBMIT_CAMPAIGN_CSV_FILES_START':
            return state.setIn(['uploadActions', 'isFetching'], true);
        case 'SUBMIT_SINGLE_CAMPAIGN_CSV_FILES_START':
            return state.setIn(['exclusionUploadActions', 'isFetching'], true);
        case 'SUBMIT_CAMPAIGN_CSV_FILE_END':
            return state.withMutations((map) => {
                map.setIn(['uploadActions', 'messages', 'success'], Immutable.fromJS(action.data.success))
                    .setIn(['uploadActions', 'messages', 'warnings'], Immutable.fromJS(action.data.warnings))
                    .setIn(['uploadActions', 'messages', 'errors'], Immutable.fromJS(action.data.errors))
                    .setIn(['uploadActions', 'messages', 'token'], Immutable.fromJS(action.data.success.token))
                    .setIn(['uploadActions', 'messages', 'clients'], Immutable.fromJS(action.data.success.clients))
                    .setIn(['uploadActions', 'messages', 'b64file'], Immutable.fromJS(action.data.success.b64file))
                    .setIn(['uploadActions', 'isFetching'], false)
                    .setIn(['uploadActions', 'fileName'], '')
                    .setIn(['campaignForm', 'id_procesamiento'], Immutable.fromJS(action.data.success.id_procesamiento))
            });
        case 'SUBMIT_SINGLE_CAMPAIGN_CSV_FILE_END':
            return state.withMutations((map) => {
                map.setIn(['exclusionUploadActions', 'messages', 'success'], Immutable.fromJS(action.data.success))
                    .setIn(['exclusionUploadActions', 'messages', 'warnings'], Immutable.fromJS(action.data.warnings))
                    .setIn(['exclusionUploadActions', 'messages', 'errors'], Immutable.fromJS(action.data.errors))
                    .setIn(['exclusionUploadActions', 'isFetching'], false)
            });
        case 'ADD_CAMPAIGN_PAY_DAY':
            return state.updateIn(['campaignForm', 'dias_envio'], data => data.push(action.day))
        case 'REMOVE_CAMPAIGN_PAY_DAY':
            return state.deleteIn(['campaignForm', 'dias_envio', action.index.toString()])
        case 'CREATE_CAMPAIGN_START':
            return state.withMutations((map) => {
                map.set('isFetchingForm', true)
                    .set('formError', '')
            });
        case 'CREATE_CAMPAIGN_END':
            return state.set('isFetchingForm', false)
        case 'EDIT_CAMPAIGN':
            return state.set('isEditing', action.data)
        case 'GET_OPENING_CAMPAIGN_PERCENT_DATA_BY_CAMPAIGN_START':
            return state.set('isFetchingData', true);
        case 'GET_OPENING_CAMPAIGN_PERCENT_DATA_BY_CAMPAIGN_END':
            return state.withMutations((map) => {
                map.set('isFetchingData', false)
                    .setIn(['tabs', 'historicData'], Immutable.fromJS(action.data))
                    .setIn(['tabs', 'historicCounter'], action.clients)
                    .setIn(['b64', 'openingPercentage'], action.b64)
            })
        case 'CHANGE_CAMPAIGN_HISTORY_TAB':
            return state.setIn(['tabs', 'historyActivetab'], action.index)
        case 'CHANGE_CAMPAIGN_CLIENTS_TAB':
            return state.setIn(['tabs', 'clientsActivetab'], action.index)
        case 'CHANGE_CLIENTS_CAMPAIGN_TAB':
            return state.setIn(['tabs', 'clientActivetab'], action.index)
        case 'GET_HISTORY_CAMPAIGN_DATA_BY_CATEGORIES_END':
            return state.withMutations((map) => {
                map.updateIn(['tabs', 'fetching'], data => data.push(action.categoryId))
                    .setIn(['tabs', action.categoryId], Immutable.fromJS(action.data))
                    .setIn(['tabs', 'counters', action.categoryId], action.counter)
            });
        case 'FILTER_USER_CAMPAIGN':
            return state.set('userFilter', action.value)
        case 'OPEN_USER_CONVERSATION_START':
            return state.setIn(['singleCampaignData', 'clients', 'isFetchingConversation'], true)
        case 'OPEN_USER_CONVERSATION_END':
            return state.withMutations((map) => {
                map.setIn(['singleCampaignData', 'clients', 'isFetchingConversation'], false)
                    .setIn(['singleCampaignData', 'clients', 'conversation'], Immutable.fromJS(action.data))
            });
        case 'OPEN_USER_VOUCHER_START':
            return state.setIn(['singleCampaignData', 'clients', 'isFetchingConversation'], true);
        case 'OPEN_USER_VOUCHER_END':
            return state.withMutations((map) => {
                map.setIn(['singleCampaignData', 'clients', 'isFetchingConversation'], false)
                    .setIn(['singleCampaignData', 'clients', 'voucher'], action.data)
            });
        case 'GET_SINGLE_CAMPAIGN_CONTACTS_START':
            return state.setIn(['tabs', 'isFetchingContacts'], true)
        case 'GET_SINGLE_CAMPAIGN_CONTACTS_END':
            return state.withMutations((map) => {
                map.setIn(['tabs', 'isFetchingContacts'], false)
                    .setIn(['tabs', 'contacts'], Immutable.fromJS(action.data))
                    .setIn(['b64', 'clientsContacted'], action.b64)
            });
        case 'GET_CLIENTS_RESPONSES_BY_ID_START':
            return state.setIn(['tabs', 'isFetchingResponses'], true)
        case 'GET_CLIENTS_RESPONSES_BY_ID_END':
            return state.withMutations((map) => {
                map.setIn(['tabs', 'isFetchingResponses'], false)
                    .setIn(['tabs', 'responses'], Immutable.fromJS(action.data))
            });
        case 'GET_SINGLE_CAMPAIGN_SATISFACTION_START':
            return state.setIn(['tabs', 'isFetchingSatisfaction'], true);
        case 'GET_SINGLE_CAMPAIGN_SATISFACTION_END':
            return state.withMutations((map) => {
                map.setIn(['tabs', 'isFetchingSatisfaction'], false)
                    .setIn(['tabs', 'satisfaction'], Immutable.fromJS(action.data))
                    .setIn(['tabs', 'satisfactionKpis'], Immutable.fromJS(action.kpis))
            });
        case 'CANCEL_CAMPAIGN_START':
            return state.set('isFetchingForm', true);
        case 'CANCEL_CAMPAIGN_END':
            return state.set('isFetchingForm', false);
        case 'FILTER_CAMPAIGN':
            return state.set('campaignFilter', action.value)
        case 'FILTER_STATUS_CAMPAIGN':
            return state.set('campaignStatusFilter', action.value)
        case 'FILTER_TEMPLATE_CAMPAIGN':
            return state.set('campaignTemplateFilter', action.value)
        case 'ADD_CAMPAIGN_EXCEPTION_DAY':
            return state.updateIn(['campaignForm', 'exceptionDays'], data => data.push(action.date));
        case 'REMOVE_CAMPAIGN_EXCEPTION_DAY':
            return state.deleteIn(['campaignForm', 'exceptionDays', action.index.toString()])
        case 'GET_CAMPAIGNS_ERROR':
            return state.withMutations((map) => {
                map.set('isFetching', false)
                    .set('error', action.error);
            })
        case 'GET_MORE_CAMPAIGNS_START':
            return state.withMutations((map) => {
                map.set('isFetchingMoreCampaign', true)
                    .set('isFetching', true)
            });
        case 'GET_MORE_CAMPAIGNS_END':
            return state.withMutations((map) => {
                map.set('isFetchingMoreCampaign', false)
                    .set('isFetching', false)
            });
        case 'SET_FILTERS_CAMPAIGNS':
            return state.withMutations((map) => {
                map.set('setCampaignTemplateFilter', action.campaignTemplateFilter)
                    .set('setCampaignStatusFilter', action.campaignStatusFilter)
                    .set('setCampaignFilter', action.campaignFilter)
            })
        case 'SET_SEND_DATES':
            return state.setIn(['campaignForm', 'sendDates'], action.dates)
        case 'CREATE_CAMPAIGN_ERROR':
            return state.withMutations((map) => {
                map.set('formError', action.error)
                    .set('isFetchingForm', false)
            })
        case 'DOWNLOAD_CAMPAIGN_CSV':
            return state.set('isFetchingCampaignCSV', true);
        case 'DOWNLOAD_CAMPAIGN_CSV_END':
            return state.set('isFetchingCampaignCSV', false);
        case 'DOWNLOAD_CAMPAIGN_CLIENTS_CSV':
            return state.set('isFetchingClientCampaignCSV', true);
        case 'DOWNLOAD_CAMPAIGN_CLIENTS_CSV_END':
            return state.set('isFetchingClientCampaignCSV', false);
        case 'GET_RETAINED_PAYMENT_START':
            return state.setIn(['tabs', 'isFetchingRetainedPayments'], true)
        case 'GET_RETAINED_PAYMENT_END':
            return state.withMutations((map) => {
                map.setIn(['tabs', 'isFetchingRetainedPayments'], false)
                    .setIn(['tabs', 'retainedPayments'], Immutable.fromJS(action.data))
                    .setIn(['b64', 'retainedPayments'], action.b64)
            });
        case 'GET_RECOVER_DATA_START':
            return state.setIn(['tabs', 'isFetchingRecoverData'], true)
        case 'GET_RECOVER_DATA_END':
            return state.withMutations((map) => {
                map.setIn(['tabs', 'isFetchingRecoverData'], false)
                    .setIn(['tabs', 'recoverData'], Immutable.fromJS(action.data))
                    .setIn(['b64', 'recoverData'], action.b64)
            });
        case 'LOADING_DASHBOARD_DATA':
            return state.setIn(['fetchingTabs', action.slug], true);
        case 'LOADING_DASHBOARD_DATA_END':
            return state.withMutations((map) => {
                map.setIn(['tabs', action.slug], Immutable.fromJS(action.data))
                    .setIn(['b64', action.slug], Immutable.fromJS(action.b64))
                    .setIn(['fetchingTabs', action.slug], false);
            });
        case 'SELECT_EMAIL_TEMPLATE_CAMPAIGN':
            return state.setIn(['campaignForm', 'emailTemplate'], action.value);
        case 'CHANGE_CHANNEL_DATA':
            return state.withMutations((map) => {
                map.setIn(['campaignForm', action.target], action.editorState)
                    .setIn(['campaignForm', action.target + 'Plain'], action.targetMarkup)
            });
        case 'ON_CHANGE_CHANNEL':
            return state.set('activeChannel', action.step)
        default:
            return state;
    }
}
