import React, { Component } from 'react';

import AssistantWrapper from './assistant-wrapper';
import AssistantWrapperFilters from './assistant-wrapper-filters';

export default class SettingsAssistantContent extends Component{
  render(){
    return(
      <div className="row inner-content clearfix">
          <AssistantWrapper { ...this.props } />
          <AssistantWrapperFilters { ...this.props } />
        </div>
    );
  }
}