import React, { Component } from 'react';
import moment from 'moment';

import ChartHolder from '../charts/chart-holder';
import DashboardLineChart from '../charts/line-chart';
import IsFetching from '../modules/is-fetching.jsx';
import { getIdEnterprise } from '../../utilities/utilities';

export default class DashboardAssistant extends Component{
  render(){
    const { dashboardStates, currentUser } = this.props,
          startDate = moment(dashboardStates.get('startDate')).format('YYYY/MM/DD'),
          endDate = moment(dashboardStates.get('endDate')).format('YYYY/MM/DD');

    return(
      <div className="row">
        <h3 className="relative-position">Indicadores de asistente</h3>
        <ChartHolder
          title={ 'Cantidad de Interacciones' }
          getData={ this.props.getDashboardData.bind(null, {
            slug: 'interactionsAssistant',
            endpoint: 'asistente/DASHBOARD_INTERACCIONES',
            ord: 'ord',
            data: {
              usuario: currentUser.getIn(['userData','username']),
              empresa: getIdEnterprise(this.props),
              id_campana: '',
              fecha_inicio: startDate,
              fecha_termino: endDate
            }
          }) }
          b64={ this.props.dashboardStates.get('b64').get('interactionsAssistant') }
        >
          <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('interactionsAssistant') } >
              <DashboardLineChart 
                data={ dashboardStates.get('data').get('interactionsAssistant') } 
                yAxisLabel={ 'Interacciones' }
              />
          </IsFetching>
        </ChartHolder>
      </div>
    );
  }
}