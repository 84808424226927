import React, { Component } from 'react';

import Label from '../../modules/label.jsx';

export default class SingleCampaignPayways extends Component{
  render(){
    const { currentCampaign } = this.props,
          payWays = currentCampaign.get('formas_pago');

    let labels = [];

    if(payWays && payWays.size){
      if(payWays.includes('webpay'))
        labels.push(<Label type={ 'warning' } label={ 'Webpay' } />)
      if(payWays.includes('url'))
        labels.push(<Label type={ 'warning' } label={ 'URL' } />)
    }

    if(labels.length){
      return (
        <li>
          <span className="term">Formas de pago:</span>
          <span className="definition">
            { labels }
          </span>
        </li>
      );
    }

    return null;
  }
}