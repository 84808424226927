import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Label
} from "recharts";
import FallbackMessage from "../modules/fallback-message";

export default class DashboardPieChart extends Component {
  shouldComponentUpdate(prevProps) {
    return (
      prevProps.data !== this.props.data ||
      prevProps.tooltipFormatter !== this.props.tooltipFormatter ||
      prevProps.dataKeys !== this.props.dataKeys ||
      prevProps.id !== this.props.id ||
      prevProps.centerInfo !== this.props.centerInfo
    );
  }
  getPorc = (data) => {
    let newData = [];
    data.forEach((elem,i) => {
      let obj = {},
        array = [];
      //Si es (Cantidad) será el primer array
      if(i===0){
        obj.value = parseFloat((100 - (elem[1].value * 100 / elem[0].value)).toFixed(0));
        obj.name = this.props.dataKeys[i].key[0];
        array.push(obj);
        obj = {};
        obj.value = parseFloat((elem[1].value * 100 / elem[0].value).toFixed(0));
        obj.name = this.props.dataKeys[i].key[1];
        array.push(obj);
      }else{//Será montos
        obj.value = parseFloat((100 - (elem[1].value * 100 / elem[0].value)).toFixed(0));
        obj.name = this.props.dataKeys[i].key[0];
        array.push(obj);
        obj = {};
        obj.value = parseFloat((elem[1].value * 100 / elem[0].value).toFixed(0));
        obj.name = this.props.dataKeys[i].key[1];
        array.push(obj);
      }
      newData.push(array);
    });
    return newData;
  }
  customLabel = (rcData) => {
    return (
      <text style={{fontSize:'12px'}}>
        <tspan
          x={rcData.index === 0 ? rcData.x - 60 : rcData.x - 50}
          y={rcData.index === 0 ? rcData.y - 15 : rcData.y + 10}
        >
          {rcData.name}
        </tspan>
        <tspan
          x={rcData.index === 0 ? rcData.x - 20 : rcData.x - 20}
          y={rcData.index === 0 ? rcData.y : rcData.y + 25}
        >{`${rcData.value}%`}</tspan>
      </text>
    );
  };
  getInfo = (data) => {
    return data.map((map) => {
      const value = new Intl.NumberFormat('es-CL', { currency: 'CLP' }).format(map.value);
      return (
        <React.Fragment>
          <h4>{value}</h4>
          <p>{map.name}</p>
        </React.Fragment>
      );
    });
  };
  getPies() {
    const { dataKeys, id, centerInfo } = this.props,
      data = this.getPorc(this.props.data.toJSON());
    return dataKeys.map((value, index) => {
      if (centerInfo) {
        return (
          <div className="charts-pie">
            <ResponsiveContainer
              key={`${id}-${index}`}
              width="100%"
              height={300}
            >
              <PieChart
                width={300}
                height={300}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip formatter={ (data) => { return Math.round(data) + '%' } }/>
                <Pie
                  cx="50%"
                  cy="50%"
                  data={data[index]}
                  dataKey={"value"}
                  nameKey={"name"}
                  innerRadius={70}
                  outerRadius={100}
                  label={this.customLabel.bind(data[index])}
                >
                  {value.colors.map((entry, ind) => {
                    return <Cell key={`cell-${ind}`} fill={entry} />;
                  })}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            {<div className="chart-info in">{this.getInfo(this.props.data.toJSON()[index])}</div>}
          </div>
        );
      } else {
        return (
          <ResponsiveContainer key={`${id}-${index}`} width="50%" height={300}>
            <PieChart
              width={600}
              height={300}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip />
              <Pie
                cx="50%"
                cy="50%"
                data={data[index]}
                dataKey={"value"}
                nameKey={"name"}
                fill={value.color}
                innerRadius={70}
                outerRadius={100}
                label
              />
            </PieChart>
          </ResponsiveContainer>
        );
      }
    });
  }
  render() {
    const data = this.props.data.toJSON(),
      pies = this.getPies();
    let content;
    if (data.length > 0) {
      content = <div className="d-flex">{pies}</div>;
    } else {
      content = (
        <FallbackMessage content="No se han encontrado datos para visualizar" />
      );
    }

    return content;
  }
}

DashboardPieChart.propTypes = {
  data: PropTypes.object,
  tooltipFormatter: PropTypes.func,
  dataKeys: PropTypes.array,
  id: PropTypes.string,
  centerInfo: PropTypes.bool
};

DashboardPieChart.defaultProps = {
  formatYAxis: tick => {
    return tick;
  },
  tooltip: data => {
    return data;
  },
  dataKeys: [
    {
      key: ["Clientes No Pagados","Clientes Pagados"],
      colors: ["#fb999f", "#98e1a4"]
    },
    {
      key: ["Montos No Pagados","Montos Pagados"],
      colors: ["#fb999f", "#98e1a4"]
    }
  ],
  centerInfo: true
};
