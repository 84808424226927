import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { entities } from './entities';
import { currentUser } from './current-user';
import { loginStates } from './login';
import { dashboardStates } from './dashboard';
import { templatesStates } from './templates';
import { generalStates } from './general';
import { campaignStates } from './campaigns';
import { usersStates } from './users';
import { clientsStates } from './clients';
import { assistantSettingsStates } from './assistant-settings';
import { assistantStates } from './assistant';
import { featuresStates } from './features';
import { channelsConfigStates } from './channel-config';
import { adminEnterprisesStates } from './admin-enterprises';
 
export const reducers = combineReducers({
  entities,
  generalStates,
  currentUser,
  loginStates,
  dashboardStates,
  templatesStates,
  campaignStates,
  usersStates,
  clientsStates,
  assistantSettingsStates,
  assistantStates,
  featuresStates,
  channelsConfigStates,
  adminEnterprisesStates
});