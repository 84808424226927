import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InnerHeader extends Component{
  constructor(props){
    super(props);

    this.goBack = this.goBack.bind(this)
  }
  goBack(){
    this.props.backButton();
  }
  render(){
    let badge = null,
        disclaimerText = null,
        backButton = null;

    if(this.props.counter)
      badge = <span className="badge">{ this.props.counter }</span>

    if(this.props.disclaimerText)
      disclaimerText = <span className={ 'small-text-disclaimer m-l-10 '+ this.props.status }>{ this.props.disclaimerText }</span>

    if(this.props.backButton)
      backButton = <button className="btn btn-back btn-icon" onClick={ this.goBack }><i className="icon-element back"></i></button>
    

    return(
      <header className={'inner-header '+ this.props.additionalClass + ' '+ this.props.status}>
        <div className="inner-content">
          {backButton}
          <div className="inner-header-content-holder">
            <h1 className="inner-content-title">
              { this.props.title }
              {/* { disclaimerText } */}
              { badge }
            </h1>
            <span className="inner-content-subtitle">{ this.props.subtitle }</span>
          </div>
          { this.props.children }
        </div>
      </header>
    )
  }
}

InnerHeader.propTypes = {
  title: PropTypes.string,
  additionalClass: PropTypes.string,
  status: PropTypes.string,
  subtitle: PropTypes.string,
  disclaimerText: PropTypes.string,
  backButton: PropTypes.func
}