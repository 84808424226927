import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class UserListDropdown extends Component{
  constructor(props){
    super(props);

    this.excludeUser = this.excludeUser.bind(this);
    this.openConversation = this.openConversation.bind(this);
    this.openVoucher = this.openVoucher.bind(this);
  }
  excludeUser(event){
    event.preventDefault();
    const { user, campaign } = this.props,
          text = '¿Estás seguro que quieres eliminar el cliente '+ user.get('name') +' de la campaña '+ campaign.get('id') +'?',
          func = this.props.getClientsByCampaignId.bind(null, user.get('id'), campaign.get('id'))

    this.props.confirmData(text, func);
  }
  openConversation(event){
    event.preventDefault();
    const { user, campaign } = this.props;

    this.props.openUserConversation(user.get('id'), campaign.get('id'));
  }
  openVoucher(event){
    event.preventDefault();
    const { user, campaign } = this.props;

    this.props.openUserVoucher(user.get('id'), campaign.get('id'));
  }
  getList(){
    const { user, campaign } = this.props;

    let list = [];

    if(!user.get('excluded'))
      list.push(<li key="exclution-list"><a className="red-text" href="#" title="Excluir usuario" onClick={ this.excludeUser }>Excluir</a></li>);

    if(user.get('conversation'))
      list.push(<li key="conversation-list"><a href="#" title="Conversación del usuario" onClick={ this.openConversation }>Ver conversación</a></li>);

    if(user.get('voucher'))
      list.push(<li key="voucher-list"><a href="#" title="Comporbante del usuario" onClick={ this.openVoucher }>Ver comprobante</a></li>);

    return list;
  }
  render(){
    const list = this.getList();
    
    return(
      <ul>
        { list }
      </ul>
    );
  }
}

UserListDropdown.propTypes = {
  user: PropTypes.object,
  campaign: PropTypes.object,
}
