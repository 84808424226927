import React, { Component } from 'react';

import StepFormChannelMenu from './step-form-channel/step-form-channel-menu';
import StepFormChannelContent from './/step-form-channel/step-form-channel-content';
import StepFormChannelButton from './step-form-channel/step-form-channel-button';

export default class StepFormChannels extends Component{
  constructor(props){
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }
  onSubmitHandler(event){
    event.preventDefault();
    this.props.changeCampaignStepForm(3)
  }
  render(){
    return (
      <div className="row">
        <h3 className="align-center">Configuración de canales de envío de la campaña</h3>
        <div style={{textAlign:'center',marginBottom:'12px'}}>
          <span className="step-form-channel-menu-title" style={{cursor: 'auto',fontSize: '12px',fontWeight: '500'}}>
            <b style={{color: '#ff0505'}}>Importante:</b> El canal "Voz" no se puede seleccionar en conjunto con alguno de los canales "Email" o "SMS"</span>
        </div>
        <form method="POST" action="#" className="form-wrapper big" onSubmit={ this.onSubmitHandler }>
          <div className="box no-padding clearfix">
            <StepFormChannelMenu { ...this.props } />
            <StepFormChannelContent {  ...this.props } />
            <StepFormChannelButton { ...this.props } />
          </div>
        </form>
      </div>
    );
  }
}