import React, { Component } from 'react';

import ChartHolder from '../charts/chart-holder';

export default class CampaignSMSTemplate extends Component{
  render(){
    const { currentCampaign } = this.props,
          content = currentCampaign.get('sms_markup') ? currentCampaign.get('sms_markup') : 'No hay texto para el SMS'

    return(
      <ChartHolder
        title={ 'Plantilla de SMS' }
        chartHolderClass={ '' }
      >
        <div className="box no-shadow">
          <p>{ content }</p>
        </div>
      </ChartHolder>
    );
  }
}