import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Kpi extends Component{
  render(){
    return (
      <div className="kpi">
        <span className="kpi-value">{ this.props.valueData }</span>
        <span className="kpi-key">{ this.props.keyData }</span>
      </div>
    );
  }
}

Kpi.propTypes = {
  keyData: PropTypes.string,
  valueData: PropTypes.any
}