import React, { Component } from 'react';

import InnerHeader from '../modules/inner-header';
import NotFoundData from '../modules/not-found-data';
import TemplatesTable from './templates-table';
import FilterField from '../modules/filter-field';
import IsFetching from '../modules/is-fetching';
import { getIdEnterprise } from '../../utilities/utilities';

export default class Templates extends Component{
  constructor(props){
    super(props);

    this.handleNewTemplateButton = this.handleNewTemplateButton.bind(this);
    this.filterTemplates = this.filterTemplates.bind(this);
  }

  componentWillMount(){
    this.callAsyncData()
  }

  callAsyncData(){
    const  { currentUser } = this.props,
           userData = currentUser.get('userData');

    this.props.getTemplates(userData.get('username'),getIdEnterprise(this.props));
  }
  filterTemplates(event){
    this.props.filterTemplatebyString(event.target.value);
  }
  handleNewTemplateButton(event){
    this.props.setModal('templateForm');
  }
  getContent(){
    const { entities, templatesStates } = this.props,
          templates = entities.get('templates'),
          error = templatesStates.get('error');

    let content, searchFilter;

    if(error){
      content = (
        <NotFoundData 
          text={ error }
          buttonText="Intentar nuevamente"
          buttonFunc={() => this.callAsyncData()}
        />
       );
    }else{
      // Si templates.size = 0, entonces es false, si es != 0 entonces es true.
      if(templates.size){
        content = <TemplatesTable { ...this.props } />;
        searchFilter = (
          <FilterField 
            name="template-filter"
            value={ templatesStates.get('filters').get('search') }
            onChangeFunc={ this.filterTemplates }
            placeholder = "Buscar plantilla..."
          />
        );
      }else{
        content = (
          <NotFoundData 
            text="Todavía no se ha cargado ninguna plantilla" 
            buttonText="Nueva plantilla"
            buttonFunc={ this.handleNewTemplateButton }
          />
        );
      }
    }
    return content;
  }
  render(){
    const {templatesStates, entities} = this.props, 
          content = this.getContent();

    let searchFilter;

    return (
      <IsFetching isFetching={ templatesStates.get('isFetching') }>
        <InnerHeader title="Plantillas" counter={ entities.get('templates').size } >
          <div className="header-actions-holder">
            { searchFilter }
            <button className="btn btn-small m-l-10" onClick={ this.handleNewTemplateButton }>Nueva Plantilla</button>
          </div>
        </InnerHeader>
        <div className="inner-content animated fadeIn">
          <div className="box inner">
            { content }
          </div>
        </div>
      </IsFetching>
    );
  }
}