import React, { Component } from 'react';
import { fromJS, is } from 'immutable';
import { getTemplatesIndexBy, getIdEnterprise } from '../../utilities/utilities';

import Table from '../modules/table.jsx';
import Label from '../modules/label.jsx';

export default class TemplatesTable extends Component{
  constructor(props){
    super(props);

    this.editHandler = this.editHandler.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.goToCampaign = this.goToCampaign.bind(this);
  }
  shouldComponentUpdate(nextProps){
    return(
      !is(this.props.entities.get('templates'),nextProps.entities.get('templates')) ||
      !is(this.props.entities.get('customFields'),nextProps.entities.get('customFields')) ||
      !is(this.props.templatesStates.get('filters').get('search'),nextProps.templatesStates.get('filters').get('search')) ||
      !is(this.props.currentUser,nextProps.currentUser) 
    )
  }
  editHandler(data, event){
    this.props.editTemplate(data, data.get('customFields'), data.get('categories'));
  }
  confirmDelete(data, event){
    const { entities, currentUser } = this.props, 
          text = '¿Estás seguro que quieres eliminar la plantilla '+ data.get('nombre_plantilla') +'?',
          func = this.props.deleteTemplate.bind(null, getTemplatesIndexBy(data.get('id_plantilla'), entities.get('templates')), data.get('id_plantilla'), currentUser.get('userData').get('username'), getIdEnterprise(this.props));

    this.props.confirmData(text, func);
  }
  goToCampaign(data){
    this.props.history.push('/campanas/'+ data)
  }
  getLabels(data, type){
    return data.map((item) => {
      return <Label key={ 'label-'+ item } type={ type } label={ item } onClickHander={ this.goToCampaign.bind(null, item) } />
    });
  }
  getActionButtons(data){
    return (
      <div className="table-action-buttons-holder">
        <button className="btn btn-table" onClick={ this.confirmDelete.bind(null, data) }><i className="icon-element close"></i></button>
      </div>
    )
  }
  getCustomFieldsLabels(data, type, entities){
    return data.map((item) => {
      const field = entities.filter((customField) => {
        return customField.get('id_campo') === item;
      });
      if(field.get('0') !== undefined){
        return <Label key={ 'label-'+ item } type={ type } label={ field.get('0').get('nombre') } /> 
      }
    });
  }
  getData(){
    const { entities, templatesStates } = this.props,
          filterValue= templatesStates.get('filters').get('search'),
          templates = entities.get('templates');

    let list = [];

    templates.forEach((template) => {
      
      if(filterValue && template.get('title').toLowerCase().search(filterValue.toLowerCase()))
        return;

      list.push(template);
    });


    return fromJS(list)
  }
  render(){
    const { entities } = this.props,
          data = this.getData();
    return (
        <Table 
          data={ data }
          columns={[
            {
              title: 'Nombre',
              order: true,
              func: (data) => {
                return <strong>{ data.get('nombre_plantilla') }</strong>
              }
            },
            {
              title: 'Descripción',
              order: false,
              func: (data) => {
                return data.get('descripcion')
              }
            },
            {
              title: 'Campañas',
              order: true,
              func: (data) => {
                return this.getLabels(data.get('campanas'));
              }
            },
            {
              title: 'Custom fields',
              order: true,
              func: (data) => {
                return this.getCustomFieldsLabels(data.get('campos'), 'warning', entities.get('customFields'));
              }
            },
            {
              title: 'Plantilla',
              order: false,
              func: (data) => {
                return <a className="table-action-buttons-holder" href={data.get('b64')} download={ data.get('nombre_plantilla')+'.csv' } title="Descargar plantilla CSV generada">Descargar CSV</a>
              }
            },
            {
              title: '',
              order: false,
              func: (data) => {
                if(data.get('campanas') && !data.get('campanas').size){
                  return this.getActionButtons(data);
                }
              }
            }
          ]}
        />
    );
  }
}