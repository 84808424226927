import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IsFetching from '../is-fetching';
import Disclaimer from '../disclaimer';
import FallbackMessage from '../fallback-message';

import DetailListBySearchHeader from './detail-list-by-search-header';
import DetailListBySearchList from './detail-list-by-search-list';

export default class DetailListBySearch extends Component{
  constructor(props){
    super(props);

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }
  onSubmitHandler(event){
    event.preventDefault();

    this.props.onAddElement(this.props.categoryValue, this.props.dataType)

  }
  render(){
    return (
      <form className="detail-list-holder" onSubmit={ this.onSubmitHandler }>
        <IsFetching isFetching={ this.props.isFetching } showChildren={true}>
          <DetailListBySearchHeader { ...this.props } />
          <Disclaimer additionalClass={ 'margin-bottom-wrapp' } type={ 'error' } content={ this.props.error } />
          <DetailListBySearchList { ...this.props } />
          <label className="button-input relative-position">
            <input 
              type="text"
              name="template-category"
              value={ this.props.categoryValue }
              onChange={ this.props.onChangeAddValue }
              placeholder={ this.props.addValuePlaceholder }
            />
            <button className="btn btn-secondary field-button">
              <i className="icon-element plus"></i>
            </button>
          </label>
        </IsFetching>
      </form>
    );
  };
}

DetailListBySearch.propTypes = {
  title: PropTypes.string,
  searchValue: PropTypes.string,
  onChangeFunc: PropTypes.func,
  placeholder: PropTypes.string,
  categoryValue: PropTypes.string,
  onChangeAddValue: PropTypes.func,
  onAddElement: PropTypes.func,
  addValuePlaceholder: PropTypes.string,
  isFetching: PropTypes.bool,
  error: PropTypes.string,
  selectedElements: PropTypes.object,
  onDetailElementClick: PropTypes.func,
  dataType: PropTypes.string
}