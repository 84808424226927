import React, { Component } from 'react';

import StepFormChannelContentEmail from './step-form-channel-content-email';
import StepFormChannelContentSMS from './step-form-channel-content-sms';
import FallbackMessage from '../../modules/fallback-message';

export default class StepFormChannelContent extends Component{
  getContent(){
    const {campaignStates } = this.props;

    switch(campaignStates.get('activeChannel')){
      case 0:
        return <StepFormChannelContentEmail { ...this.props } />
      case 1:
        return <StepFormChannelContentSMS { ...this.props } />
      case 2:
        return <FallbackMessage content={ 'No hay opciones configurables para este canal' } />
      default:
        return <FallbackMessage content={ 'Seleccione un canal para configurar' } />
    }
  }
  render(){
    const content = this.getContent();

    return(
      <div className="step-form-channel-content">
        { content }
      </div>
    );
  }
}