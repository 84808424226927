import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { EditorState, Modifier, Entity, ContentState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';

import { ItalicButton, BoldButton, UnderlineButton } from 'draft-js-buttons';


const positionSuggestions = ({ state, props }) => {
  let transform;
  let transition;

  if (state.isActive && props.suggestions.length > 0) {
    transform = 'scaleY(1)';
    transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)';
  } else if (state.isActive) {
    transform = 'scaleY(0)';
    transition = 'all 0.25s cubic-bezier(.3,1,.2,1)';
  }

  return {
    transform,
    transition,
  };
};

const customSuggestionsFilter = (searchValue, suggestions) => {
  const size = (list) => (list.constructor.name === 'List'
      ? list.size
      : list.length);

  const get = (obj, attr) => (obj.get
      ? obj.get(attr)
      : obj[attr]);

  const value = searchValue.toLowerCase();
  const filteredSuggestions = suggestions.filter((suggestion) => (
    !value || get(suggestion, 'name').toLowerCase().indexOf(value) > -1
  ));
  const length = size(filteredSuggestions) < 10 ? size(filteredSuggestions) : 50;
  return filteredSuggestions.slice(0, length);
};

export default class ContentEditable extends Component{
  constructor(props){
    super(props);

    this.focus = this.focus.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

    this.mentionPlugin = false;

    this.state = {
      editorState: EditorState.createWithContent(ContentState.createFromText(this.props.editorState)),
      suggestions: this.customSuggestionsFilter('', this.props.mentions)
    }

    if(this.props.mentions){
      this.mentionPlugin = createMentionPlugin({
        mentions: this.props.mentions,
        entityMutability: 'IMMUTABLE',
        positionSuggestions,
        mentionTrigger: '@',
        mentionPrefix: '@',
      });
    }

    this.linkPlugin = createLinkPlugin();

    this.inlineToolbarPlugin = createInlineToolbarPlugin({
      structure: [
        this.linkPlugin.LinkButton
      ]
    });

    this.staticToolbarPlugin = createToolbarPlugin({
      structure: [
        BoldButton,
        ItalicButton,
        UnderlineButton
      ]
    })
  }
  focus(){
    this['editor-'+this.props.id].focus();
  }
  customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) => (list.constructor.name === 'List'
        ? list.size
        : list.length);

    const get = (obj, attr) => (obj.get
        ? obj.get(attr)
        : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) => (
      !value || get(suggestion, 'name').toLowerCase().indexOf(value) > -1
    ));
    const length = size(filteredSuggestions) < 10 ? size(filteredSuggestions) : 10;
    return filteredSuggestions.slice(0, length);
  };
  onSearchChange({ value }){
    this.setState({
      suggestions: this.customSuggestionsFilter(value, this.props.mentions),
    });
  }
  getCounter(){
    const { counter, editorState } = this.props;

    if(!counter){ return null; }
    
    let rangeClass = 'text-green';
    if(editorState.length > 100 && editorState.length < 160)
      rangeClass = 'text-yellow';
    else if(editorState.length > 159)
      rangeClass = 'text-red';

    return <span className={`character-counter`}>Número de caracteres: <span className={ rangeClass }>{ editorState.length }</span></span>
  }
  render(){
    const MentionSuggestions = this.mentionPlugin.MentionSuggestions,
          { InlineToolbar } = this.inlineToolbarPlugin,
          { Toolbar } = this.staticToolbarPlugin,
          { LinkButton } = this.linkPlugin,
          plugins = [this.mentionPlugin, this.inlineToolbarPlugin, this.staticToolbarPlugin, this.linkPlugin],
          counter = this.getCounter();

    return(
      <div>
        <div onClick={this.focus}>
          <span className="label-like">
            { this.props.title }
            { counter }
          </span>
          <Toolbar>
            {
              externalProps => (
                <div>
                    <ItalicButton {...externalProps} />
                    <BoldButton {...externalProps} />
                    <UnderlineButton {...externalProps} />
                </div>
              )
            }
          </Toolbar>
          <div className="box no-shadow content-editable-holder">
            <Editor 
              editorState={this.state.editorState}
              onChange={ (editorState) => {
                this.setState({ editorState })
                this.props.onChangeFunc(editorState);
               } }
              plugins={plugins}
              placeholder={ this.props.placeholder }
              ref={(element) => { this['editor-'+this.props.id] = element; }}
            />
            <InlineToolbar>
                {
                  externalProps => (
                    <div>
                        <LinkButton {...externalProps} />
                    </div>
                  )
                }
            </InlineToolbar>
            <div className="mention-suggestions">
              <MentionSuggestions
                onSearchChange={({ value }) => {
                  this.setState({
                    suggestions: customSuggestionsFilter(value, this.props.mentions),
                  });
                }}
                suggestions={this.state.suggestions}
                entryComponent={this.props.labelComponentRender}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ContentEditable.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  editorState: PropTypes.string,
  onChangeFunc: PropTypes.func,
  mentions: PropTypes.array,
  mentions2: PropTypes.array,
  labelComponentRender: PropTypes.func,
  placeholder: PropTypes.string,
  toolbar: PropTypes.bool,
  counter: PropTypes.bool
}

ContentEditable.defaultProps = {
  toolbar: true
}