import * as Immutable from "immutable";
const formArgs = {
  nombre: "",
  features: [],
  workspace_id: "",
  workspace_user: "",
  workspace_password: "",
  database_name: "",
  activada: 0,
  pais: 1,
  id: null
};
const formVariablesArgs = {
  alcanza_limite: false,
  asunto_error_ftp: "",
  ftp_ip: "",
  ftp_pass: "",
  ftp_port: "",
  ftp_user: "",
  limite_campana: "",
  limite_clientes: "",
  nombre_archivo_ftp: "",
  nombre_email: "",
  subject: "",
  tag: "",
  emisor: ""
};
export function adminEnterprisesStates(
  state = Immutable.fromJS({
    isFetching: true,
    error: "",
    formError: "",
    enterpriseForm: formArgs,
    enterpriseFormModal: formArgs,
    enterpriseVariablesForm : formVariablesArgs,
    enterpriseVariablesError: '',
    messageVariableForm: '',
    allFeatures: [],
    selectedEnterprise: null,
    messageForm: "",
    activeTab: 0
  }),
  action
) {
  switch (action.type) {
    case "SET_MODAL":
      if (!action.modalSlug)
        return state.set(action.modalSlug, Immutable.fromJS(formArgs));
      else return state;
    case "CHANGE_ENTERPRISE_TAB":
      return state.set("activeTab", action.index);
    case "GET_FEATURES":
      return state.set("isFetching", true);
    case "GET_FEATURES_END":
      return state.withMutations(map => {
        map.set("isFetching", false);
        map.set("allFeatures", Immutable.fromJS(action.data));
      });
    case "GET_FEATURES_ERROR":
      return state.set("error", action.data);
    case "CHANGE_ENTERPRISE_FIELDS":
      return state.setIn(["enterpriseForm", action.target], action.value);
    case "CHANGE_ENTERPRISE_MODAL_FIELDS":
      return state.setIn(["enterpriseFormModal", action.target], action.value);
    case "CHANGE_ENTERPRISE_VARIABLES_FIELDS":
      return state.setIn(["enterpriseVariablesForm", action.target], action.value);
    case "CHANGE_ENTERPRISE_FORM":
      //SAco variables del action.data y modifico lo que venga en "" por 0
      let variables = action.data.get('variables'),
        withOutVariables = action.data.delete("variables");
        variables = variables.set('limite_campana',
          variables.get('limite_campana')===""?0:variables.get('limite_campana'));
        variables = variables.set('limite_clientes',
          variables.get('limite_clientes')===""?0:variables.get('limite_clientes'));
      return state.withMutations(map => {
        map.set("enterpriseForm", withOutVariables)
          .set("enterpriseVariablesForm", variables);
      })
    case "ADD_ENTERPRISE_FEATURE":
      return state.updateIn(["enterpriseForm", "features"], data =>
        data.push(action.value)
      );
    case "REMOVE_ENTERPRISE_FEATURE":
      return state.deleteIn([
        "enterpriseForm",
        "features",
        action.index.toString()
      ]);
    case "ADD_ENTERPRISE_MODAL_FEATURE":
      return state.updateIn(["enterpriseFormModal", "features"], data =>
        data.push(action.value)
      );
    case "REMOVE_ENTERPRISE_MODAL_FEATURE":
      return state.deleteIn([
        "enterpriseFormModal",
        "features",
        action.index.toString()
      ]);
    case "CREATE_ENTERPRISE_START":
      return state.set("isFetching", true);
    case "CREATE_ENTERPRISE_END":
      return state.withMutations(map => {
        map.set("isFetching", false);
        map.set("messageForm", action.message);
      });
    case "CREATE_ENTERPRISE_ERROR":
      return state.withMutations(map => {
        map.set("isFetching", false);
        map.set("formError", action.error);
      });
    case "EDIT_ENTERPRISE_VARIABLES_START":
      return state.set("isFetching", true);
    case "EDIT_ENTERPRISE_VARIABLES_END":
      return state.withMutations(map => {
        map.set("isFetching", false);
        map.set("messageVariableForm", action.message);
      });
    case "EDIT_ENTERPRISE_VARIABLES_ERROR":
      return state.withMutations(map => {
        map.set("isFetching", false);
        map.set("enterpriseVariablesError", action.error);
      });
    case "CHANGE_ENTERPRISE":
      return state.set("selectedEnterprise", Immutable.fromJS(action.data));
    case "RESET_ENTERPRISE_FORM_MODAL":
      return state.set("enterpriseFormModal", Immutable.fromJS(formArgs));
    default:
      return state;
  }
}
