import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cancelado from "../../assets/images/cancelado.png";
import proceso from "../../assets/images/proceso.png";
import finalizado from "../../assets/images/finalizado.png";
import pausado from "../../assets/images/pausado.png";
import CampaignDays from '../modules/campaign-days';


export default class CampaignBoxDateResume extends Component{
  getDateDuration(){
    const { campaign } = this.props,
          today = moment().startOf('day'),
          startDate = moment(campaign.get('fecha_inicio')).startOf('day'),
          endDate = moment(campaign.get('fecha_termino')).startOf('day');

    return {
      duration: endDate.diff(startDate, 'days'),
      endIn: endDate.diff(today, 'days'),
      startIn: startDate.diff(today, 'days')
    }
    
  }
  // getDateDisclaimer({ endIn, startIn }){
  //   const { campaign } = this.props;

  //   let dayString = 'días',
  //       textSuccess = 'en '+ endIn +' '+dayString,
  //       textInactive = 'en ' + startIn + ' '+dayString;

  //   if(campaign.get('estado_campana') == 'en curso'){
  //     if(endIn < 1)
  //       textSuccess = 'hoy';
  //     return <span className="small-text-disclaimer warning m-l-10">Termina { textSuccess }</span>;
  //   }else if(campaign.get('estado_campana') == 'no iniciada'){
  //     if(startIn < 1)
  //       textInactive = 'hoy';
  //     return <span className="small-text-disclaimer warning m-l-10">Comienza { textInactive }</span>;
  //   }else if(campaign.get('estado_campana') == 'cancelada'){
  //     return <span className="small-text-disclaimer error m-l-10">Cancelada</span>;
  //   }else{
  //     return <span className="small-text-disclaimer warning m-l-10">Finalizada</span>;
  //   }

  //   return null;
  // }

  getDateDisclaimer({ endIn, startIn }) {
    const { campaign } = this.props;

    let imageSrc = '', altText = '';

    if (campaign.get('estado_campana') === 'en curso') {
      if (endIn < 1) {
        imageSrc = proceso
        altText = 'Hoy';
      } else {
        imageSrc = proceso;
        altText = `Termina en ${endIn} días`;
      }
    } else if (campaign.get('estado_campana') === 'no iniciada') {
      if (startIn < 1) {
        imageSrc = 'ruta-a-imagen-hoy';
        altText = 'Hoy';
      } else {
        imageSrc = 'ruta-a-imagen-comienza';
        altText = `Comienza en ${startIn} días`;
      }
    } else if (campaign.get('estado_campana') === 'cancelada') {
      imageSrc = cancelado;
      altText = 'Cancelada';
    } else {
      imageSrc = finalizado;
      altText = 'Finalizada';
    }

    return (
      <span className="small-text-disclaimer m-l-10">
        <img src={imageSrc} alt={altText} />
      </span>
    );
  }
  getTitle(){
    const { showTitle } = this.props;
    if(!showTitle){ return null }
    return <h4 className="m-b-10">Disponibilidad</h4>
  }

  getDates(){
    const { showDates, campaign } = this.props;
      // let campaignStatus = '';

      // if (campaign.get('estado_campana') === 'en curso') {
      //   campaignStatus = ' En progreso';
      // } else if (campaign.get('estado_campana') === 'no iniciada') {
      //   campaignStatus = ' No ha iniciado';
      // } else if (campaign.get('estado_campana') === 'cancelada') {
      //   campaignStatus = ' Cancelada';
      // } else {
      //   campaignStatus = ' Terminada';
      // }

let fechaInicio = new Date(campaign.get('fecha_inicio'))
let  diaInicio = fechaInicio.getDate();
let mesInicio = fechaInicio.getMonth() + 1; 
let anioInicio = fechaInicio.getFullYear();
let fechaInicioFormateada = `${diaInicio}/${mesInicio}/${anioInicio}`;

let fechaHoraCreacion = campaign.get('fecha_hora_creacion');
let fecha = new Date(fechaHoraCreacion);

let dia = fecha.getDate();
let mes = fecha.getMonth() + 1; 
let anio = fecha.getFullYear();
let fechaFormateada = `${dia}/${mes}/${anio}`;

let hora = fecha.getHours();
let minutos = fecha.getMinutes();
let minutosFormateados = minutos.toString().padStart(2, '0');
let horaFormateada = `${hora}:${minutosFormateados}`;
;
  
let fechaTermino = new Date(campaign.get('fecha_termino'))
let  diaTermino = fechaTermino.getDate();
let mesTermino = fechaTermino.getMonth() + 1;
let anioTermino = fechaTermino.getFullYear();
let fechaTerminoFormateada = `${diaTermino}/${mesTermino}/${anioTermino}`;



    // if(!showDates){ return null; }
    return (
    <div className="card">
  <div className="texto-container">
    <div className="texto-elemento">
      <div className="icono-container">
        <i className="icono inicio"></i>
      </div>
      <div className="texto-columna">
        <p className="texto-titulo">Inicio</p>
        <p className="texto-valor">{fechaInicioFormateada}</p>
      </div>
    </div>

    <div className="texto-elemento">
      <div className="icono-container">
      
      </div>
      <div className="texto-columna">
        <p className="texto-titulo">Hora</p>
        <p className="texto-valor">{campaign.get('hr_envio') + ":00 "}</p>
      </div>
    </div>

    <div className="texto-elemento">
      <div className="icono-container">
        <i className="icono carga"></i>
      </div>
      <div className="texto-columna">
        <p className="texto-titulo">Carga</p>
        <p className="texto-valor">{fechaFormateada}</p>
      </div>
    </div>

    <div className="texto-elemento">
      <div className="icono-container">
     
      </div>
      <div className="texto-columna">
        <p className="texto-titulo">Hora</p>
        <p className="texto-valor">{horaFormateada }</p>
      </div>
      
    </div>
    <div className="texto-elemento">
      <div className="icono-container">
        <i className="icono termino"></i>
      </div>
      <div className="texto-columna">
        <p className="texto-titulo">Término</p>
        <p className="texto-valor">{fechaTerminoFormateada}</p>
      </div>
      
    </div>
    <div className="texto-elemento">
      <div className="icono-container">
      
      </div>
      <div className="texto-columna">
        <p className="texto-titulo">Hora</p>
        <p className="texto-valor">24:00 </p>
      </div>
      
    </div>
  </div>
</div>
    
    );
  }
  render(){
    const { campaign } = this.props,
          dateDurations = this.getDateDuration(),
          dateDisclaimer = this.getDateDisclaimer(dateDurations),
          title = this.getTitle(),
          dates = this.getDates()
       
    
    return (
      <div className="campaign-box-wrapper ">
        { title }
        <span className="campaign-client-visualization-header">
          <div className="block-element">
          </div>
          { dates } 
        </span>
       {/* <CampaignDays days={campaign.get('dias_envio')} additionalHolderClass={'align-center'} /> */}
      </div>
    );
  }
}

CampaignBoxDateResume.propTypes = {
  campaign: PropTypes.object,
  showTitle: PropTypes.bool,
  showDates: PropTypes.bool
}

CampaignBoxDateResume.defaultProps = {
  showTitle: true,
  showDates: true
}
