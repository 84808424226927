import React, { Component, createRef } from 'react';
import Immutable from 'immutable';

import FallbackMessage from '../fallback-message';

export default class DetailListBySearchItems extends Component{
  constructor(props){
    super(props);

    this.scrolledBox = createRef();
  }
  componentDidMount(){
    if(!this.props.isFetching){
      const el = this.scrolledBox.current;
      el.scrollTop = el.scrollHeight - el.scrollTop;
    }
  }
  getCategories(){
    const { data, selectedElements } = this.props;

    return data.map((item, index) => {
      let activeClass = '',
          iconClass = 'disabled-check';

      if(selectedElements.includes(item.get('id_campo'))){
        activeClass = 'active';
        iconClass = 'check';
      }
      
      return (
        <li key={ 'list-detail-' + item.get('nombre') }>
          <a href="#" className={ activeClass } onClick={ this.props.onDetailElementClick.bind(null, item.get('id_campo')) }>
            <i className={'icon-element '+ iconClass +' inline-left'}></i>
            { item.get('nombre') }
          </a>
        </li>
      );
    });
  }
  render(){
    const {data} = this.props,
          categories = this.getCategories();

    let ulCategories = null;
    if(data.get('0').get('id_campo'))
      ulCategories = categories;
    else
      ulCategories = <FallbackMessage content={ data.get('0').get('nombre') } />

    const { error } = this.props;
    let style = error.length > 0 ? { maxHeight: '160px' } : {};

    return(
      <ul className="detail-list scrolled-box" style={style} ref={this.scrolledBox}>
        { ulCategories }
      </ul>
    );
  }
}