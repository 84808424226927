import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class Calendar extends Component{
  constructor(props){
    super(props);

    this.dateHandler = this.dateHandler.bind(this);
  }
  dateHandler(data, event){
    const { dateHandler } = this.props;

    if(dateHandler)
      dateHandler(data);
  }
  getWeeks(){
    const { weeksData, startDate, endDate, concurrency, exceptionDays, exceptionDate } = this.props,
          today = moment();

    let weekElement = [],
        cont = 1;

    weeksData.forEach((week, index) => {
      let days = [],
          conBetweenDays = 0;
      week.forEach((w) => {
        const diff = moment(w.formatedDate).diff(today.startOf('day'), 'days'),
              startDateDiff = moment(startDate).startOf('day'),
              endDateDiff = moment(endDate).startOf('day'),
              dayName = (parseInt(w.dayName, 10) - 1) >= 0 ? (parseInt(w.dayName, 10) - 1) : 6,
              isSeted = exceptionDays ? exceptionDays.includes((dayName + 1).toString()) : true,
              isNotDeleted = exceptionDate ? !exceptionDate.includes(w.formatedDate) : true,
              isBetween = moment(w.formatedDate).isBetween(startDateDiff, endDateDiff, 'days', '[]'),
              dayClass =  diff == 0 ? 'today' : '',
              pastDayClass = diff < 0 && isNotDeleted? 'past-day' : '';

        

        let activeCampaignClass = '',
            selectedCampaignClass = '',
            isActiveItem = false,
            isSelected = false;

        if(isBetween)
          isActiveItem = true;

        if(isBetween && isSeted && isNotDeleted)
          isSelected = true;

        if(isBetween && concurrency){
          if(cont !== 1){
            if(cont % concurrency != 1)
              isSelected = false
          }
          cont++;
        }

        if(isActiveItem){
          activeCampaignClass = 'active';
        }

        if(isSelected){
          selectedCampaignClass = 'selected';
        }

        if(this.props.monthNumber == w.monthNumber)
          days.push(<td onClick={ this.dateHandler.bind(null, w.formatedDate) } className={dayClass +' '+ activeCampaignClass +' '+ pastDayClass +' '+ selectedCampaignClass} key={'row-week-'+'-'+ index +'-'+w.date}>{w.date}</td>);
        else
        days.push(<td key={'row-week-'+'-'+ index +'-'+w.date}></td>);
      });
      weekElement.push(<tr key={ 'tr-week-'+ index +'-day' }>{ days }</tr>);
    });

    return weekElement;
  }
  render(){
    const weeks = this.getWeeks();

    return(
      <table className="calendar-table">
        <thead>
          <tr>
            <th>Lun</th>
            <th>Mar</th>
            <th>Mie</th>
            <th>Jue</th>
            <th>Vie</th>
            <th>Sab</th>
            <th>Dom</th>
          </tr>
        </thead>
        <tbody>
          { weeks }
        </tbody>  
      </table>
    );
  }
}

Calendar.propTypes = {
  weeksData: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  exceptionDays: PropTypes.array,
  exceptionDate: PropTypes.any,
  concurrency: PropTypes.any,
  campaignType: PropTypes.any,
  dateHandler: PropTypes.func,
  monthNumber: PropTypes.string
}