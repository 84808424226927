import React, { Component } from 'react';

import Table from '../modules/table';
import CampaignBoxUserResume from './campaign-box-user-resume';
import CampaignBoxDateResume from './campaign-box-date-resume';

import { getCampaignStatus } from '../../utilities/utilities';

export default class CampaignTable extends Component{
  render(){
    const { campaigns, entities } = this.props;

    return (
      <div className="box inner animated fadeIn">
        <Table 
          data={ campaigns }
          tableClass={ 'no-padding cursored' }
          rowFunc={ (data) => {
            this.props.history.push('/campanas/'+data.get('id_campana'));
          }}
          columns={[
            {
              title: 'Campaña',
              order: true,
              func: (data) => {
                return (
                  <div className={`campaign-table ${ getCampaignStatus(data.get('estado_campana')) }`}>
                    <h3>{ data.get('numero_campana') }</h3>
                  </div>
                );
              }
            },
            {
              title: 'Plantilla',
              order: true,
              func: (data) => {
                const template = entities.get('templates').find((d) => {
                  return d.get('id_plantilla') == data.get('id_plantilla');
                })
      
                return (
                  <div className="table-wrapp">
                    <span className="campaign-box-header-indicator static active">
                      <i className="icon-element inline-left templates"></i>
                      { template.get('nombre_plantilla') }
                    </span>
                  </div>
                )
              }
            },
            {
              title: 'Clientes',
              order: true,
              func: (data) => {
                return (
                  <div className="table-wrapp">
                    <CampaignBoxUserResume campaign={ data } {...this.props} wrapper={ false }/>
                  </div>
                )
              }
            },
            // {
            //   title: 'Disponibilidad',
            //   order: true,
            //   func: (data) => {
            //     return <CampaignBoxDateResume campaign={ data } {...this.props} wrapper={ false } />
            //   }
            // }
          ]}
        />
      </div>
    );
  }
}