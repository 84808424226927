import React, { Component } from 'react';

import Assistant from './assistant';
import AssistantFilters from './assistant-filters';

export default class AssistantWrapper extends Component{
  render(){
    return(
      <div className="grid-6">
        <div className="box no-padding clearfix">
          <div className="grid-4 no-gutter-left no-gutter-right">
            <div className="wrapper">
              <AssistantFilters {  ...this.props } />
            </div>
          </div>
          <div className="grid-8 no-gutter-right bordered-left">
            <div className="wrapper">
              <Assistant { ...this.props } />
            </div>
          </div>
        </div>
      </div>
    );
  }
}