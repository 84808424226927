import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserListDropdown from './user-list-dropdown';
import Dropdown from '../modules/dropdown';

export default class UserList extends Component{
  getList(){
    const { users, campaign } = this.props;

    return users.map((user) => {
      const userListDropdown = <UserListDropdown user={user} campaign={ campaign } {...this.props} />;

      let dropdown = (
        <Dropdown
          handlerClass="btn btn-small btn-user-actions"
          icon={ 'icon-element points' }
        >
          { userListDropdown }
        </Dropdown>
      );
      if(user.get('excluded') && !user.get('conversation') && !user.get('voucher')){
        dropdown = null;
      }

      return (
        <li key={'user-rut-'+ user.get('id')}>
          <span className="user-name">{ user.get('name') }</span>
          <span className="user-data">{ user.get('rut') }</span>
          <div className="user-indicators">
            <span className="tooltip">
              <i className={'icon-element inline-left excluded '+ (user.get('excluded') ? 'active' : '')}></i>
              <span className="tooltip-content small up-left">Excluído</span>
            </span>
            <span className="tooltip">
              <i className={'icon-element inline-left conversation '+ (user.get('conversation') ? 'active' : '')}></i>
              <span className="tooltip-content small up-left">Respondió</span>
            </span>
            <span className="tooltip">
              <i className={'icon-element inline-left voucher '+ (user.get('voucher') ? 'active' : '')}></i>
              <span className="tooltip-content small up-left">Comprobante</span>
            </span>
            <span className="tooltip">
              <i className={'icon-element inline-left paid '+ (user.get('paid') ? 'active' : '')}></i>
              <span className="tooltip-content small up-left">Pagado</span>
            </span>
          </div>
          { dropdown }
        </li>
      )
    });
  }
  getContent(){
    const { users } = this.props,
          list = this.getList();
          

    if(users.size){
      return (
        <ul className="user-list">
          { list }
        </ul>
      )
    }else{
      return (
        <div className="box">
          <p className="align-center">No hay usuarios para mostrar</p>
        </div>
      );
    }
  }
  render(){
    const content = this.getContent();

    return content;
  }
}

UserList.propTypes= {
  users: PropTypes.object,
  campaign: PropTypes.object
}