import React, { Component } from 'react';

import IsFetching from '../modules/is-fetching';
import CampaignSingleContent from './campaign-single-content';
import { getIdEnterprise } from '../../utilities/utilities';

export default class CampaignSingle extends Component{
  componentWillMount(){
    this.callAsyncData()
  }
  callAsyncData(){
    const  { currentUser } = this.props,
           userData = currentUser.get('userData'),
           campaignId = this.props.match.params.id;
    this.props.getCampaigns(userData.get('username'), 0, 20, getIdEnterprise(this.props), '', '', campaignId.toString());
    this.props.getTemplates(userData.get('username'), getIdEnterprise(this.props));
    this.props.getEmailTemplates(userData.get('username'),getIdEnterprise(this.props))
  }
  render(){
    const { campaignStates, templatesStates, channelsConfigStates, entities } = this.props,
          currentCampaign = entities.get('campaigns').get('0');

    return(
      <IsFetching isFetching={campaignStates.get('isFetching') || templatesStates.get('isFetching') || channelsConfigStates.get('isFetching')}>
        <CampaignSingleContent 
          currentCampaign = { currentCampaign }
          { ...this.props }
        />
      </IsFetching>
    );
  }
}