import React, { Component } from 'react';

import { getCalendarData } from '../../../utilities/utilities';

import CampaignBoxSendDataResume from './campaign-box-date-resume';
import CampaignBoxAdditionalInfo from './campaign-box-additional-info';
import CampaignBoxDateResume from '../campaign-box-date-resume';

export default class SingleCampaignSendData extends Component{
  render(){
    const { currentCampaign } = this.props;

    return(
      <div className="box inner m-b-10">
        <CampaignBoxDateResume campaign={ currentCampaign } />
        <CampaignBoxSendDataResume { ...this.props } />
        <CampaignBoxAdditionalInfo { ...this.props } />
      </div>
    );
  }
}