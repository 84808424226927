import React, { Component } from 'react';

import IsFetching from '../modules/is-fetching';
import Disclaimer from '../modules/disclaimer';

export default class ConfirmData extends Component{
  getDisclaimer(){
    const { generalStates } = this.props,
          error = generalStates.get('formError');

    if(error){
      return (
        <div className="wrapper horizontal">
          <Disclaimer type={ 'error' } content={ error } />
        </div>
      ) 
    }
    return null;
  }
  render(){
    const { generalStates } = this.props,
          confirmModalContent = generalStates.get('confirmModalContent'),
          disclaimer = this.getDisclaimer();

    return(
      <IsFetching isFetching={ generalStates.get('isFetchingForm') } showChildren={ true }>
        <header className="modal-header">
          <h3 className="modal-title">Confirmar</h3>
        </header>
        { disclaimer }
        <div className="modal-body">
          <div className="scrolled-content inner">
            <p className="align-center">{ confirmModalContent.get('text') }</p>
          </div>
        </div>
        <div className={ 'form-button-holder' }>
          <button className='btn btn-secondary m-r-10' onClick={ this.props.setModal.bind(null, false) }>Cancelar</button>
          <button className='btn' onClick={confirmModalContent.get('func')}>Confirmar</button>
        </div>
      </IsFetching>
    );
  }
}
