import React, { Component, createRef } from 'react';
import { baseUrlEmailTemplate } from '../../actions/constants';

export default class EmailPrevisualization extends Component{
  constructor(props){
    super(props);

    this.iframeData = createRef();
  }
  componentDidMount(){
    const { channelsConfigStates } = this.props,
          emailConfig = channelsConfigStates.get('emailConfig'),
          modalData = channelsConfigStates.get('modalData'),
          content = modalData.get('file_content'),
          iframe = this.iframeData.current;

    if(!iframe){ return false; }
    
    const document = iframe.contentDocument;
    document.body.innerHTML = content.replace('email_markup', `<p>Estimado usuario, regulariza tu situación en el siguiente link:</p> <a href="#" style="display: inline-block; background-color: ${ emailConfig.get('buttonBackgroundColor') }; border-radius: 50px; color: ${ emailConfig.get('buttonTextColor') }; padding:15px 60px; font-weight: bold; font-size: 16px; text-decoration: none;">${ emailConfig.get('buttonText') }</a>`);
  }
  render(){
    const { channelsConfigStates } = this.props,
          modalData = channelsConfigStates.get('modalData');

    return(
      <div>
        <header className="modal-header">
          <h3 className="modal-title">Previsualización { modalData.get('name')  }</h3>
        </header>
        <div className="modal-body">
          <div className="row scrolled-content inner">
            <iframe sandbox="allow-same-origin" ref={ this.iframeData } title="email-previsualization-title" className="email-iframe"></iframe>
          </div>
        </div>
      </div>
    );
  }
}