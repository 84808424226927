import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getSimbology } from '../../utilities/utilities';

import Simbology from '../modules/simbology';

export default class CampaignReports extends Component{
  getSimbology(){
   const { simbology, featuresStates , adminEnterprisesStates } = this.props,
          features = featuresStates.get('features').get('campaignsFeatures'),
          enterpriseId = adminEnterprisesStates.getIn(["selectedEnterprise", "id"]);
          console.log(enterpriseId)


    if(simbology){
      return (
        <Simbology items = {getSimbology(features.get('enableWhatsapp'), features.get('enableVoice'))} id = {enterpriseId} />
        )
    }
  }
  render(){
    const simbology = this.getSimbology();
    return(
      <div className={ this.props.holderClass }>
        <h4 className="m-b-20 m-t-20 relative-position">
          { this.props.title }
          { simbology }
        </h4>
        { this.props.children }
      </div>
    );
  }
}

CampaignReports.propTypes = {
  holderClass: PropTypes.string,
  title: PropTypes.string,
  simbology: PropTypes.bool
}

CampaignReports.defaultProps = {
  holderClass: ''
}
