import * as Immutable from 'immutable';

export function featuresStates(state = Immutable.fromJS({
  features: JSON.parse(localStorage.getItem('features'))
}), action){
  switch(action.type){
    case 'FETCH_LOGIN_END':
      return state.set('features', Immutable.fromJS(action.features));
    case 'LOGOUT':
      return state.set('features', Immutable.fromJS({}));
    default:
      return state;
  }
}