import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CampaignDays extends Component{
  getDays(){
    const { days, onClickhandler } = this.props,
          daysLetters = ['L', 'M', 'M', 'J', 'V', 'S' , 'D'];

    return daysLetters.map((day, index) => {
      const activeClass = days.includes((index + 1).toString()) ? 'active' : '';
      if(onClickhandler)
        return <button key={'day-'+index} type="button" className={'day '+ activeClass} onClick={ onClickhandler.bind(null, (index + 1).toString()) }>{ day }</button>
      else
        return <span key={'day-'+index} type="button" className={'day '+ activeClass}>{ day }</span>
    });
  }
  render(){
    const days = this.getDays();

    return(
      <div className={'campaign-days-holder '+ this.props.additionalHolderClass}>
        { days }
      </div>
    );
  }
}

CampaignDays.propTypes = {
  days: PropTypes.object,
  onClickhandler: PropTypes.func,
  additionalHolderClass: PropTypes.string
}