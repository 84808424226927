import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ModalBase extends Component{
  render(){
    return(
      <div className="screen animated fadeIn">
        <div className={'box modal animated fadeInUp ' + this.props.additionalClass}>
          <button className="btn btn-smallest btn-top-right btn-secondary" onClick={this.props.setModal.bind(null, false)}>Cerrar</button>
          { this.props.children }
        </div>
      </div>
    );
  }
}

ModalBase.propTypes = {
  additionalClass: PropTypes.string
}