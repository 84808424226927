import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Tabs extends Component{
  getTabs() {
    const tabs = this.props.tabs;
    return tabs.map((item, index) => {
      const isActive = this.props.activeTab === index;
      const isDisabled = item.title.toLowerCase().includes('reagendamiento');
  
      let className = 'btn btn-tab';
      if (isActive) {
        className += ' active';
      }
      if (isDisabled) {
        className += ' disabled';
      }
  
      const onClick = isDisabled ? null : () => this.props.changeDashboardTab(index);
      const title = isDisabled ? 'Este botón está desactivado para reagendamiento' : null;
  
      return (
        <button
          key={`button-item-${item.title}`}
          className={className}
          onClick={onClick}
          title={title}
        >
          {item.title}
          {item.counter ? item.counter() : null}
        </button>
      );
    });
  }

  getTabsPanels(){
    const tabs = this.props.tabs;
    return tabs.map((item, index) => {
      if(this.props.activeTab === index){
        return (
          <div key={`tab-item-${item.title}`} className={'tab-panel active'}>
            { item.content() }
          </div>
        );
      }
      return null;
    });
  }

  render(){
    const tabsButtons = this.getTabs(),
          tabsPanels = this.getTabsPanels();

    return (
      <div className="tabs-holder">
        <div className={this.props.buttonsHolderClass}>
          { tabsButtons }
        </div>
        <div className={ this.props.tabsHolderClass }>
          { tabsPanels }
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.number,
  changeDashboardTab: PropTypes.func,
  buttonsHolderClass: PropTypes.string,
  tabsHolderClass: PropTypes.string,
  counter: PropTypes.func
}

Tabs.defaultProps = {
  buttonsHolderClass : 'tabs-buttons-holder',
  tabsHolderClass: 'tabs-panels-holder bg-white box-wrapp'
}

