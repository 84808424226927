import React, { Component } from 'react';
import Immutable from 'immutable';

import StepForm from '../modules/step-form';
import StepFormTemplates from '../campaigns/step-form-templates';
import StepFormSettings from '../campaigns/step-form-settings';
import StepFormConfirm from '../campaigns/step-form-confirm';
import IsFetching from '../modules/is-fetching';
import Disclaimer from '../modules/disclaimer';
import { getSendDates } from '../../utilities/utilities';

export default class CampaignForm extends Component{
  constructor(props){
    super(props);

    this.state = {
      sendDates: []
    }
  }
  componentWillReceiveProps(nextProps){
    const sendDates = getSendDates(nextProps.campaignStates.get('campaignForm')),
          oldSendDates = getSendDates(this.props.campaignStates.get('campaignForm'));
          console.log("sendDates",sendDates)
    if(!Immutable.is(sendDates, oldSendDates)){
      this.setState({ sendDates: sendDates })
    }
  }
  getDisclaimer(){
    const { campaignStates } = this.props,
          error = campaignStates.get('formError');

    if(error){
      return (
        <div className="wrapper horizontal">
          <Disclaimer type={ 'error' } content={ error } />
        </div>
      ) 
    }
    return null;
  }
  render(){
    const { campaignStates } = this.props,
          editingCampaign = campaignStates.get('isEditing'),
          modalTitle = editingCampaign ? 'Editar Campaña '+ editingCampaign.get('id') : 'Nueva Campaña',
          disclaimer = this.getDisclaimer();

    return(
      <IsFetching isFetching={ campaignStates.get('isFetchingForm') } showChildren={ true }>
        <header className="modal-header">
          <h3 className="modal-title">{ modalTitle }</h3>
        </header>
        { disclaimer }
        <div className="modal-body">
          <StepForm
            changeStepFunc={ this.props.changeCampaignStepForm } 
            activeStep={ campaignStates.get('activeStep') }
            steps={[
              {
                title: 'Plantilla y clientes',
                content: () => {
                  return (
                    <StepFormTemplates {...this.props} />
                  )
                }
              },
              {
                title: 'Configuración',
                content: () => {
                  return <StepFormSettings {...this.props}/>
                }
              },
              {
                title: 'Confirmación',
                content: () => {
                  return <StepFormConfirm {...this.props} sendDates={ this.state.sendDates }/>
                }
              }
            ]}
          />
        </div>
      </IsFetching>
    );
  }
}