import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Label extends Component{
  render(){
    return <span className={ 'label '+ this.props.type } onClick={ this.props.onClickHander }>{ this.props.label }</span>
  }
}

Label.propTypes = {
  type: PropTypes.string,
  label: PropTypes.any,
  onClickHander: PropTypes.func
}