import React, { Component } from 'react';
import moment from 'moment';

import { getSimbology, getBarChartSetup, getLineChartSetup, getIdEnterprise } from '../../utilities/utilities';

import Simbology from '../modules/simbology';
import ChartHolder from '../charts/chart-holder';
import Tooltip from '../modules/tooltip';
import DashboardBarChart from '../charts/bar-chart';
import DashboardLineChart from '../charts/line-chart';
import IsFetching from '../modules/is-fetching.jsx';
import Tabs from '../modules/tabs';

export default class DashboardCampaigns extends Component{
  constructor(props){
    super(props);
    this.changeTabDashboard = this.changeTabDashboard.bind(this);
  }

  changeTabDashboard(index, target, event){
    this.props.changeTabsDashboard(target, index);
  }

  getDataForDownloadCSV(conReagentamiento){
    const { dashboardStates, currentUser } = this.props,
          startDate = moment(dashboardStates.get('startDate')).format('YYYY/MM/DD'),
          endDate = moment(dashboardStates.get('endDate')).format('YYYY/MM/DD');

    const dataForDownloadCSV = {
      usuario: currentUser.getIn(['userData','username']),
      fecha_inicio: startDate,
      fecha_termino: endDate,
      id_campana: '',
      empresa: getIdEnterprise(this.props)
    }

    if(conReagentamiento){
      dataForDownloadCSV['reagendamiento'] = true;
    }

    return dataForDownloadCSV;
  }
  render(){
    const { dashboardStates, currentUser, featuresStates, adminEnterprisesStates } = this.props,
          features = featuresStates.get('features').get('campaignsFeatures'),
          startDate = moment(dashboardStates.get('startDate')).format('YYYY/MM/DD'),
          endDate = moment(dashboardStates.get('endDate')).format('YYYY/MM/DD'),
          barChartSetup = getBarChartSetup(features),
          lineChartSetup = getLineChartSetup(features),
          enterpriseId = adminEnterprisesStates.getIn(["selectedEnterprise", "id"]);

    return(
      <div className="row">
        <h3 className="relative-position">
          Apertura de campañas
          <Simbology items = {getSimbology(features.get('enableWhatsapp'), features.get('enableVoice'))} id = {enterpriseId} />
        </h3>
        <Tabs 
          activeTab={dashboardStates.get('tabs').get('opening')} 
          changeDashboardTab={this.changeTabDashboard.bind(null, 'opening')}
          buttonsHolderClass={ 'tabs-buttons-holder small inner' }
          tabsHolderClass={'tabs-panels-holder'}
          tabs = {[
            {
              title: 'Agendamiento',
              content: () => {
                return (
                  <ChartHolder
                    title={ 'Porcentaje de apertura' }
                    getData={ this.props.getDashboardData.bind(null, {
                      slug: 'openingCampaignsPercent',
                      endpoint: 'campanas/DASHBOARD_APERTURA',
                      ord: 'ord',
                      data: {
                        usuario: currentUser.getIn(['userData','username']),
                        id_campana: '',
                        fecha_inicio: startDate,
                        fecha_termino: endDate,
                        reagendamiento: false,
                        empresa: getIdEnterprise(this.props)
                      }
                    }) }
                    kpis={ this.props.dashboardStates.get('kpis').get('openingCampaignsPercent') }
                    dataForCSVCall={this.getDataForDownloadCSV()}
                    csvType={"porcentaje_apertura"}
                  >
                    <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('openingCampaignsPercent') } >
                      <DashboardBarChart 
                        data={ dashboardStates.get('data').get('openingCampaignsPercent') } 
                        tooltipFormatter = {  (data) => { return Math.round(data) + '%' } }
                        yAxisLabel={ 'Apertura' }
                        formatYAxis={ (data) => { return data + '%' } }
                        setup={ barChartSetup }
                      />
                    </IsFetching>
                  </ChartHolder>
                )
              }
            },
            {
              title: 'Reagendamiento',
              content: () => {
                return (
                  <ChartHolder
                    title={ 'Porcentaje de apertura' }
                    getData={ this.props.getDashboardData.bind(null, {
                      slug: 'openingCampaignsPercent',
                      endpoint: 'campanas/DASHBOARD_APERTURA',
                      ord: 'ord',
                      data: {
                        usuario: currentUser.getIn(['userData','username']),
                        id_campana: '',
                        fecha_inicio: startDate,
                        fecha_termino: endDate,
                        reagendamiento: true,
                        empresa: getIdEnterprise(this.props)
                      }
                    }) }
                    kpis={ this.props.dashboardStates.get('kpis').get('openingCampaignsPercent') }
                    dataForCSVCall={this.getDataForDownloadCSV(true)}
                    csvType={"porcentaje_apertura"}
                  >
                    <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('openingCampaignsPercent') } >
                      <DashboardBarChart 
                        data={ dashboardStates.get('data').get('openingCampaignsPercent') } 
                        tooltipFormatter = {  (data) => { return Math.round(data) + '%' } }
                        yAxisLabel={ 'Apertura' }
                        formatYAxis={ (data) => { return data + '%' } }
                        setup={ barChartSetup }
                      />
                    </IsFetching>
                  </ChartHolder>
                )
              }
            }
          ]}
        />
        <div className="grid-6">
          <ChartHolder
            title={ 'Días con mejor porcentaje de apertura' }
            getData={ this.props.getDashboardData.bind(null, {
              slug: 'openingCampaignsDay',
              endpoint: 'campanas/DASHBOARD_DIAS_APERTURA',
              parseDays: true,
              data: {
                usuario: currentUser.getIn(['userData','username']),
                id_campana: '',
                fecha_inicio: startDate,
                fecha_termino: endDate,
                empresa: getIdEnterprise(this.props),
              }
            })
            }
            dataForCSVCall={this.getDataForDownloadCSV()}
            csvType={"porcentaje_apertura_dias"}
          >
            <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('openingCampaignsDay') } >
              <DashboardLineChart 
                data={ dashboardStates.get('data').get('openingCampaignsDay') } 
                tooltipFormatter = {  (data) => { return Math.round(data) + '%' } }
                yAxisLabel={ 'Apertura' }
                formatYAxis={ (data) => { return data + '%' } }
                dataKeys={ lineChartSetup }
              />
            </IsFetching>
          </ChartHolder>
        </div>
        <div className="grid-6">
          <ChartHolder
            title={ 'Horas con mejor porcentaje de apertura' }
            getData={ this.props.getDashboardData.bind(null, {
              slug: 'openingCampaignsHour',
              endpoint: 'campanas/DASHBOARD_HORAS_APERTURA',
              ord: 'name',
              data: {
                usuario: currentUser.getIn(['userData','username']),
                id_campana: '',
                fecha_inicio: startDate,
                fecha_termino: endDate,
                empresa: getIdEnterprise(this.props),
              }
            })
            }
            dataForCSVCall={this.getDataForDownloadCSV()}
            csvType={"porcentaje_apertura_horas"}
          >
            <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('openingCampaignsHour') } >
              <DashboardLineChart 
                data={ dashboardStates.get('data').get('openingCampaignsHour') } 
                tooltipFormatter = {  (data) => { return Math.round(data) + '%' } }
                yAxisLabel={ 'Apertura' }
                formatYAxis={ (data) => { return data + '%' } }
                dataKeys={ lineChartSetup }
              />
            </IsFetching>
          </ChartHolder>
        </div>
      </div>
    );
  }
}
