import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class LoginHeader extends Component{
  render(){
    return(
      <div className="margin-bottom-wrapp margin-bottom-wrapp-big">
        <h1 className="logo">
          { this.props.title }
          <img className="centered-img" src={ this.props.img } alt="logo Cognitiva" />
        </h1>
        <p className="align-center">{ this.props.description }</p>
      </div>
    );
  }
}

LoginHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string
}
