import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CampaignBoxes from './campaign-boxes';
import CampaignTable from './campaign-table';

export default class CampaignsBoxes extends Component{
  render(){
    const { campaignStates, campaigns } = this.props;
  console.log('CampaignsBoxes', campaigns)
    // if(campaignStates.get('viewSwitch') == 'table')
    //   return <CampaignTable { ...this.props } />
    // else
    return <CampaignBoxes { ...this.props } />
  }
}

CampaignsBoxes.propTypes = {
  campaigns: PropTypes.object
}