import React, { Component } from 'react';

import Disclaimer from '../modules/disclaimer';
import UploadButton from '../modules/upload-button';

export default class CampaignUpdateUsers extends Component{
  constructor(props){
    super(props);

    this.submitCampaignUpdateFile = this.submitCampaignUpdateFile.bind(this);
  }
  onSubmitHandler(event){
    event.preventDefault();
  }
  submitCampaignUpdateFile(){
    const { entities, campaignStates, currentUser } = this.props,
          currentCampaign = entities.get('campaigns').get('0'),
          uploadActions = campaignStates.get('uploadActions');

    this.props.submitCampaignUpdateFile(currentUser.get('userData').get('username'), uploadActions.get('file'), parseInt(currentCampaign.get('id_campana'), 10));
  }
  render(){
    const { campaignStates } = this.props,
          uploadActions = campaignStates.get('updateUploadActions');

    return(
      <div>
        <header className="modal-header">
          <h3 className="modal-title">Actualizar clientes</h3>
        </header>
        <form className="wrapper" method="POST" action="#" onSubmit={ this.onSubmitHandler }>
          <span className="label-like">Cargar archivo de actualización</span>
          <UploadButton 
            selectCSVFile={ this.props.selectCampaignUpdateFile }
            fileName={ uploadActions.get('fileName') }  
            submitFunc={ this.submitCampaignUpdateFile }
            isFetching={ uploadActions.get('isFetching') }
          />
          <Disclaimer type={ 'error' } content={ uploadActions.get('error') } />
          <Disclaimer type={ 'success' } content={ uploadActions.get('success') } />
        </form>
      </div>
    );
  }
}
