import React, { Component } from 'react';

import DetailListBySearchItems from './detail-list-by-search-items';

export default class DetailListBySearchList extends Component{
  render(){
    const { data } = this.props;
    
    if(!data.size){ return null }
    
    return(
      <div>
        <label className="relative-position clear-margin">
          <i className="icon-element search inline-left inner-field-icon"></i>
          <input 
            type="search" 
            name="search-category" 
            value={ this.props.searchValue } 
            onChange={ this.props.onChangeFunc }
            placeholder={this.props.placeholder } />
        </label>  
        <DetailListBySearchItems { ...this.props } />
      </div>
    );
  }
}