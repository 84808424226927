import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IsFetching from './is-fetching';

export default class UploadButton extends Component{
  constructor(props){
    super(props);

    this.onChange = this.onChange.bind(this);
  }
  onChange(event){
    event.preventDefault();
    const value = event.target.value,
          file = event.target.files[0];

    if(value){
      const reader = new FileReader();

      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          var content = this.props.encodeContent ? decodeURIComponent(escape(evt.target.result)) : evt.target.result; 
          this.props.selectCSVFile(value, file, content);
        } 
      };

      var blob = file;
      reader.readAsBinaryString(blob);
    }
  }
  getPlaceholderData(){
    const { fileName } = this.props;
    return fileName ? fileName : this.props.placeholder;
  }
  getSubmitButton(){
    const { fileName } = this.props;

    if(!fileName || !this.props.submitFunc)
      return <span className="btn btn-smallest btn-secondary">Agregar archivo</span>;
    else
      return <button type="button" className="btn btn-smallest btn-primary" onClick={ this.props.submitFunc }>Confirmar archivo</button>;
  }
  render(){
    const placeholderData = this.getPlaceholderData(),
          submitButton = this.getSubmitButton();

    return (
      <div className="relative-position margin-bottom-wrapp">
      <IsFetching isFetching={ this.props.isFetching } showChildren={ true }>
        <label className={ 'relative-position '+ this.props.holderClass }>
          <input type="file" onChange={ this.onChange } className={ 'hide' } accept={ this.props.acceptData } />
          { submitButton }
          <span className="fake-input">{ placeholderData }</span>
        </label>
      </IsFetching>
      </div>
    );
  }
}

UploadButton.propTypes = {
  holderClass: PropTypes.string,
  selectCSVFile: PropTypes.func,
  placeholder: PropTypes.string,
  fileName: PropTypes.string,
  submitFunc: PropTypes.func,
  isFetching: PropTypes.bool,
  acceptData: PropTypes.string,
  encodeContent: PropTypes.bool
}

UploadButton.defaultProps = {
  holderClass: 'upload-button-holder',
  placeholder: 'No se ha cargado ningún archivo',
  acceptData: '.csv',
  encodeContent: false
}