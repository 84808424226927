import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Dropdown extends Component{
  constructor(props){
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this); 
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);

    this.state = {
      isShowingModal: false,
      onModal: false
    }
  }
  componentDidMount(){
    document.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  handleClickOutside(){
    if(!this.state.onModal){
      this.setState({
        isShowingModal: false
      });
    }
  }
  hideDropdown(){
    this.setState({
      isShowingModal: false
    });
  }
  toggleDropdown(event){
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
  }
  getElementHandler(){
    const { handlerClass, type, text, icon } = this.props,
          activeClass = this.state.isShowingModal ? 'active' : '';

    if(type == 'button'){
      let iconElement = icon ? <i className={ icon }></i> : null;

      return (
        <button className={ handlerClass + ' '+ activeClass } onClick={ this.toggleDropdown }>
          { iconElement }
          { text }
        </button>
      );
    }else{
      return null;
    }
  }
  mouseEnter(){
    this.setState({
      onModal: true
    });
  }
  mouseLeave(){
    this.setState({
      onModal: false
    });
  }
  render(){
    const elementHandler = this.getElementHandler(),
          activeClass = this.state.isShowingModal ? 'active' : '';
          
    return (
      <div className="dropdown-holder" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        { elementHandler }
        <div className={'dropdown box '+ activeClass}>
          <div onClick={ this.hideDropdown }>
            { this.props.children }
          </div>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  handlerClass: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  items: PropTypes.element,
  type: PropTypes.string
}

Dropdown.defaultProps = {
  type: 'button'
};