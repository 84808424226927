import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

export default class ColorPicker extends Component{
  constructor(props){
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this); 
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    
    this.state = {
      isShowingModal: false,
      onModal: false
    }
  }
  componentDidMount(){
    document.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  handleClickOutside(){
    if(!this.state.onModal){
      this.setState({
        isShowingModal: false
      });
    }
  }
  hideDropdown(){
    this.setState({
      isShowingModal: false
    });
  }
  toggleDropdown(event){
    this.setState({
      isShowingModal: !this.state.isShowingModal
    })
  }
  mouseEnter(){
    this.setState({
      onModal: true
    });
  }
  mouseLeave(){
    this.setState({
      onModal: false
    });
  }
  getColorPicker(){
    const { isShowingModal } = this.state,
          { color, changeColorFunc } = this.props;

    if(!isShowingModal){ return null }

    return (
        <SketchPicker 
          color={ color }
          onChangeComplete={ changeColorFunc }
          disableAlpha={ true }
          presetColors={[]}
          width={200}
        />
    );
  }
  render(){
    const { title, color } = this.props,
          colorPicker = this.getColorPicker();

    return(
      <div className="color-picker-holder relative-position" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <div onClick={ this.toggleDropdown  } >
          <span className="label-like">{ title }</span>
          <div className="color-picker">
            <span className="color-box" style={{ backgroundColor: color }}></span>
            <span className="color-box-text">{ color }</span>
          </div>
        </div>
        { colorPicker }
      </div>
    );
  }
}

ColorPicker.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string
}