import React, { Component } from 'react';

import CampaignReports from './campaign-reports';
import CampaignEmailTemplate from './campaign-email-template';
import CampaignSMSTemplate from './campaign-sms-template';

export default class CampaignSingleContentChannels extends Component{
  getEmailTemplate(){
    const { currentCampaign } = this.props;

    if(!currentCampaign.get('canales').includes('email')){ return null; }

    return <CampaignEmailTemplate { ...this.props } />;
  }
  getSMSTemplate(){
    const { currentCampaign } = this.props;

    if(!currentCampaign.get('canales').includes('sms')){ return null; }

    return <CampaignSMSTemplate { ...this.props } />;
  }
  render(){
    const { currentCampaign } = this.props,
          emailTemplate = this.getEmailTemplate(),
          smsTemplate = this.getSMSTemplate();

    if(!currentCampaign.get('canales').includes('email') && !currentCampaign.get('canales').includes('sms')){ return false; }

    return(
      <CampaignReports 
        title={ 'Canales' }
        {...this.props}
      >
        { emailTemplate }
        { smsTemplate }
      </CampaignReports>
    );
  }
}