import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CustomPrompt extends Component{
  render(){
    const { title, description, onConfirm, onCancel } = this.props;

    return(
      <div className="screen animated fadeIn">
        <div className={'box modal animated fadeInUp modal-small'}>
          <button className="btn btn-smallest btn-top-right btn-secondary" onClick={onCancel}>Cerrar</button>
          <header className="modal-header">
            <h3 className="modal-title">{ title }</h3>
          </header>
          <div className="modal-body">
            <div className="scrolled-content inner">
              <p className="align-center">{ description }</p>
            </div>
          </div>
          <div className={ 'form-button-holder' }>
            <button className='btn btn-secondary m-r-10' onClick={ onCancel }>Cancelar</button>
            <button className='btn' onClick={onConfirm}>Confirmar</button>
          </div>
        </div>
      </div>
    );
  }
}

CustomPrompt.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}