import React, { Component } from 'react';

export default class StepFormChannelButton extends Component{
  render(){
    const {campaignStates } = this.props,
          campaignForm = campaignStates.get('campaignForm');

    if(!campaignForm.get('email') && !campaignForm.get('sms') && !campaignForm.get('whatsapp') && !campaignForm.get('voz'))
      return null;

    if(campaignForm.get('email') && !campaignForm.get('emailTemplate'))
      return null;

    if(campaignForm.get('email') && campaignForm.get('emailTemplate') && !campaignForm.get('emailMarkupPlain').length)
      return null;

    if(campaignForm.get('sms') && !campaignForm.get('smsMarkupPlain'))
      return null;

    if(campaignForm.get('smsMarkupPlain').length > 159)
      return null;

    return(
      <div>
        <hr className="m-b-0 m-t-0"></hr>
        <div className="align-center wrapper">
          <button className="btn">Siguiente</button>
        </div>
      </div>
    );
  }
}