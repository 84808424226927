import React, { Component } from 'react';

import { getCalendarData } from '../../../utilities/utilities';

import CampaignDays from '../../modules/campaign-days';
import CalendarCampaign from '../calendar-campaign';

export default class CampaignBoxDateResume extends Component{
  render(){
    const { currentCampaign } = this.props,
          calendarData = getCalendarData(currentCampaign.get('fecha_inicio'), currentCampaign.get('fecha_termino'));
          console.log("aca",);

 let fechaTerminoEnvio = new Date(currentCampaign.get('ultim_fecha_enviado'))
let hora = fechaTerminoEnvio.getHours();
let minutos = fechaTerminoEnvio.getMinutes();
console.log("mi",minutos)
let horaFormateada = `${hora}:${minutos}`;

    return (
      <div className="campaign-box-wrapper">
        {/* <h4 className="m-b-0">Envío terminado a las</h4>
        <span className="secondary-data">{ " " +  horaFormateada +' hrs.' }</span> */}
        <div className="wrapper wrapper-vertical">
          <CampaignDays
            days={ currentCampaign.get('dias_envio') }
            additionalHolderClass={ 'align-center' }
          />
        </div>
        <div className="scrolled-box inner m-b-20">
          <CalendarCampaign 
            months={ calendarData }
            startDate={ currentCampaign.get('fecha_inicio') }
            endDate={ currentCampaign.get('fecha_termino') }
            days={ currentCampaign.get('dias_envio').toJS() }
            concurrency={ currentCampaign.get('concurrencia') }
            exceptionDate={ currentCampaign.get('dias_excepcion') }
          />
        </div>
      </div>
    );
  }
}