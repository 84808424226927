import * as Immutable from 'immutable';

export function channelsConfigStates(state = Immutable.fromJS({
  isFetching: true,
  isFetchingConfig: true,
  errorConfig: '',
  apiError: '',
  modalData: {},
  error: '',
  uploadActions: {
    fileName: '',
    file: false,
    fileContent: '',
    isFetching: false
  },
  emailConfig: {}
}), action){
  switch(action.type){
    case 'GET_EMAIL_TEMPLATES_START':
      return state.set('isFetching', true);
    case 'GET_EMAIL_TEMPLATES_END':
      return state.set('isFetching', false);
    case 'GET_EMAIL_TEMPLATE_ERROR':
      return state.withMutations((map) => {
        map.set('error', action.error)
          .set('isFetching', false)
      });
    case 'SUBMIT_EMAIL_TEMPLATE_HTML_START':
      return state.setIn(['uploadActions', 'isFetching'], true);
    case 'SUBMIT_EMAIL_TEMPLATE_HTML_END':
      return state.withMutations((map) => {
        map.setIn(['uploadActions', 'isFetching'], false)
        .setIn(['uploadActions', 'fileName'], '')
        .setIn(['uploadActions', 'file'], false)
      });
    case 'SELECT_EMAIL_TEMPLATE_HTML_FILE':
      return state.withMutations((map) => {
        map.setIn(['uploadActions', 'fileName'], action.file.name)
          .setIn(['uploadActions', 'file'], Immutable.fromJS(action.file))
          .setIn(['uploadActions', 'fileContent'], action.fileContent)
      });
    case 'EDIT_EMAIL_TEMPLATE':
      return state.set('modalData', Immutable.fromJS(action.data));
    case 'EMAIL_TEMPLATE_ERROR':
      return state.withMutations((map) => {
        map.set('apiError', action.error)
          .setIn(['uploadActions', 'isFetching'], false)
          .setIn(['uploadActions', 'file'], false)
          .setIn(['uploadActions', 'fileName'], '')
      })
    case 'GET_EMAIL_TEMPLATES_CONFIG_START':
      return state.withMutations((map) => {
        map.set('isFetchingConfig', true)
          .set('errorConfig', '')
      });
    case 'GET_EMAIL_TEMPLATES_CONFIG_ERROR':
      return state.withMutations((map) => {
        map.set('isFetchingConfig', false)
          .set('errorConfig', action.error)
      });
    case 'GET_EMAIL_TEMPLATES_CONFIG_END':
      return state.withMutations((map) => {
        map.set('isFetchingConfig', false)
          .set('emailConfig', Immutable.fromJS(action.data))
      });
    case 'SET_BUTTON_COLOR':
      return state.setIn(['emailConfig','buttonTextColor'], action.data)
    case 'SET_BUTTON_BACKGROUND_COLOR':
      return state.setIn(['emailConfig','buttonBackgroundColor'], action.data)
    case 'SET_BUTTON_TEXT':
      return state.setIn(['emailConfig','buttonText'], action.data)
    default:
      return state;
  }
}