import React, { Component } from 'react';
import logo from "../../assets/images/logo.svg";
import MainNavigation from './main-navigation';
import SecondaryNavigation from './secondary-navigation';

export default class Header extends Component{
  getItems(){
    const  { currentUser, featuresStates } = this.props,
           userData = currentUser.get('userData'),
           permissions = userData.get('viewsPermissions'),
           features = featuresStates.get('features');

    let items = [];

    if(permissions.includes('dashboard') && features.get('dashboard')){
      items.push(
        {
          text:"Dashboard",
          title: 'Ir al Dashboard de campañas',
          slug: '',
          path:"/dashboard",
          icon: 'icon-element dashboard'
        }
      )
    }

    if(permissions.includes('templates') && features.get('templates')){
      items.push(
        {
          text:"Plantillas",
          title: 'Ver plantillas para cargar campañas',
          slug: 'plantillas',
          path:"/plantillas",
          icon: 'icon-element templates'
        }
      )
    }

    if(permissions.includes('campaigns') && features.get('campaigns')){
      items.push(
        {
          text:"Campañas",
          title: 'Ver todas las campañas',
          slug: 'campanas',
          path:" /campanas",
          icon: 'icon-element campaigns'
        }
      );
    }

    return items;
  }
  render(){
    const { currentUser } = this.props,
          userData = currentUser.get('userData'),
          items = this.getItems(),
          currentPath = window.location.pathname.split('/');

    return (
      <div className="main-header">
        <img className="header-logo" src={ logo } alt="Cognitiva logo" />
        <MainNavigation 
          activeItem={ currentPath[currentPath.length - 1] }
          items={items}
        />
        <SecondaryNavigation 
          userName={ userData.get('nombre') }
          { ...this.props }
        />
      </div>
    );
  }
}