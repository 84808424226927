import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { getIndex, getCalendarData } from '../../utilities/utilities';
import CampaignDays from '../modules/campaign-days';
import CampaignDaySunday from '../modules/campaign-day-sunday'
import CalendarCampaign from './calendar-campaign';

export default class StepFormConfirm extends Component {
    constructor(props) {
        super(props);

        this.inputHandler = this.inputHandler.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.dayClickHandler = this.dayClickHandler.bind(this);

        this.submitAlert = this.submitAlert.bind(this);

        this.state = {
            showForm: false
        }
    }
    dayClickHandler(day, event) {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            payDays = campaignForm.get('dias_envio');

        if (payDays.includes(day)) {
            this.props.removeCampaignPayDay(getIndex(day, payDays));
        } else {
            this.props.addCampaignPayDay(day);
        }
    }
    inputHandler(target, event) {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm');

        let value = event.target.value;
        if (event.target.type == 'checkbox')
            value = !campaignForm.get(target);

        this.props.inputFormCampaign(target, value)
    }
    handleChangeStart(date) {
        const { campaignStates } = this.props,
            selectedDate = date.format('YYYY/MM/DD');

        this.props.inputFormCampaign('startDate', date);

        if (date.isAfter(campaignStates.get('campaignForm').get('endDate')))
            this.props.inputFormCampaign('endDate', moment(selectedDate).add(7, 'days'));
    }
    handleChangeEnd(date) {
        const { campaignStates } = this.props,
            selectedDate = date.format('YYYY/MM/DD');

        this.props.inputFormCampaign('endDate', date);

        if (date.isBefore(campaignStates.get('campaignForm').get('startDate')))
            this.props.inputFormCampaign('startDate', moment(selectedDate).subtract(7, 'days'));
    }
    getUrlField() {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm');

        if (campaignForm.get('urlPay'))
            return (
                <label className="relative-position">
                    <input
                        type="text"
                        name="url-pay-value"
                        value={campaignForm.get('urlPayValue')}
                        onChange={this.inputHandler.bind(null, 'urlPayValue')}
                        placeholder={'Ingrese la URL de la forma de pago'} />
                    <span className="field-disclaimer">Ejemplo: http://www.example.com/</span>
                </label>
            );
    }
    getConcurrencyCheckbox() {
        const { featuresStates, campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            features = featuresStates.get('features');

        if (features.get('campaignsFeatures').get('enableCampaignConcurrencySetup')) {
            return (
                <label className="relative-position">
                    <span className="checkbox-holder">
                        <input type="checkbox" name="user-can-set-concurrency" value={campaignForm.get('userCanSelectConcurrency')} onChange={this.inputHandler.bind(null, 'userCanSelectConcurrency')} checked={campaignForm.get('userCanSelectConcurrency')} />
                        <span className="slider"></span>
                    </span>
                    <span className="label-like">Permitir que el usuario pueda seleccionar la concurrencia</span>
                </label>
            )
        }
    }
    getPaymentMethods() {
        const { featuresStates, campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            features = featuresStates.get('features'),
            urlField = this.getUrlField();

        if (features.get('campaignsFeatures').get('enablePaymentMethodsSetup')) {
            return (
                <fieldset className="grid-6 margin-bottom-wrapp">
                    <h3>Forma de pago</h3>
                    <label className="relative-position">
                        <span className="checkbox-holder">
                            <input type="checkbox" name="channel-email" value={campaignForm.get('webpay')} onChange={this.inputHandler.bind(null, 'webpay')} checked={campaignForm.get('webpay')} />
                            <span className="slider"></span>
                        </span>
                        <span className="label-like">Webpay</span>
                    </label>
                    <label className="relative-position">
                        <span className="checkbox-holder">
                            <input type="checkbox" name="channel-email" value={campaignForm.get('urlPay')} onChange={this.inputHandler.bind(null, 'urlPay')} checked={campaignForm.get('urlPay')} />
                            <span className="slider"></span>
                        </span>
                        <span className="label-like">URL</span>
                        {urlField}
                    </label>
                </fieldset>
            )
        }
    }
    getStarudayDisclaimer() {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            days = campaignForm.get('dias_envio');

        if (days.includes('6'))
            return <span className="field-disclaimer">El horario límite de envío de campaña por ley es hasta las 14:00 hrs para los días sabados.</span>

        return null;
    }

    submitAlert() {
        const { campaignStates, entities } = this.props,
            campaignForm = campaignStates.get('campaignForm')
        if (campaignForm.get('campaignType')) {
            this.setState({ showForm: false });
        } else {
            this.setState({ showForm: true });
        }
    }

    render() {
        const { campaignStates, entities } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            enterprises = entities.get('enterprises'),
            calendarData = getCalendarData(campaignForm.get('startDate').startOf('day').format('YYYY/MM/DD'), campaignForm.get('endDate').startOf('day').format('YYYY/MM/DD')),
            concurrencyCheckbox = this.getConcurrencyCheckbox(),
            paymentMethods = this.getPaymentMethods(),
            saturdayDisclaimer = this.getStarudayDisclaimer();

        //Extrae booleano para evaluar si la empresa puede realizar campañas los dias domingos
        const holidaysSunday = entities.get('enterprises').toJS()
        const idEnterprise = sessionStorage.getItem("enterprise")
        const holidaysSundayMap = holidaysSunday.find(x => x.id == idEnterprise)
        const evaluationSunday = holidaysSundayMap.active_sunday_holidays

        //Se filtra por id de empresa para mostrar las opciones en tipo de campaña 
        const res = enterprises.find(item => item.get('id') == sessionStorage.getItem('enterprise'))
        res.get('options');

        let listadoArray = [];
        res.get('options').map((item) => {
            var obj = {};
            obj.name = item.replace('demora', 'mora'); 
            obj.value = item.replace('demora', 'mora');
            listadoArray.push(obj);
        })
        //console.log('CAMPAING TYPE: ' + campaignForm.get('campaignType'))
        //console.log('CAMPAING TYPE - listadoArray: ' + listadoArray)
        //console.log('CAMPAING TYPE - listadoArray - length: ' + listadoArray.length)

        return (
            <div className="row">
                <h3 className="align-center">Configuración de la campaña</h3>
                <form className="form-wrapper" method="POST" action="#">
                    <div className="grid-8">
                        <div className="box no-padding clearfix">
                            <fieldset className="fieldset-box wrapper">
                                <h4>Disponibilidad de la campaña</h4>
                                <label className="grid-6 m-b-0">
                                    <span className="label-like">Fecha de inicio</span>
                                    <span className="relative-position inline-element">
                                        <i className="icon-element calendar inline-left inner-field-icon"></i>
                                        <DatePicker
                                            dateFormat="DD/MM/YYYY"
                                            selected={campaignForm.get('startDate')}
                                            selectsStart
                                            startDate={campaignForm.get('startDate')}
                                            endDate={campaignForm.get('endDate')}
                                            onChange={this.handleChangeStart}
                                        />
                                    </span>
                                </label>
                                <label className="grid-6 m-b-0">
                                    <span className="label-like">Fecha de término</span>
                                    <span className="relative-position inline-element">
                                        <i className="icon-element calendar inline-left inner-field-icon"></i>
                                        <DatePicker
                                            dateFormat="DD/MM/YYYY"
                                            selected={campaignForm.get('endDate')}
                                            selectsEnd
                                            startDate={campaignForm.get('startDate')}
                                            endDate={campaignForm.get('endDate')}
                                            onChange={this.handleChangeEnd}
                                        />
                                    </span>
                                </label>
                            </fieldset>

                            {listadoArray !== undefined && listadoArray.length > 1 &&
                                <fieldset className="fieldset-box wrapper">
                                    <h4 className="m-b-40">Tipo de campaña</h4>
                                    <label className="grid-6 m-b-0">
                                        <select
                                            name="campaign-type"
                                            value={campaignForm.get('campaignType')}
                                            onChange={this.inputHandler.bind(null, 'campaignType')}
                                            style={{ textTransform: 'capitalize' }}
                                        >
                                            <option value="">Seleccionar</option>
                                            {
                                                listadoArray !== undefined &&
                                                listadoArray.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </label>
                                </fieldset>
                            }

                            <fieldset className="fieldset-box wrapper">
                                <h4>Envío de la campaña</h4>
                                <div className="grid-6">
                                    <label>
                                        <span className="label-like">Hora de envío:</span>
                                        <select name="send-time" value={campaignForm.get('sendTime')} onChange={this.inputHandler.bind(null, 'sendTime')} >
                                            <option value="08">08:00</option>
                                            <option value="09">09:00</option>
                                            <option value="10">10:00</option>
                                            <option value="11">11:00</option>
                                            <option value="12">12:00</option>
                                            <option value="13">13:00</option>
                                            <option value="14">14:00</option>
                                            <option value="15">15:00</option>
                                            <option value="16">16:00</option>
                                            <option value="17">17:00</option>
                                            <option value="18">18:00</option>
                                            <option value="19">19:00</option>
                                        </select>
                                    </label>
                                    <label className="relative-position">
                                        <span className="label-like">Concurrencia de la campaña</span>
                                        <select name="campaign-concurrecy" value={campaignForm.get('concurrency')} onChange={this.inputHandler.bind(null, 'concurrency')}>
                                            <option value="Una sola vez">Una sola vez</option>
                                            <option value="Cada dos días">Cada dos días</option>
                                            <option value="Cada tres días">Cada tres días</option>
                                            <option value="Cada cuatro días">Cada cuatro días</option>
                                            <option value="Cada cinco días">Cada cinco días</option>
                                            <option value="Todos los días">Todos los días</option>
                                        </select>
                                    </label>
                                    {concurrencyCheckbox}
                                </div>
                                {evaluationSunday ? (
                                    <div className="grid-6">
                                        <span className="label-like">Días de envío</span>
                                        <CampaignDaySunday
                                            days={campaignForm.get("dias_envio")}
                                            onClickhandler={this.dayClickHandler}
                                        />
                                        {/* {saturdayDisclaimer} */}
                                    </div>
                                ) : (
                                    <div className="grid-6">
                                        <span className="label-like">Días de envío</span>
                                        <CampaignDays
                                            days={campaignForm.get("dias_envio")}
                                            onClickhandler={this.dayClickHandler}
                                        />
                                        {saturdayDisclaimer}
                                    </div>
                                )
                                }
                            </fieldset>
                            {paymentMethods}


                            {
                                // campaignForm.get('campaignType') && 
                                this.state.showForm && campaignForm.get('campaignType') == ''
                                    ?
                                    <div className="grid-12 align-center wrapper">
                                        <span className="step-form-channel-menu-title" style={{ cursor: 'auto', fontSize: '12px', fontStyle: 'italic', fontWeight: '500' }}>
                                            <b style={{ color: 'rgb(255, 5, 5)' }}>Importante:</b> Debe completar todos los campos para continuar
                                        </span>
                                    </div>
                                    :
                                    null
                            }

                            <div className="grid-12 align-center wrapper">
                                <button
                                    type="button"
                                    // disabled={campaignForm.get('campaignType') ? false : true}
                                    className="btn"
                                    onClick={
                                        campaignForm.get('campaignType')
                                            ?
                                            this.props.changeCampaignStepForm.bind(null, 2)
                                            :
                                            this.submitAlert.bind(null)
                                    }
                                >
                                    Siguiente
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="grid-4">
                        <div className="box">
                            <div className="scrolled-box big">
                                <CalendarCampaign
                                    months={calendarData}
                                    startDate={campaignForm.get('startDate').startOf('day').format('YYYY/MM/DD')}
                                    endDate={campaignForm.get('endDate').startOf('day').format('YYYY/MM/DD')}
                                    days={campaignForm.get('dias_envio').toJS()}
                                    concurrency={campaignForm.get('concurrency')}
                                    exceptionDate={campaignForm.get('exceptionDays')}
                                    dateHandler={(date) => {
                                        if (campaignForm.get('exceptionDays').includes(date)) {
                                            this.props.removeCampaignExceptionDay((getIndex(date, campaignForm.get('exceptionDays'))));
                                        } else {
                                            this.props.addCampaignExceptionDay(date);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
