import React, { Component } from 'react';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import ContentEditable from '../../content-editable/content-editable';

export default class StepFormChannelContentSMS extends Component{
  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(target, editorstate, event){
    const rawData = convertToRaw(editorstate.getCurrentContent());

    this.props.changeChannelData(target+'Markup', draftToHtml(rawData), editorstate.getCurrentContent().getPlainText());
  }
  labelComponentRender(props){
    const {
      mention,
      theme,
      searchValue, 
      isFocused,
      ...parentProps
    } = props;

    return (
      <div {...parentProps}>
        {mention.name}
      </div>
    );
  }
  getTemplateFields(){
    const { campaignStates, entities } = this.props,
          campaignForm = campaignStates.get('campaignForm'),
          templateId = campaignForm.get('template'),
          template =  entities.get('templates').filter((data) => {
            return data.get('id_plantilla') === parseInt(templateId, 0)
          });

    let fields = [];

    entities.get('uniqueFields').forEach((field) => {
      fields.push({
        name: field.get('nombre_despliegue'),
        type: 'uniqueField'
      }) 
    });

    if(template.get('0')){
      template.get('0').get('campos').forEach((field) => {
        const fieldData = entities.get('customFields').filter((data) => {
          return data.get('id_campo') === field;
        })
        fields.push({
          name: fieldData.get('0').get('nombre'),
          type: 'customField'
        })
      });
    }
    
    return fields;
  }
  render(){
    const { campaignStates } = this.props,
          campaignForm = campaignStates.get('campaignForm'),
          templateFields = this.getTemplateFields();

    return (
      <div className="wrapper">
        <h4>Configuración de SMS</h4>
        <p>Los mensajes SMS soportan <strong>160 caracteres</strong> como máximo. La URL del asistente ocupará 57 caracteres.</p>
        <ContentEditable 
          id={'sms-editor'}
          title={ 'Contenido SMS:' }
          editorState={ campaignForm.get('smsMarkupPlain') }
          onChangeFunc={ this.handleChange.bind(null, 'sms') }
          labelComponentRender={this.labelComponentRender}
          placeholder={'Ingresa el contenido del SMS...'}
          mentions={templateFields}
          counter={ true }
        />
        <span className="field-disclaimer">Para agregar o buscar <strong>custom fields: @customField</strong></span>
      </div>
    );
  }
}