import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FallbackMessage extends Component{
  render(){
    return (
      <div className="fallback-message-holder">
        <div className="align-center">
          <p className="fallback-message">{ this.props.content }</p>
        </div>
      </div>
    )
  }
}

FallbackMessage.propTypes = {
  content: PropTypes.string
} 