import React, { Component } from 'react';

import LoginHeader from './login-header.jsx';
import FormValidizr from '../form-validizr/form-validizr.jsx';
import logo from "../../assets/images/logo.svg";
import Tooltip from '../modules/tooltip';
import IsFetching from '../modules/is-fetching';
import Disclaimer from '../modules/disclaimer';

export default class Login extends Component{
  constructor(props){
    super(props);

    this.inputHandler = this.inputHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.handleForgotPass = this.handleForgotPass.bind(this);
  }
  inputHandler(target, event){
    this.props.setLoginFields(target, event.target.value);
  }
  formSubmitHandler(){
    const formData = this.props.loginStates.get('formData');

    this.props.loginUser({
      username: formData.get('email'),
      password: formData.get('password'),
      ip: '',
      geolocalizacion: '',
      dispositivo: 'Desktop',
      navegador: ''
    });
  }
  handleForgotPass(event){
    event.preventDefault();
    this.props.setModal('forgotPass');
  }
  getErrorMessage(){
    const { loginStates } = this.props,
          errorMessage = loginStates.get('errorMessage'),
          errorMessageStatus = loginStates.get('errorMessageStatus');

    return errorMessage ? <Disclaimer type={ errorMessageStatus } content={ errorMessage } /> : '';
  }
  getContent(){
    const { loginStates } = this.props,
          changeLoginPass = loginStates.get('changeLoginPass'),
          formData = loginStates.get('formData'),
          errorMessage = this.getErrorMessage();

    if(changeLoginPass){
      return (
        <div>
          <p className="align-center">Debes cambiar tu contraseña provisoria por una nueva</p>
          <IsFetching isFetching={ this.props.loginStates.get('isFetching') } showChildren={ true }>
            <FormValidizr 
              formClass="margin-bottom-wrapp"
              formSubmitHandler={ this.props.recoverPassChange.bind(null, formData.get('email'), formData.get('newPassword')) }
              formButtonHolder=''
                formButton={{
                  buttonClass: 'btn btn-full',
                  text: 'Actualizar contrasena'
                }}
              formFields={[
                {
                  data: [
                    {
                      tag: 'input',
                      type: 'password',
                      name: 'change-password',
                      invalidSetup: {
                        icons: {
                          valid: 'icon-element check field-icon',
                          invalid: 'icon-element rounded info red field-icon',
                        },
                        tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debe ingresar una nueva contraseña" />
                      },
                      placeholder: 'Ingrese una nueva contraseña',
                      value: formData.get('newPassword'),
                      label: 'Tu nueva contraseña:',
                      onChangeFunc: this.inputHandler.bind(null, 'newPassword'),
                      validators: ['required']
                    }
                  ]
                }
              ]}
            />
          </IsFetching>
          { errorMessage }
        </div>
      )
    }else{
      return (
        <div>
          <IsFetching isFetching={ this.props.loginStates.get('isFetching') } showChildren={ true }>
            <FormValidizr
              formClass="margin-bottom-wrapp"
              formSubmitHandler={ this.formSubmitHandler }
              formHolderClass='margin-bottom-wrapp'
              formButtonHolder=''
              formButton={{
                buttonClass: 'btn btn-full',
                text: 'Ingresar'
              }}
              formFields={[
                {
                  data: [
                    {
                      tag: 'input',
                      type: 'email',
                      name: 'email',
                      invalidSetup: {
                        icons: {
                          valid: 'icon-element check field-icon',
                          invalid: 'icon-element rounded info red field-icon',
                        },
                        tooltip: <Tooltip type="red" additionalClass="error in-field" content="El Email debe ser válido" />
                      },
                      placeholder: 'example@example.com',
                      value: formData.get('email'),
                      label: 'Tu email:',
                      onChangeFunc: this.inputHandler.bind(null, 'email'),
                      validators: ['required', 'email']
                    },
                    {
                      tag: 'input',
                      type: 'password',
                      name: 'password',
                      invalidSetup: {
                        icons: {
                          valid: 'icon-element check field-icon',
                          invalid: 'icon-element rounded info red field-icon',
                        },
                        tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debe ingresar una contraseña" />
                      },
                      placeholder: 'Ingresa tu contraseña',
                      value: formData.get('password'),
                      label: 'Contraseña:',
                      onChangeFunc: this.inputHandler.bind(null, 'password'),
                      validators: ['required']
                    }
                  ]
                }
              ]}
            />
          </IsFetching>
          <div className="align-center margin-bottom-wrapp">
            <button className="btn btn-link" onClick={ this.handleForgotPass }>Recuperar contraseña</button>
          </div>
          { errorMessage }
        </div>
      );
    }
  }
  render(){
    const content = this.getContent();
    
    let loadedClass;

    return (
      <div className="gradient-blue-bg full-height">
        <div className="vertically-center-parent">
          <div className={'box box-small-centered vertically-center-children ' + loadedClass}>
            <LoginHeader 
              title="Cognitiva - IBM Watson estrategic partner"
              img={ logo }
              description="Bienvenido al gestor de campañas"
            />
            { content }
          </div>
        </div>
      </div>
    );
  }
}
