import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import UploadButton from '../modules/upload-button';
import Disclaimer from '../modules/disclaimer';
import B64Link from '../modules/b64-link';
import { getTemplateElementById, getIdEnterprise } from '../../utilities/utilities';

export default class StepFormTemplates extends Component {
    constructor(props) {
        super(props);

        this.inputHandler = this.inputHandler.bind(this);
        this.submitCampaignCSVFile = this.submitCampaignCSVFile.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.goToTemplates = this.goToTemplates.bind(this);
    }
    inputHandler(target, event) {
        this.props.inputFormCampaign(target, event.target.value)
    }
    selectTemplate(event) {
        this.props.selectTemplateCampaign(event.target.value)
    }
    submitCampaignCSVFile() {
        const { campaignStates, currentUser } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            uploadActions = campaignStates.get('uploadActions'),
            id_empresa = getIdEnterprise(this.props);

        this.props.submitCampaignCSVFile(currentUser.get('userData').get('username'),
            uploadActions.get('file'),
            parseInt(campaignForm.get('template'), 10),
            id_empresa);
    }
    getTemplateOptions() {
        const { entities } = this.props,
            templates = entities.get('templates');

        return templates.map((template) => {
            return <option key={'template-value-' + template.get('id_plantilla')} value={template.get('id_plantilla')}>{template.get('nombre_plantilla')}</option>
        });
    }
    getTemplateLink() {
        const { campaignStates, entities } = this.props,
            campaignForm = campaignStates.get('campaignForm');

        let url = '';
        const selectedTemplate = getTemplateElementById(campaignForm.get('template'), entities.get('templates'));

        if (campaignForm.get('template'))
            return <a href={selectedTemplate.get('0').get('b64')} download={selectedTemplate.get('0').get('nombre_plantilla') + '.csv'} title="Descargar plantilla CSV">Descargar plantilla CSV</a>
    }
    getSuccessMessage(type) {
        const { campaignStates } = this.props,
            uploadActions = campaignStates.get('uploadActions'),
            message = uploadActions.get('messages').get(type),
            clients = message.get('clients'),
            //messageContent = type != 'success' ? message.get('title') : 'Archivo cargado correctamente con ' + message.get('clients') + ' clientes'
            messageContent = type != 'success' ? message.get('title') : 'Archivo cargado con ' + clients + ' clientes'
        type = clients == 0 ? 'errors' : type

        //console.log('StepFormTemplates success type: ' + type);
        console.log('StepFormTemplates success message : ' + message);
        //console.log('StepFormTemplates success message.title: ' + message.get('title'));
        //console.log('StepFormTemplates success message.size : ' + message.size);
        if (message.size)
            return <Disclaimer type={type} content={messageContent} />
    }
    getWarningClientsList() {
        const { campaignStates } = this.props,
            uploadActions = campaignStates.get('uploadActions'),
            message = uploadActions.get('messages').get('warnings');


        //console.log('StepFormTemplates Warning message : ' + message);
        //console.log('StepFormTemplates Warning message.size : ' + message.size);

        if (!message.size) { return null }

        let warningClients;
        const notUndefined = anyValue => typeof anyValue !== 'undefined';
        if (message.get('errors')) {
            warningClients = message.get('errors').map((item, index) => {
                if (item.get('rows').size > 0) {
                    return (
                        <li key={`error-message-${index}`}>
                            <span className="term">{item.get('header') + ':'}</span>
                            <span className="definition">{item.get('rows').map((field, field_id) => <b id={field_id}> {field}{(item.get('rows').size > (field_id + 1)) ? ',' : '.'} </b>)}  </span>
                        </li>
                    )
                }
            }).filter(notUndefined);
        }

        if (warningClients.size > 0) {
            return (
                <div className="box no-shadow">
                    <Disclaimer
                        type={'error'}
                        content={message.get('title')}
                        additionalClass={'align-center wrapper'}
                        additionalContent={<B64Link b64={message.get('b64')} title={`errores`} buttonText={'Descargar CSV con errores'} />}
                    />
                    <p>Registros que se encuentran con problema o Lista Negra, si se han cargado, se puede continuar y serán excluidos al crear la campaña.
                        Si lo desea, puede subir otro archivo antes de continuar. </p>
                    <div className="box no-padding no-shadow">
                        <ul className="line-list">
                            {warningClients}
                        </ul>
                    </div>
                </div>
            )
        };

    }
    getUploadFieldset() {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            uploadActions = campaignStates.get('uploadActions'),
            successMessage = this.getSuccessMessage('success'),
            warningClientsList = this.getWarningClientsList(),
            errorMessage = this.getSuccessMessage('errors');

        if (campaignForm.get('template')) {
            return (
                <fieldset className="fieldset-box wrapper animated fadeIn">
                    <h4>Clientes</h4>
                    <span className="label-like">Cargar Archivo XLSX o CSV <span className="text-red">*</span></span>
                    <UploadButton
                        selectCSVFile={this.props.selectCampaignCSVFile}
                        fileName={uploadActions.get('fileName')}
                        submitFunc={this.submitCampaignCSVFile}
                        isFetching={uploadActions.get('isFetching')}
                        acceptData={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                    />
                    {errorMessage}
                    {successMessage}
                    {warningClientsList}
                </fieldset>
            );
        }
    }
    getSubmitButton() {
        const { campaignStates } = this.props,
            uploadActions = campaignStates.get('uploadActions'),
            errors = uploadActions.get('messages').get('errors'),
            success = uploadActions.get('messages').get('success');

        //console.log('getSubmitButton error: ' + errors);
        //console.log('getSubmitButton success: ' + success);
        if ((!errors.size && success.size) || (errors.get('title') == undefined && success.size)) {
            return <div className="align-center wrapper"><button type="button" className="btn" onClick={this.props.changeCampaignStepForm.bind(null, 1)}>Siguiente</button></div>
        }
    }
    goToTemplates(event) {
        event.preventDefault();

        this.props.history.push('/plantillas');
    }
    render() {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            templatesOptions = this.getTemplateOptions(),
            templateLink = this.getTemplateLink(),
            uploadFieldset = this.getUploadFieldset(),
            submitButton = this.getSubmitButton();

        return (
            <div>
                <h3 className="align-center">Carga de plantilla y  configuración de clientes</h3>
                <form className="box no-padding box-form" method="POST" action="#">
                    <fieldset className="fieldset-box wrapper">
                        <h4>Plantilla</h4>
                        <label className="grid-10">
                            <span className="label-like">Cargar plantilla <span className="text-red">*</span></span>
                            <select name="campaign-template" value={campaignForm.get('template')} onChange={this.selectTemplate}>
                                <option value="">Seleccionar plantilla</option>
                                {templatesOptions}
                            </select>
                            <span className="field-disclaimer">Las plantillas listadas aquí se deben agregar desde el panel de <Link to={'/plantillas'}>plantillas</Link></span>
                            {templateLink}
                        </label>
                    </fieldset>
                    {uploadFieldset}
                    {submitButton}
                </form>
            </div>
        )
    }
}
