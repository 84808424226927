
//step-form-channel-menu.jsx

import React, { Component } from 'react';

export default class StepFormChannelMenu extends Component{
  constructor(props){
    super(props);

    this.inputHandler = this.inputHandler.bind(this);
  }

  inputHandler(target, event){
    const { campaignStates } = this.props,
          campaignForm = campaignStates.get('campaignForm');
    let value = event.target.value;
    if(event.target.type == 'checkbox'){
        value = !campaignForm.get(target);
        this.props.inputFormCampaign(target, value)
    }

    if(event.target.name == 'channel-email' || event.target.name == 'channel-sms'){
      this.props.inputFormCampaign('voz', false);
    }
    if(event.target.name == 'channel-voice'){
      this.props.inputFormCampaign('email', false);
      this.props.inputFormCampaign('sms', false);
    }
      
  }

  getWhatsappMenu(){
    const { featuresStates, campaignStates } = this.props,
          activeChannel = campaignStates.get('activeChannel'),
          campaignForm = campaignStates.get('campaignForm'),
          features = featuresStates.get('features').get('campaignsFeatures');

    if(!features.get('enableWhatsapp')){ return null; }

    return( 
      <li className={ activeChannel === 2 ? 'active' : '' } onClick={ this.props.onChangeChannel.bind(null, 2) }>
        <span className="step-form-channel-menu-title">Whatsapp</span>
        <label className="step-form-channel-menu-check relative-position">
          <span className="checkbox-holder">
            <input type="checkbox" name="channel-whatsapp" value={ campaignForm.get('whatsapp') } onChange={ this.inputHandler.bind(null, 'whatsapp') } checked={ campaignForm.get('whatsapp') } />
            <span className="slider"></span>
          </span>
        </label>
      </li>
    );
  }

  getVoiceMenu(){
    const { featuresStates, campaignStates } = this.props,
          activeChannel = campaignStates.get('activeChannel'),
          campaignForm = campaignStates.get('campaignForm'),
          features = featuresStates.get('features').get('campaignsFeatures');

    if(!features.get('enableVoice')){ return null; }

    return( 
      <li className={ activeChannel === 3 ? 'active' : '' } onClick={ this.props.onChangeChannel.bind(null, 3) }>
        <span className="step-form-channel-menu-title">Voz</span>
        <label className="step-form-channel-menu-check relative-position">
          <span className="checkbox-holder">
            <input type="checkbox" name="channel-voice" value={ campaignForm.get('voz') } onChange={ this.inputHandler.bind(null, 'voz') } checked={ campaignForm.get('voz') } />
            <span className="slider"></span>
          </span>
        </label>
      </li>
    );
  }
  
  render(){
    const { campaignStates } = this.props,
          campaignForm = campaignStates.get('campaignForm'),
          activeChannel = campaignStates.get('activeChannel'),
          whatsappMenu = this.getWhatsappMenu(),
          voiceMenu = this.getVoiceMenu();

    return(
      <ul className="step-form-channel-menu">
        <li className={ activeChannel === 0 ? 'active' : '' } onClick={ this.props.onChangeChannel.bind(null, 0) }>
          <span className="step-form-channel-menu-title">Email</span>
          <label className="step-form-channel-menu-check relative-position">
            <span className="checkbox-holder">
              <input type="checkbox" name="channel-email" value={ campaignForm.get('email') } onChange={ this.inputHandler.bind(null, 'email') } checked={ campaignForm.get('email') } />
              <span className="slider"></span>
            </span>
          </label>
        </li>
        <li className={ activeChannel === 1 ? 'active' : '' } onClick={ this.props.onChangeChannel.bind(null, 1) }>
          <span className="step-form-channel-menu-title">SMS</span>
          <label className="step-form-channel-menu-check relative-position">
            <span className="checkbox-holder">
              <input type="checkbox" name="channel-sms" value={ campaignForm.get('sms') } onChange={ this.inputHandler.bind(null, 'sms') } checked={ campaignForm.get('sms') } />
              <span className="slider"></span>
            </span>
          </label>


        </li>
          { whatsappMenu }
          { voiceMenu  }
      </ul>
    );
  }
}