import React, { Component } from 'react'
import Immutable from 'immutable';
import IsFetching from '../modules/is-fetching';
import InnerHeader from '../modules/inner-header';
import Tabs from '../modules/tabs';
import AdminEnterpriseEdit from './admin-enterprise-edit';
import AdminEnterpriseVariables from './admin-enterprise-variables';

export default class AdminEnterprise extends Component {
  constructor(props) {
    super(props)
    this.handleNewEnterpriseButton = this.handleNewEnterpriseButton.bind(this);
  }
  componentWillMount = () => {
    this.fetchData();
    // Sólo si no tiene seleccionado una empresa (al refrescar), 
    // debe ir a modificar la empresa seleccionada
    if (this.props.adminEnterprisesStates.get('selectedEnterprise') !== null) {
      const { adminEnterprisesStates, } = this.props,
        selectedEnterprise = adminEnterprisesStates.get('selectedEnterprise');
      this.props.changeFormEnterprise(selectedEnterprise);
    }
  }
  shouldComponentUpdate = (nextProps) => {
    if (!Immutable.is(nextProps.adminEnterprisesStates.get('selectedEnterprise'), this.props.adminEnterprisesStates.get('selectedEnterprise'))) {
      const { adminEnterprisesStates, } = nextProps,
        selectedEnterprise = adminEnterprisesStates.get('selectedEnterprise');
      this.props.changeFormEnterprise(selectedEnterprise);
    }
    return true;
  }
  fetchData = () => {
    this.props.getAllFeatures();
  }
  handleNewEnterpriseButton = () => {
    this.props.setModal("enterpriseFormModal");
  }
  render() {
    const { adminEnterprisesStates } = this.props;
    return (
      <IsFetching isFetching={adminEnterprisesStates.get('isFetching')}>
        <InnerHeader title="Administrar empresa" additionalClass="with-tabs">
          <div className="header-actions-holder">
            <button className="btn btn-small m-l-10" onClick={this.handleNewEnterpriseButton}>Nueva Empresa</button>
          </div>
        </InnerHeader>
        <div className="inner-content animated fadeIn">
          <Tabs
            activeTab={adminEnterprisesStates.get('activeTab')}
            changeDashboardTab={this.props.changeAdminEnterpriseTab}
            tabs={[
              {
                title: 'Edición',
                content: () => {
                  return <AdminEnterpriseEdit {...this.props} />
                }
              },
              {
                title: 'Variables',
                content: () => {
                  return <AdminEnterpriseVariables {...this.props} />
                }
              }
            ]}
          />
        </div>
      </IsFetching>

    )
  }
}

