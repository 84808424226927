import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getIndex, getCalendarData, getIdEnterprise } from '../../utilities/utilities';

import IsFetching from '../modules/is-fetching';
import Disclaimer from '../modules/disclaimer';
import Label from '../modules/label.jsx';
import CalendarCampaign from './calendar-campaign';

export default class StepFormConfirm extends Component {
    constructor(props) {
        super(props);

        this.createCampaign = this.createCampaign.bind(this);
    }
    createCampaign() {
        const { campaignStates, currentUser, channelsConfigStates, createCampaign } = this.props,
            emailConfig = channelsConfigStates.get("emailConfig"),
            userData = currentUser.get('userData'),
            campaignForm = campaignStates.get('campaignForm'),
            args = {
                usuario: userData.get('username'),
                id_plantilla: parseInt(campaignForm.get('template'), 10),
                fechas_envio: this.props.sendDates,
                dias_envio: campaignForm.get('dias_envio').toJS(),
                hr_envio: campaignForm.get('sendTime'),
                email: campaignForm.get('email'),
                sms: campaignForm.get('sms'),
                whatsapp: campaignForm.get('whatsapp'),
                voz: campaignForm.get('voz'),
                fecha_inicio: campaignForm.get('startDate').format('YYYY/MM/DD'),
                fecha_termino: campaignForm.get('endDate').format('YYYY/MM/DD'),
                url: campaignForm.get('urlPay'),
                url_pay_value: campaignForm.get('urlPayValue'),
                webpay: campaignForm.get('webpay'),
                puede_concurrir: campaignForm.get('userCanSelectConcurrency'),
                concurrencia: campaignForm.get('concurrency'),
                campaignType: campaignForm.get('campaignType'),
                estado_campana: 'no iniciada',
                dias_excepcion: campaignForm.get('exceptionDays').toJS(),
                token: campaignStates.get('uploadActions').get('messages').get('token'),
                clientes: campaignStates.get('uploadActions').get('messages').get('clients'),
                b64file: campaignStates.get('uploadActions').get('messages').get('b64file'),
                plantilla_email: parseInt(campaignForm.get('emailTemplate')),
                email_markup: campaignForm.get('emailMarkup').replace(/&nbsp;/g, '').replace(/<\/p>/g, ' <\/p> ').replace(/<p>/g, ' <p> '),
                sms_markup: campaignForm.get('smsMarkupPlain').replace(/&nbsp;/g, ''),
                //Los botones se sacan del email config
                button_text_color: emailConfig.get('buttonTextColor'),
                button_background_color: emailConfig.get('buttonBackgroundColor'),
                button_text: emailConfig.get('buttonText'),
                id_procesamiento: campaignForm.get('id_procesamiento'),
                empresa: getIdEnterprise(this.props),
                tipo_campana: campaignForm.get('campaignType') // COMENTADO PORQUE AÚN NO VA TIPO DE CAMPAÑA
            }
        createCampaign(args, this.props.history);
        // .then((code) => {
        //   debugger
        //   if(code == 200)
        //     this.props.history.push('/campanas')
        // });
    }
    getSuccessMessage() {
        const { campaignStates } = this.props,
            uploadActions = campaignStates.get('uploadActions'),
            message = uploadActions.get('messages').get('success'),
            messageContent = 'Campaña se cargará con ' + message.get('clients') + ' clientes';

        if (message.size)
            return <Disclaimer type={'success'} content={messageContent} />
    }
    getWarningMessage() {
        const { campaignStates } = this.props,
            uploadActions = campaignStates.get('uploadActions'),
            message = uploadActions.get('messages').get('warnings');
        // console.log('warning message: ' + message)

        //let warningClients;
        //if (message.get('clients')) {
        //    warningClients = message.get('clients').map((item, index) => {
        //        return <li key={'warning-client-' + index}>{item.get('name') + ' - ' + item.get('rut')}</li>
        //    });
        //}
        if (!message.size) { return null }

        let warningClients;
        const notUndefined = anyValue => typeof anyValue !== 'undefined';
        if (message.get('errors')) {
            warningClients = message.get('errors').map((item, index) => {
                if (item.get('rows').size > 0) {
                    return (
                        <li key={`error-message-${index}`}>
                            <span className="term">{item.get('header') + ':'}</span>
                            <span className="definition">{item.get('rows').map((field, field_id) => <b id={field_id}> {field}{(item.get('rows').size > (field_id + 1)) ? ',' : '.'} </b>)}  </span>
                        </li>
                    )
                }
            }).filter(notUndefined);
        }

        if (message.size) {
            return (
                <div className="disclaimer warning">
                    <p className="m-b-0">{message.get('title')}</p>
                    <p>Registros que se encuentran con problema o Lista Negra, si se han cargado, se puede continuar y serán excluidos al crear la campaña.
                        Si lo desea, puede subir otro archivo antes de continuar. </p>
                    <ul className="disclaimer-list scrolled-box">
                        {warningClients}
                    </ul>
                </div>
            );
        }
    }
    getPayDays() {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            payDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];

        return campaignForm.get('dias_envio').map((day) => {
            return <Label key={'pay-day-' + payDays[day]} type={'warning'} label={payDays[day - 1]} />
        });
    }
    getTemplateName() {
        const { campaignStates, entities } = this.props,
            templates = entities.get('templates'),
            campaignForm = campaignStates.get('campaignForm'),
            campaignTarget = templates.filter((data) => {
                return data.get('id_plantilla') == parseInt(campaignForm.get('template'), 0)
            });

        if (campaignTarget.get('0'))
            return campaignTarget.get('0').get('nombre_plantilla');

        return null;
    }
    render() {
        const { campaignStates } = this.props,
            campaignForm = campaignStates.get('campaignForm'),
            successMessage = this.getSuccessMessage(),
            warningMessage = this.getWarningMessage(),
            payDays = this.getPayDays(),
            templateTarget = this.getTemplateName(),
            calendarData = getCalendarData(campaignForm.get('startDate').startOf('day').format('YYYY/MM/DD'), campaignForm.get('endDate').startOf('day').format('YYYY/MM/DD'));

        let emailTag, smsTag, webpayTag, whatsappTag, urlTag, voiceTag;

        if (campaignForm.get('email'))
            emailTag = <Label type={'warning'} label={'Email'} />

        if (campaignForm.get('sms'))
            smsTag = <Label type={'warning'} label={'SMS'} />

        if (campaignForm.get('whatsapp'))
            whatsappTag = <Label type={'warning'} label={'Whatsapp'} />

        if (campaignForm.get('webpay'))
            webpayTag = <Label type={'warning'} label={'Webpay'} />

        if (campaignForm.get('urlPay'))
            urlTag = <Label type={'warning'} label={'URL'} />

        if (campaignForm.get('voz'))
            voiceTag = <Label type={'warning'} label={'Voz'} />

        let payWays;
        if (webpayTag || urlTag) {
            payWays = (
                <li>
                    <span className="term">Forma de pago:</span>
                    <span className="definition">
                        {webpayTag}
                        {urlTag}
                    </span>
                </li>
            );
        }

        return (
            <IsFetching isFetching={campaignStates.get('isFetchingForm')} showChildren={true}>
                <h3 className="align-center">Detalles y confirmación de la campaña</h3>
                <form className="row box box-form" method="POST" action="#">
                    <fieldset className="fieldset-box">
                        <h4>Detalles de la Campaña</h4>
                        <ol className="step-list full inner">
                            <li>
                                <h4 className="relative-position">1. Plantillas   <a href="#" className="confirm-title" onClick={this.props.changeCampaignStepForm.bind(null, 0)}><i className="icon-element inline-left edit"></i>Modificar</a></h4>
                                <div className="box no-shadow inner">
                                    <ul className="line-list">
                                        <li>
                                            <span className="term">Plantilla:</span>
                                            <span className="definition">{templateTarget}</span>
                                        </li>
                                    </ul>
                                    <div className="wrapper">
                                        {successMessage}
                                        {warningMessage}
                                    </div>
                                </div>
                            </li>
                            <li>
                                <h4 className="relative-position">Configuración <a href="#" className="confirm-title" onClick={this.props.changeCampaignStepForm.bind(null, 1)}><i className="icon-element inline-left edit"></i>Modificar</a></h4>
                                <div className="box inner no-shadow">
                                    <ul className="line-list">
                                        <li>
                                            <span className="term">Fecha:</span>
                                            <span className="definition">{campaignForm.get('startDate').format('DD/MM/YYYY') + ' - ' + campaignForm.get('endDate').format('DD/MM/YYYY')}</span>
                                        </li>
                                        <li>
                                            <span className="term">Tipo de Campaña:</span>
                                            <span className="definition" style={{ textTransform: 'capitalize' }}>{campaignForm.get('campaignType')}</span>
                                        </li>
                                        <li>
                                            <span className="term">Concurrencia:</span>
                                            <span className="definition">{campaignForm.get('concurrency') + ' ' + (campaignForm.get('userCanSelectConcurrency') ? '- Permite que el usuario modifique este valor' : '')}</span>
                                        </li>
                                        <li>
                                            <span className="term">Hora de envío:</span>
                                            <span className="definition">{`${campaignForm.get('sendTime')}:00`}</span>
                                        </li>
                                        <li>
                                            <span className="term">Días de envío:</span>
                                            <span className="definition">
                                                {payDays}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="term">Calendario:</span>
                                            <span className="definition">
                                                <CalendarCampaign
                                                    months={calendarData}
                                                    startDate={campaignForm.get('startDate').startOf('day').format('YYYY/MM/DD')}
                                                    endDate={campaignForm.get('endDate').startOf('day').format('YYYY/MM/DD')}
                                                    days={campaignForm.get('dias_envio').toJS()}
                                                    concurrency={campaignForm.get('concurrency')}
                                                    campaignType={campaignForm.get('campaignType')}
                                                    exceptionDate={campaignForm.get('exceptionDays')}
                                                    dateHandler={(date) => {
                                                        if (campaignForm.get('exceptionDays').includes(date)) {
                                                            this.props.removeCampaignExceptionDay((getIndex(date, campaignForm.get('exceptionDays'))));
                                                        } else {
                                                            this.props.addCampaignExceptionDay(date);
                                                        }
                                                    }}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            <span className="term">Canales:</span>
                                            <span className="definition">
                                                {emailTag}
                                                {smsTag}
                                                {whatsappTag}
                                                {voiceTag}
                                            </span>
                                        </li>
                                        {payWays}
                                    </ul>
                                </div>
                            </li>
                        </ol>
                    </fieldset>
                    <div className="align-center">
                        <Disclaimer type={'error'} content={campaignStates.get('formError')} />
                        <button type="button" className="btn" onClick={this.createCampaign}>Crear campaña</button>
                    </div>
                </form>
            </IsFetching>
        )
    }
}

StepFormConfirm.propTypes = {
    sendDates: PropTypes.array
}
