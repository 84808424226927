import React, {Component} from 'react';

import IsFetching from '../modules/is-fetching';
import FormValidizr from '../form-validizr/form-validizr.jsx';
import Tooltip from '../modules/tooltip';

export default class ForgotPassModal extends Component{
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
  }
  handleSubmit(){
    const { loginStates } = this.props,
          formData = loginStates.get('formData');

    this.props.sendRecoverPass(formData.get('emailForgot'));
  }
  inputHandler(target, event){
    this.props.setLoginFields(target, event.target.value);
  }
  render(){
    const formData = this.props.loginStates.get('formData'),
          invalidFields = this.props.loginStates.get('invalidFields'),
          invalidClass = invalidFields.includes('emailForgot') ? 'invalid-input' : '';

    return(
      <div>
        <header className="modal-header">
          <h3 className="modal-title">¿Olvidó su contraseña?</h3>
        </header>
        <div className="modal-body">
          <div className="wrapper">
            <p>Por favor ingrese el email en el siguiente espacio, en breve se estará enviando a esta dirección las instrucciones para reestablecer una nueva contraseña.</p>
          </div>
          <IsFetching isFetching={this.props.loginStates.get('isFetchingRecoverPass')}>
            <FormValidizr 
              formSubmitHandler={ this.handleSubmit }
              formButton={{
                buttonClass: 'btn',
                text: 'Enviar',
                holder: 'align-right'
              }}
              formFields={[
                {
                  data: [
                    {
                      tag: 'input',
                      type: 'email',
                      name: 'email',
                      invalidSetup: {
                        icons: {
                          valid: 'icon-element check field-icon',
                          invalid: 'icon-element rounded info red field-icon',
                        },
                        tooltip: <Tooltip type="red" additionalClass="error in-field" content="El Email debe ser válido" />
                      },
                      placeholder: 'example@example.com',
                      value: formData.get('emailForgot'),
                      label: 'Tu email:',
                      onChangeFunc: this.inputHandler.bind(null, 'emailForgot'),
                      validators: ['required', 'email']
                    }
                  ]
                }
              ]}
            />
          </IsFetching>
        </div>
      </div>
    );
  }
}