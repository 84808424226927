import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, Legend} from 'recharts';

import FallbackMessage from '../modules/fallback-message';

export default class DashboardBarChart extends Component{
  getBarElements(){
    const { setup, colors } = this.props;
    return setup.map((type, index) => {
      if(!this.props.cellByColors){
        return <Bar key={'bar-type-'+type} dataKey={ type } fill={ colors[index] } />
      }else{
        return (
          <Bar key={'bar-type-'+type} dataKey={ type } >
            {
              this.props.data.map((entry, index) => (
                <Cell cursor="pointer" fill={ this.props.cellByColors[index] } key={`cell-${index}`}/>
              ))
            }
          </Bar>
        );
      }

    })
  }
  getXAxis(){
    const { layout } = this.props;

    if(layout == 'vertical'){
      return <XAxis dataKey="data" type="number" />
    }else{
      return  <XAxis dataKey="name" />
    }
  }
  getYAxis(){
    const { layout } = this.props;
    if(layout == 'vertical'){
      return <YAxis label={{ value: this.props.yAxisLabel, angle: -90, position: 'insideLeft' }} type="category" dataKey="name" tickFormatter={this.props.formatYAxis}/>
    }else{
      return <YAxis label={{ value: this.props.yAxisLabel, angle: -90, position: 'insideLeft' }} axisLine={false} tickLine={false}  tickFormatter={this.props.formatYAxis}/>
    }
  }
  render(){
    const data = this.props.data.toJSON(),
          barElement = this.getBarElements(),
          xAxisElement = this.getXAxis(),
          yAxisElement = this.getYAxis();
    let content;
    if(data.length > 0){
      content = (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart 
            width={600} 
            height={300} 
            data={data}
            barSize={ this.props.barSize }
            layout={ this.props.layout }
            margin={{top: 0, right: 0, left: 0, bottom: 0}}>
            { xAxisElement }
            { yAxisElement }
            <CartesianGrid vertical={ false }/>
            <Tooltip formatter={this.props.tooltipFormatter} />
            { barElement }
          </BarChart>
      </ResponsiveContainer>
      );
    }else{
      content = <FallbackMessage content="No se han encontrado datos para visualizar" />
    }

    return content;
  }
}

DashboardBarChart.propTypes = {
  data: PropTypes.object,
  layout: PropTypes.string,
  setup: PropTypes.array,
  colors: PropTypes.array,
  cellByColors: PropTypes.array,
  barSize: PropTypes.number,
  formatYAxis: PropTypes.func,
  tooltipFormatter: PropTypes.func,
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string
}

DashboardBarChart.defaultProps = {
  layout: 'horizontal',
  setup: ['email', 'sms'],
  colors: ['#37C3F7', '#FF53A2', '#2BB741', '#e74c3c'],
  barSize: 10,
  formatYAxis: (tick) => { return tick },
  tooltip: (data) => { return data }
}