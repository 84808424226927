import React, { Component } from 'react';

import UploadButton from '../modules/upload-button';
import Disclaimer from '../modules/disclaimer';

export default class ExclusionListCampaign extends Component{
  constructor(props){
    super(props);

    this.submitCampaignCSVFile = this.submitCampaignCSVFile.bind(this)
  }
  submitCampaignCSVFile(){
    const { campaignStates } = this.props,
          uploadActions = campaignStates.get('exclusionUploadActions');

    this.props.submitSingleCampaignCSVFile(uploadActions.get('file'))
  }
  getDisclaimer(type){
    const { campaignStates } = this.props,
          uploadActions = campaignStates.get('exclusionUploadActions'),
          message = uploadActions.get('messages').get(type),
          messageContent = type !== 'success' ? message.get('title') : 'Archivo cargado correctamente con '+ message.get('clients') +' clientes';

    if(message.size)
      return <Disclaimer type={ type } content={ messageContent } />
  }
  render(){
    const { campaignStates } = this.props,
          uploadActions = campaignStates.get('exclusionUploadActions'),
          successDisclaimer = this.getDisclaimer('success'),
          errorDisclaimer = this.getDisclaimer('errors');

    return (
      <div>
        <header className="modal-header">
          <h3 className="modal-title">Cargar Lista de exclusión</h3>
        </header>
        <div className="modal-body">
          <div className="wrapper">
          <p>Lorem ipsum dolor sit amet consectetur do</p>
            <UploadButton 
              selectCSVFile={ this.props.selectSingleCampaignCSVFile }
              fileName={ uploadActions.get('fileName') }  
              submitFunc={ this.submitCampaignCSVFile }
              isFetching={ uploadActions.get('isFetching') }
            />
            { successDisclaimer }
            { errorDisclaimer }
          </div>
        </div>
      </div>
    )
  }
}