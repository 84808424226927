import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NotFoundData extends Component{
  getButton(){
    const { buttonFunc } = this.props;
    
    if(buttonFunc)
      return <button className={ this.props.buttonClass } onClick={ this.props.buttonFunc }>{ this.props.buttonText }</button>
    else
      return null;
  }
  render(){
    const button = this.getButton()
    return (
      <div className="not-found-box">
        <p>{ this.props.text }</p>
        { button }
      </div>
    );
  }
}

NotFoundData.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonFunc: PropTypes.func
}

NotFoundData.defaultProps = {
  buttonClass: 'btn'
}