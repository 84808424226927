import * as Immutable from 'immutable';

const formArgs = {
  name: '',
  username: '',
  password: '',
  viewsPermissions: []
}

export function usersStates(state = Immutable.fromJS({
  isFetching: false,
  isFetchingForm: false,
  isEditing: false,
  userForm: formArgs,
  error: '',
  formError: ''
}), action){
  switch(action.type){
    case 'SET_MODAL':
      if(!action.modalSlug)
        return state.withMutations((map) => {
          map.set('userForm', Immutable.fromJS(formArgs))
            .set('formError', '')
            .set('isEditing', false)
        });
      else
        return state;
    case 'GET_USERS_START':
      return state.set('isFetching', true);
    case 'GET_USERS_END':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('error', false)
      });
    case 'CHANGE_USER_FIELDS':
      return state.setIn(['userForm', action.target], action.value);
    case 'CREATE_USER_START':
      return state.set('isFetchingForm', true);
    case 'CREATE_USER_END':
      return state.set('isFetchingForm', false);
    case 'EDIT_USER_DATA_START':
      return state.set('isFetchingForm', true);
    case 'EDIT_USER_DATA_END':
      return state.set('isFetchingForm', false);
    case 'EDIT_USER': 
      return state.withMutations((map) => {
        map.set('userForm', action.data)
          .set('isEditing', action.data.get('id'))
      })
    case 'ADD_USER_PERMISSION':
      return state.updateIn(['userForm', 'viewsPermissions'], data => data.push(action.value))
    case 'REMOVE_USER_PERMISSION':
      return state.deleteIn(['userForm', 'viewsPermissions', action.index.toString()])
    case 'GET_USER_ERROR':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('error', action.error)
      });
    case 'CREATE_USER_ERROR':
      return state.withMutations((map) => {
        map.set('isFetchingForm', false)
          .set('formError', action.error)
      }); 
    case 'EDIT_USER_ERROR':
      return state.withMutations((map) => {
        map.set('isFetchingForm', false)
          .set('formError', action.error)
      }); 
    default:
      return state;
  }
}