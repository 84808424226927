import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class MainNavigation extends Component {
  getItems() {
    const newItems = [...this.props.items];
    newItems[1].icon = 'icon-element-1 templates-2';

    return newItems.map((item) => {
      const activeClass = this.props.activeItem === item.slug ? "active" : "";
    // return <Link className={activeClass} key={ 'item-' + item.text } to={'/' + item.slug}><i className={'icon-element inline-left '+ item.icon}></i>{item.text}</Link>
      return (
        <Link
          className={`${activeClass}`}
          key={`item-${item.text}`}
          to={`/${item.slug}`}
        >
          <i className={`icon-element inline-left ${item.icon}`} />
          {item.text}
        </Link>
      );
    });
  }
  render() {
    const items = this.getItems();
    return <nav className="main-navigation">{items}</nav>;
  }
}

MainNavigation.propTypes = {
  activeItem: PropTypes.string,
  items: PropTypes.array,
};
