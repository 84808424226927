import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class B64Link extends Component{
  render(){
    const { b64, className, buttonText, title } = this.props;

    if(b64){
      return <a href={b64} download={ title+'.csv' } title="Descargar CSV" className={ className }>{ buttonText }</a>
    }
  }
}

B64Link.propTypes = {
  title: PropTypes.string,
  b64: PropTypes.string,
  className: PropTypes.string,
  buttonText: PropTypes.string
}

B64Link.defaultProps = {
  buttonText: 'Descargar CSV',
  title: 'file'
}