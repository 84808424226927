import React, { Component } from 'react';

import IsFetching from '../modules/is-fetching';

export default class ConfirmData extends Component{
  render(){
    const { campaignStates } = this.props,
          singleCampaignData = campaignStates.get('singleCampaignData'),
          clientsData = singleCampaignData.get('clients');

    return(
      <div>
        <header className="modal-header">
          <h3 className="modal-title">Comprobante de pago</h3>
        </header>
        <div className="modal-body">
          <div className="scrolled-content inner">
            <IsFetching isFetching={ clientsData.get('isFetchingConversation') }>
              <img className="elastic-img" src={ clientsData.get('voucher') } alt="Comprobante de pago" />
            </IsFetching>
          </div>
        </div>
        <div className={ 'form-button-holder' }>
          <button className='btn btn-secondary m-r-10' onClick={ this.props.setModal.bind(null, false) }>Cancelar</button>
        </div>
      </div>
    );
  }
}
