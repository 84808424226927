import React, { Component } from 'react';

import IsFetching from '../modules/is-fetching';
import InnerHeader from '../modules/inner-header';
import NotFoundData from '../modules/not-found-data';
import EmailTemplatesTable from './email-templates-table';
import UploadButton from '../modules/upload-button';
import Disclaimer from '../modules/disclaimer';
import { getIdEnterprise } from '../../utilities/utilities';

export default class ChannelConfig extends Component{
  constructor(props){
    super(props);

    this.submitCampaignCSVFile = this.submitCampaignCSVFile.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentWillMount(){
    this.fetchData();
  }
  fetchData(){
    const  { currentUser } = this.props,
           userData = currentUser.get('userData');

    this.props.getEmailTemplates(userData.get('username'),getIdEnterprise(this.props));
    this.props.getEmailTemplatesConfig(userData.get('username'),getIdEnterprise(this.props));
  }
  getContent(){
    const { entities, channelsConfigStates } = this.props,
          templates = entities.get('emailTemplates'),
          error = channelsConfigStates.get('error') || channelsConfigStates.get('errorConfig');

    let content;

    if(error){
      content = (
        <NotFoundData 
          text={ error }
          buttonText="Intentar nuevamente"
          buttonFunc={ this.fetchData }
        />
       );
    }else{
      if(templates.size){
        content = <EmailTemplatesTable { ...this.props } />;
      }else{
        content = (
          <NotFoundData 
            text="Todavía no se ha cargado ninguna plantilla" 
            buttonText="Nueva plantilla"
            buttonFunc={ this.handleNewTemplateButton }
          />
        );
      }
    }

    return content;
  }
  submitCampaignCSVFile(){
    const { channelsConfigStates, currentUser } = this.props,
          uploadActions = channelsConfigStates.get('uploadActions');

    this.props.submitEmailTemplateHTMLFile(currentUser.get('userData').get('username'), uploadActions.get('file'), uploadActions.get('fileName'), uploadActions.get('fileContent'), getIdEnterprise(this.props));
  }
  getError(){
    const { channelsConfigStates } = this.props,
          error = channelsConfigStates.get('apiError');

    if(error){
      return (
        <Disclaimer type={ 'error' } content={ error } />
      );
    }else{
      return null;
    }
  }
  render(){
    const { channelsConfigStates, entities } = this.props,
          uploadActions = channelsConfigStates.get('uploadActions'),
          content = this.getContent(),
          error = this.getError();

    return(
      <IsFetching isFetching={ channelsConfigStates.get('isFetching') || channelsConfigStates.get('isFetchingConfig') }>
         <InnerHeader title="Plantillas de email" counter={ entities.get('emailTemplates').size } >
          <div className="header-actions-holder">
            <UploadButton 
              selectCSVFile={ this.props.selectEmailTemplateHTMLFile }
              fileName={ uploadActions.get('fileName') }  
              submitFunc={ this.submitCampaignCSVFile }
              isFetching={ uploadActions.get('isFetching') }
              acceptData={ '.html' }
              encodeContent={ true }
            />
          </div>
        </InnerHeader>
        <div className="inner-content animated fadeIn">
          { error }
          <div className="box inner">
            { content }
          </div>
        </div>
      </IsFetching>
    );
  }
}