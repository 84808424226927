import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Simbology extends Component{
    getItems() {
    const { items ,id  } = this.props;
    console.log(items)
    const showLogo = id === 153;


    if (showLogo) {
      return [
        <span className="simbology" key="simbology-voz">
          <span className="simbology-color" style={{ backgroundColor: "#ff0000" }}></span>
          Voz
        </span>
      ]
    }
  
    return items.map((item) => {
      return (
        <span className="simbology" key={ 'simbology-'+ item.name }>
          <span className="simbology-color" style={{ backgroundColor: item.color }}></span>
          { item.name }
        </span>
      );
    })
  }
   render(){
    const items = this.getItems();
    return (
      <span className="simbology-holder">
        { items }
      </span>
    );
  }
}

Simbology.propTypes = {
  items: PropTypes.array
}
