import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import Kpi from '../modules/kpi';

class ChartHolder extends Component{
  componentWillMount(){
    if(this.props.getData)
      this.props.getData()
  }
  getKpis(){
    const { kpis } = this.props;
    
    if(!kpis){ return null; }

    return kpis.map((kpi) => {
      return <Kpi key={'kpi-'+kpi.get('key')} keyData={ kpi.get('key') } valueData={ kpi.get('value') } />
    })
  }
  getButton(){
    const { csvType } = this.props;

    if(csvType){
      return <a onClick={()=> this.downloadCSV()} download={ this.props.title+'.csv' } title="Descargar CSV" className="btn btn-secondary btn-smallest btn-top-right">Descargar CSV</a>
    }
  }

  downloadCSV(){
    const { downloadChartTypeCSV, dataForCSVCall, csvType } = this.props;
    const { usuario, id_campana, empresa, fecha_inicio, fecha_termino, reagendamiento = false} = dataForCSVCall;
    downloadChartTypeCSV(
      usuario,
      id_campana,
      empresa,
      csvType,
      fecha_inicio,
      fecha_termino,
      reagendamiento,
    );
  }

  render(){
    const kpis = this.getKpis();
    const download = this.getButton();

    let kpisElement = null
    if(kpis){
      kpisElement = (
        <div className="align-center">
          { kpis }
        </div>
      );
    }

    return (
      <div className={ this.props.holderClass }>
        <h4>
          { this.props.title }
          { this.props.tooltip }
        </h4>
        { download }
        <div className={ this.props.chartHolderClass }>
          { this.props.children }
        </div>
        { kpisElement }
      </div>
    );
  }
}

ChartHolder.propTypes = {
  OpeningPercentageData: PropTypes.func,
  holderClass: PropTypes.string,
  title:  PropTypes.string,
  tooltip: PropTypes.element,
  getData: PropTypes.func,
  kpis: PropTypes.object,
  chartHolderClass: PropTypes.string,
  dataForCSVCall: PropTypes.object,
  csvType: PropTypes.any,
}

ChartHolder.defaultProps = {
  holderClass: 'box margin-bottom-wrapp relative-position',
  chartHolderClass: 'chart-holder',
  dataForCSVCall: null,
  csvType: '',
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps,mapDispatchToProps)(ChartHolder);