import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FilterField extends Component{
  render(){
    return (
      <label className={ this.props.labelClass }>
        <span className="label-like">{ this.props.label }</span>
        <i className={'icon-element '+ this.props.iconClass +' inline-left inner-field-icon'}></i>
        <input 
          className={ this.props.fieldClass }
          type="search" 
          name={ this.props.name } 
          placeholder={ this.props.placeholder }
          value={ this.props.value }
          onChange={ this.props.onChangeFunc }
        />
      </label>
    );
  }
}

FilterField.propTypes = {
  labelClass: PropTypes.string,
  iconClass: PropTypes.string,
  fieldClass: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChangeFunc: PropTypes.func,
  label: PropTypes.string
}

FilterField.defaultProps = {
  labelClass: 'relative-position inline-element',
  iconClass: 'search',
  fieldClass: 'filter-field'
}