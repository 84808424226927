import React, { Component } from 'react';

import Table from '../modules/table.jsx';
import { getIndexBy, getIdEnterprise } from '../../utilities/utilities';

export default class UsersTable extends Component{
  constructor(props){
    super(props);

    this.editHandler = this.editHandler.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }
  editHandler(data){
    this.props.editUser(data);
  }
  confirmDelete(data){
    const { entities, currentUser } = this.props, 
          currentUserData = currentUser.get('userData'),
          text = '¿Estás seguro que quieres eliminar al usuario'+ data.get('name') +'?',
          func = this.props.deleteUser.bind(null, getIndexBy(data.get('id'), entities.get('users')), data.get('id'), currentUserData.get('username'),getIdEnterprise(this.props));
    
    this.props.confirmData(text, func);
  }
  getActionButtons(data){
    const { currentUser } = this.props,
          userData = currentUser.get('userData');

    let buttonClose = null;
    if(userData.get('username') !== data.get('username'))
      buttonClose = <button className="btn btn-table" onClick={ this.confirmDelete.bind(null, data) }><i className="icon-element close"></i></button>

    return (
      <div className="table-action-buttons-holder">
        <button className="btn btn-table" onClick={ this.editHandler.bind(null, data) }><i className="icon-element edit"></i></button>
        { buttonClose }
      </div>
    )
  }
  getData(){
    const { entities, currentUser } = this.props,
          userData = currentUser.get('userData'),
          permissions = userData.get('viewsPermissions');

    if(permissions.includes('users')){
      return entities.get('users');
    }else{
      return entities.get('users').filter((user) => {
        return user.get('username') === userData.get('username');
      })
    }
    
  }
  render(){
    const data = this.getData()
    
    return(
      <Table
        className="margin-bottom-wrapp"
        data={ data }
        columns={[
          {
            title: 'Nombre',
            order: true,
            func: (data) => {
              return <strong>{ data.get('name') }</strong>
            }
          },
          {
            title: 'Email',
            order: false,
            func: (data) => {
              return data.get('username')
            }
          },
          {
            title: '',
            order: false,
            func: (data) => {
              return this.getActionButtons(data);
            }
          }
        ]}
      />
    )
  }
}