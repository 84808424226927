import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Conversation extends Component{
  getConversation(){
    const { data } = this.props;

    return data.map((conversation) => {
      return (
        <div className={'conversation-bubble '+ conversation.get('type')}>
          <p>{ conversation.get('message') }</p>
          <span className="secondary-data">{ conversation.get('date') +' '+ conversation.get('hour') }</span>
        </div>
      )
    });
  }
  render(){
    const conversation = this.getConversation();

    return (
      <div className="conversation-holder">
        { conversation }
      </div>
    )
  }
}

Conversation.propTypes = {
  data: PropTypes.array
}