import React, { Component } from 'react';

import ModalBase from '../modales/modal-base';
import Disclaimer from '../modules/disclaimer';
import ForgotPassModal from '../modales/forgot-pass-modal';
import ConfirmData from '../modales/confirm-data';
import TemplateForm from '../modales/template-form';
import CampaignForm from '../modales/campaign-form';
import ExclutionListCampaign from '../modales/exclution-list-campaign';
import UserConversation from '../modales/user-conversation';
import UserVoucher from '../modales/user-voucher';
import AssistantDemo from '../modales/assistant-demo';
import UserForm from '../modales/user-form';
import EmailPrevisualization from '../modales/email-previsualization';
import CampaignUpdateUsers from '../modales/campaign-update-users';
import NewEnterprise from '../modales/new-enterprise';

export default class Footer extends Component{
  getModal(){
    const modalType = this.props.generalStates.get('modal');
    let modal, additionalClass;
    if(modalType){
      const modalComponents = {
        forgotPass: ForgotPassModal,
        confirmData: ConfirmData,
        templateForm: TemplateForm,
        campaignForm: CampaignForm,
        exclutionListCampaign: ExclutionListCampaign,
        userConversation: UserConversation,
        userVoucher: UserVoucher,
        userForm: UserForm,
        assistantDemo: AssistantDemo,
        emailPrevisualization: EmailPrevisualization,
        campaignUpdateUsers: CampaignUpdateUsers,
        enterpriseFormModal: NewEnterprise
      }

      const ModalComponent = modalComponents[modalType];

      if(modalType === 'forgotPass' || modalType === 'userVoucher')
        additionalClass = 'modal-small';
      else if(modalType === 'userConversation' || modalType === 'assistantDemo')
        additionalClass = 'modal-smallest';
      else if(modalType == 'templateForm'){
        const {templatesStates} = this.props,
          formError = templatesStates.get("formError");
        additionalClass = formError!==''?'modal-min-height h-100':'modal-min-height';
      }

      modal = (
        <ModalBase { ...this.props } additionalClass={ additionalClass }>
          <ModalComponent {...this.props} />
        </ModalBase>
      );
    }

    return modal;
  }
  getDisclaimer(){
    const disclaimerData = this.props.generalStates.get('disclaimer');
    let disclaimer;
    if(disclaimerData.get('show')){
      disclaimer = (
        <Disclaimer 
          type={ disclaimerData.get('status') }
          content={ disclaimerData.get('description') }
          additionalClass={ 'floated' }
        />
      );
    }
    return disclaimer;
  }
  render(){
    const modal = this.getModal(),
          disclaimer = this.getDisclaimer();

    return (
      <div>
        { modal }
        { disclaimer }
      </div>
    );
  }
}
