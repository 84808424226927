import React, { Component, createRef } from 'react';
import { fromJS } from 'immutable';

import FormValidizr from '../form-validizr/form-validizr';
import Tooltip from '../modules/tooltip';
import DetailListBySearch from '../modules/detail-list-by-search/detail-list-by-search';
import IsFetching from '../modules/is-fetching';
import { templatesStates } from '../../reducers/templates';
import Disclaimer from '../modules/disclaimer';
import { getIdEnterprise } from '../../utilities/utilities';

export default class TemplateForm extends Component{
  constructor(props){
    super(props);

    this.form = createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.addTemplateCategory = this.addTemplateCategory.bind(this);
    this.addTemplateCustomFields = this.addTemplateCustomFields.bind(this);
    this.toggleTemplateCategory = this.toggleTemplateCategory.bind(this);
    this.toggleTemplateCustomFields = this.toggleTemplateCustomFields.bind(this);
  }
  handleSubmit(){
    const { templatesStates, currentUser } = this.props,
          templateForm = templatesStates.get('templateForm'),
          selectedCategories = templatesStates.get('selectedElements').get('selectedCategories'),
          selectedCustomFields = templatesStates.get('selectedElements').get('selectedCustomFields'),
          args = {
            usuario: currentUser.get('userData').get('username'),
            nombre_plantilla: templateForm.get('title'),
            descripcion: templateForm.get('description'),
            // repaired: templateForm.get('repaired'),
            campos: selectedCustomFields.toJS(),
            categoria: selectedCategories.toJS(),
            empresa: getIdEnterprise(this.props)
          };

    if(!templatesStates.get('isEditing'))
      this.props.createTemplate(args);
    else
      this.props.editTemplateData(templatesStates.get('isEditing'), args);
  }
  inputHandler(target, event){
    const { templatesStates } = this.props,
          templateForm = templatesStates.get('templateForm');

    let value = target != 'customFieldAdd' ? event.target.value : event.target.value.replace(' ', '_');

    if(target == 'repaired')
      value = !templateForm.get(target);

    this.props.changeTemplateFields(target, value);
  }
  addTemplateCategory(element, dataType){
    const { currentUser } = this.props;
    if(element)
      this.props.addTemplateCategory(element, dataType, currentUser.get('userData').get('username'), getIdEnterprise(this.props));
  }
  addTemplateCustomFields(element){
    if(element)
      this.props.addTemplateCustomField(element);
  }
  toggleTemplateCategory(id, event){
    const { templatesStates } = this.props,
          selectedCategories = templatesStates.get('selectedElements').get('selectedCategories');

    if(!selectedCategories.includes(id)){
      this.props.selectTemplateCategory(id);
    }else{
      this.props.removeTemplateCategory(id);
    }
  }
  toggleTemplateCustomFields(id, event){
    const { templatesStates } = this.props,
          selectedCustomField = templatesStates.get('selectedElements').get('selectedCustomFields');
    
    if(!selectedCustomField.includes(id)){
      this.props.selectTemplateCustomField(id);
    }else{
      this.props.removeTemplateCustomField(id);
    }
  }
  getFilteredData(target, filterData){
    const { entities, templatesStates } = this.props,
          data = entities.get(target),
          filterValue = templatesStates.get('templateForm').get(filterData);

    let list = [];

    data.forEach((item) => {
      if(filterValue && item.get('nombre').toLowerCase().search(filterValue.toLowerCase()))
        return;

      list.push(item);
    });

    if(!list.length)
      list.push(
        { 
          id: 0,
          name: 'No se han encontrado datos con ese nombre'
        }
      )

    return fromJS(list);

  }
  getDisclaimer(){
    const { templatesStates } = this.props,
          error = templatesStates.get('formError');

    if(error){
      return (
        <div className="wrapper horizontal" style={{paddingBottom: 0}}>
          <Disclaimer type={ 'error' } content={ error } />
        </div>
      ) 
    }
    return null;
  }
  render(){
    const { templatesStates, entities } = this.props,
          templateForm = templatesStates.get('templateForm'),
          formButtonText = templatesStates.get('isEditing') ? 'Editar plantilla' : 'Crear plantilla',
          disclaimer = this.getDisclaimer();

    return (
      <div>
        <header className="modal-header">
          <h3 className="modal-title">Nueva plantilla</h3>
        </header>
        { disclaimer }
        <IsFetching isFetching={ templatesStates.get('isFetchingForm') } showChildren={ true }>
          <div className="modal-body d-flex">
            <div className="w-50">
              <FormValidizr 
                disableEnter={ false }
                formSubmitHandler={ this.handleSubmit }
                formHolderClass={ 'row wrapper inner' }
                formButton={{
                  buttonClass: 'btn',
                  text: formButtonText,
                  holder: 'align-center'
                }}
                formFields={[
                  {
                    data: [
                      {
                        tag: 'input',
                        type: 'text',
                        name: 'template-name',
                        invalidSetup: {
                          icons: {
                            valid: 'icon-element check field-icon',
                            invalid: 'icon-element rounded info red field-icon',
                          },
                          tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un nombre para la plantilla" />
                        },
                        placeholder: 'Ingrese el nombre de la plantilla',
                        value: templateForm.get('title'),
                        label: 'Nombre:',
                        onChangeFunc: this.inputHandler.bind(null, 'title'),
                        validators: ['required']
                      },
                      {
                        tag: 'textarea',
                        name: 'template-description',
                        placeholder: 'Ingrese una descripción breve de la plantilla',
                        value: templateForm.get('description'),
                        label: 'Descripción:',
                        onChangeFunc: this.inputHandler.bind(null, 'description'),
                      }
                    ]
                  }
                ]}>
              </FormValidizr> 
            </div>
            <div className="w-50">
              <div className="wrapper">
                <DetailListBySearch
                  title="Custom Fields"
                  dataType="custom"
                  placeholder="Buscar custom field"
                  addValuePlaceholder="Agregar nueva custom field"
                  searchValue={ templateForm.get('customFieldsSearch') }
                  categoryValue={ templateForm.get('customFieldAdd') }
                  onChangeFunc={ this.inputHandler.bind(null, 'customFieldsSearch') }
                  onChangeAddValue={ this.inputHandler.bind(null, 'customFieldAdd') }
                  onAddElement={ this.addTemplateCategory }
                  isFetching={ templatesStates.get('isFetchingCustomFields') }
                  data={this.getFilteredData('customFields', 'customFieldsSearch')}
                  error={ templatesStates.get('errors').get('customFields')  }
                  selectedElements={ templatesStates.get('selectedElements').get('selectedCustomFields') }
                  onDetailElementClick={ this.toggleTemplateCustomFields }
                />
              </div>
            </div>
          </div>
        </IsFetching>
      </div>
    );
  }
}