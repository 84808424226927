import axios, { post } from 'axios';
import { apiUrl_asistente, apiUrl_campanas, apiUrl_plantillas, apiUrl_auth, apiUrl_integracion } from './constants';
import { removeSessionStorage, getIdEnterprise } from "../utilities/utilities";
import moment from "moment";
import { dataURIToBlob } from '../utilities/utilities';

const errorMessage = 'Error de conexión, intente nuevamente';

//GENERALES
export function setModal(modalSlug) {
    return {
        type: 'SET_MODAL',
        modalSlug
    }
}

export function confirmData(text, func) {
    return {
        type: 'CONFIRM_DATA',
        text,
        func
    }
}

export function filterTemplatebyString(value) {
    return {
        type: 'FILTER_TEMPLATE_BY_STRING',
        value
    }
}

export function inputFormCampaign(target, value) {
    return {
        type: 'INPUT_FORM_CAMPAIGN',
        target,
        value
    }
}

export function selectTemplateCampaign(value) {
    return {
        type: 'SELECT_TEMPLATE_CAMPAIGN',
        value
    }
}

export function selectCampaignCSVFile(value, file) {
    return {
        type: 'SELECT_CAMPAIGN_CSV_FILE',
        value,
        file
    }
}

export function selectCampaignUpdateFile(value, file) {
    return {
        type: 'SELECT_CAMPAIGN_UPDATE_FILE',
        value,
        file
    }
}

export function selectAssistantHeaderImage(value, file) {
    return {
        type: 'SELECT_ASSISTANT_HEADER_IMAGE',
        value,
        file
    }
}

export function selectAssistantImage(value, file) {
    return {
        type: 'SELECT_ASSISTANT_IMAGE',
        value,
        file
    }
}

export function changeAssistantHeaderColor(color) {
    return {
        type: 'CHANGE_ASSISTANT_HEADER_COLOR',
        color
    }
}

export function changeAssistantTitleColor(color) {
    return {
        type: 'CHANGE_ASSISTANT_TITLE_COLOR',
        color
    }
}

export function changeAssistantButtonColor(color) {
    return {
        type: 'CHANGE_ASSISTANT_BUTTONS_COLOR',
        color
    }
}

export function changeAssistantTitle(value) {
    return {
        type: 'CHANGE_ASSISTANT_TITLE',
        value
    }
}

export function changeAssistantBubbleBackgroundColor(value) {
    return {
        type: 'CHANGE_ASSISTANT_BUBBLE_BACKGROUND_COLOR',
        value
    }
}

export function changeAssistantBubbleColor(value) {
    return {
        type: 'CHANGE_ASSISTANT_BUBBLE_COLOR',
        value
    }
}

export function changeAssistantHeaderFileUrl(value) {
    return {
        type: 'CHANGE_ASSISTANT_HEADER_FILE_URL',
        value
    }
}

export function changeAssistantAvatarFileUrl(value) {
    return {
        type: 'CHANGE_ASSISTANT_AVATAR_FILE_URL',
        value
    }
}

////////LOGIN
export function setLoginFields(target, value) {
    return {
        type: 'SET_LOGIN_FIELDS',
        target,
        value
    }
}

export function setInvalidFields(invalidFields) {
    return {
        type: 'SET_INVALID_INPUTS',
        invalidFields
    }
}

export function removeInvalidField(field) {
    return {
        type: 'REMOVE_INVALID_FIELDS',
        field
    }
}

export function logout() {
    return function action(dispatch) {
        sessionStorage.removeItem("loggIn");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        sessionStorage.removeItem("timer");
        sessionStorage.removeItem("enterprise");
        dispatch({ type: "LOGOUT" });
    };
}

////////DASHBOARD
export function filterDashboardDates(startDate, endDate) {
    return {
        type: 'FILTER_DASHBOARD_DATES',
        startDate,
        endDate
    }
}

export function setDashboardStartDate(date) {
    return {
        type: 'SET_DASHBOARD_START_DATE',
        date
    }
}

export function setDashboardEndDate(date) {
    return {
        type: 'SET_DASHBOARD_END_DATE',
        date
    }
}

export function changeDashboardTab(index) {
    return {
        type: 'CHANGE_DASHBOARD_TAB',
        index
    }
}

////////TEMPLATES
export function changeTemplateFields(target, value) {
    return {
        type: 'CHANGE_TEMPLATE_FIELD',
        target,
        value
    }
}

export function changeFormFields(target, value) {
    return {
        type: 'CHANGE_USER_FIELDS',
        target,
        value
    }
}

export function selectTemplateCategory(id) {
    return {
        type: 'SELECT_TEMPLATE_CATEGORY',
        id
    }
}

export function removeTemplateCategory(id) {
    return {
        type: 'REMOVE_TEMPLATE_CATEGORY',
        id
    }
}

export function selectTemplateCustomField(id) {
    return {
        type: 'SELECT_CUSTOM_FIELD',
        id
    }
}

export function removeTemplateCustomField(id) {
    return {
        type: 'REMOVE_CUSTOM_FIELD',
        id
    }
}

export function searchListFilter(target, value) {
    return {
        type: 'SEARCH_LIST_FILTER',
        target,
        value
    }
}

export function editTemplate(data, customFields, categories) {
    return {
        type: 'EDIT_TEMPLATE',
        data,
        customFields,
        categories
    }
}

///////CAMPAIGNS
export function cleanNewCampaign() {
    return {
        type: 'CLEAN_NEW_CAMPAIGN'
    }
}

export function switchCampaignView(value) {
    return {
        type: 'SWITCH_CAMPAIGN_VIEW',
        value
    }
}

export function changeCampaignStepForm(step) {
    return {
        type: 'SET_CAMPAIGN_STEP_FORM',
        step
    }
}

export function addCampaignPayDay(day) {
    return {
        type: 'ADD_CAMPAIGN_PAY_DAY',
        day
    }
}

export function removeCampaignPayDay(index) {
    return {
        type: 'REMOVE_CAMPAIGN_PAY_DAY',
        index
    }
}

export function editCampaign(data) {
    return {
        type: 'EDIT_CAMPAIGN',
        data
    }
}

export function changeCampaignHistoryTab(index) {
    return {
        type: 'CHANGE_CAMPAIGN_HISTORY_TAB',
        index
    }
}

export function changeTabsDashboard(index, target) {
    return {
        type: 'CHANGE_TABS_DASHBOARD',
        index,
        target
    }
}

export function changeClientsTab(index) {
    return {
        type: 'CHANGE_CAMPAIGN_CLIENTS_TAB',
        index
    }
}

export function filterUserCampaign(value) {
    return {
        type: 'FILTER_USER_CAMPAIGN',
        value
    }
}

export function filterCampaign(value) {
    return {
        type: 'FILTER_CAMPAIGN',
        value
    }
}

export function filterStatusCampaign(value) {
    return {
        type: 'FILTER_STATUS_CAMPAIGN',
        value
    }
}

export function filterTemplateCampaign(value) {
    return {
        type: 'FILTER_TEMPLATE_CAMPAIGN',
        value
    }
}

export function setFilterCampaigns(campaignTemplateFilter, campaignStatusFilter, campaignFilter) {
    return {
        type: 'SET_FILTERS_CAMPAIGNS',
        campaignTemplateFilter,
        campaignStatusFilter,
        campaignFilter
    }
}

export function selectSingleCampaignCSVFile(value, file) {
    return {
        type: 'SELECT_SINGLE_CAMPAIGN_CSV_FILE',
        value,
        file
    }
}

export function changeClientsCampaignTab(index) {
    return {
        type: 'CHANGE_CLIENTS_CAMPAIGN_TAB',
        index
    }
}

export function removeCampaignExceptionDay(index) {
    return {
        type: 'REMOVE_CAMPAIGN_EXCEPTION_DAY',
        index
    }
}

export function addCampaignExceptionDay(date) {
    return {
        type: 'ADD_CAMPAIGN_EXCEPTION_DAY',
        date
    }
}

export function setSendDates(dates) {
    return {
        type: 'SET_SEND_DATES',
        dates
    }
}

//USERS
export function editUser(data) {
    return {
        type: 'EDIT_USER',
        data
    }
}

export function removeUserPermission(index) {
    return {
        type: 'REMOVE_USER_PERMISSION',
        index
    }
}

export function addUserPermission(value) {
    return {
        type: 'ADD_USER_PERMISSION',
        value
    }
}

//ASISTENTE
export function changeAssistantState(target, value) {
    return {
        type: 'CHANGE_ASSISTANT_STATE',
        target,
        value
    }
}

export function setAssistantSettingsState(target, value) {
    return {
        type: 'SET_ASSISTANT_SETTINGS_STATE',
        target,
        value
    }
}

//////////////////////////////////////////////////Async

//TOKEN
function getTokenEnd(data) {
    return {
        type: "GET_TOKEN_END",
        data
    };
}

function getToken(data, dispatch) {
    return new Promise((resolve, reject) => {
        console.log('getting token: url' + apiUrl_auth + "token")
        const request = axios({
            method: "POST",
            headers: {
                Authorization: `Basic ${btoa(data.username + ":" + data.password)}`,
                "Content-Type": "application/json;charset=UTF-8"
            },
            url: apiUrl_auth + "token",
            data: {}
        });
        return request
            .then(response => {
                resolve(response);
                dispatch(getTokenEnd(response.data.data));
            })
            .catch(error => {
                dispatch(fetchLoginError(errorMessage));
            });
    });
}

export function refreshToken(dispatch, states) {
    const currentUser = states.currentUser;
    return new Promise((resolve, reject) => {
        if (currentUser.get("isFetchingRefresh")) {
            resolve(200);
            return false;
        }
        dispatch(isFetchingRefreshStart());
        const request = axios({
            method: "POST",
            headers: {
                Authorization: `Bearer ${currentUser.get("refresh_token")}`,
                "Content-Type": "application/json;charset=UTF-8"
            },
            url: apiUrl_auth + "refresh",
            data: {}
        });

        return request.then(
            response => {
                if (response.data.estado.codigoEstado === 200) {
                    dispatch(
                        isFetchingRefreshEnd(
                            response.data.data.token,
                            response.data.data.refresh_token,
                            moment().format("YYYY/MM/DD HH:mm:ss")
                        )
                    );
                    sessionStorage.setItem("token", response.data.data.token);
                    sessionStorage.setItem(
                        "refresh_token",
                        response.data.data.refresh_token
                    );
                    sessionStorage.setItem(
                        "timer",
                        moment().format("YYYY/MM/DD HH:mm:ss")
                    );
                    resolve(200);
                } else {
                    dispatch(isFetchingRefreshEnd("", ""));
                    removeSessionStorage();
                    dispatch(logout());
                }
            },
            err => {
                dispatch(isFetchingRefreshEnd("", ""));
                removeSessionStorage();
                dispatch(logout());
            }
        );
    });
}

function isFetchingRefreshStart() {
    return {
        type: "REFRESH_TOKEN_START"
    };
}

function isFetchingRefreshEnd(token, refresh_token, timer) {
    return {
        type: "REFRESH_TOKEN_END",
        token,
        refresh_token,
        timer,
    };
}

//LOGIN
export function recoverPassChange(username, password) {
    return function action(dispatch, getStates) {
        dispatch(fetchLoginStart());

        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'ACTUALIZAR_CONTRASENA_USUARIO',
            data: {
                username,
                password
            }
        });

        return request.then(
            (response) => {
                if (response.data.estado.codigo === 200) {
                    dispatch(loginUser({
                        dispositivo: 'Desktop',
                        geolocalizacion: '',
                        ip: '',
                        navegador: '',
                        password,
                        username
                    }));
                } else {
                    dispatch(fetchLoginError(response.data.estado.glosa));
                }
            },
            (err) => {
                dispatch(fetchLoginError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}

export function loginUser(data) {
    return function action(dispatch, getStates) {
        dispatch(fetchLoginStart());
        getToken(data, dispatch).then(response => {
            if (response.status === 204) {
                dispatch(fetchLoginError("Email y/o contraseña inválidos."));
            } else if (response.data.estado.codigoEstado === 200) {
                loginUserData(response.data.data, data, dispatch, getStates);
            } else {
                dispatch(fetchLoginError(errorMessage));
            }
        });
    };
}

function loginUserData(tokens, data, dispatch, getStates) {
    const request = axios({
        method: "POST",
        headers: {
            'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
            "Content-Type": "application/json;charset=UTF-8"
        },
        url: apiUrl_auth + "LOGEO",
        data
    });
    return request.then(
        response => {
            if (response.data.estado.codigo === 200) {
                const userData = response.data.data.users,
                    features = response.data.data.features;
                const timer = moment().format("YYYY/MM/DD HH:mm:ss");
                sessionStorage.setItem("loggIn", JSON.stringify(userData));
                sessionStorage.setItem("token", tokens.token);
                sessionStorage.setItem("refresh_token", tokens.refresh_token);
                sessionStorage.setItem("timer", timer);
                localStorage.setItem('features', JSON.stringify(features));
                dispatch(fetchLoginEnd(userData, features, 200, timer));
            } else if (response.data.estado.codigo === 400) {
                dispatch(fetchLoginError(response.data.estado.glosa));
            } else if (response.data.estado.codigo === 300) {
                dispatch(fetchLoginChangePass(response.data.data[0].usuario));
            }
        },
        err => {
            dispatch(fetchLoginError(errorMessage));
        }
    );
}

function fetchLoginStart() {
    return {
        type: 'FETCH_LOGIN_START'
    }
}

function fetchLoginEnd(userData, features, code, timer) {
    return {
        type: 'FETCH_LOGIN_END',
        userData,
        features,
        code,
        timer
    }
}

function fetchLoginError(error) {
    return {
        type: 'FETCH_LOGIN_ERROR',
        error,
        messageStatus: 'error'
    }
}

function fetchLoginChangePass(user) {
    return {
        type: 'FETCH_LOGIN_CHANGE_PASS',
        user
    }
}

//RECOVER PASS
export function sendRecoverPass(email) {
    return function action(dispatch) {
        dispatch(sendRecoverPassStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            url: apiUrl_auth + 'RECUPERAR_CONTRASENA_USUARIO',
            data: {
                correo: email
            }
        });

        return request.then(
            (response) => {
                if (response.data.estado.codigo === 200) {
                    dispatch(sendRecoverPassEnd(response.data.estado.glosa))
                } else {
                    dispatch(sendRecoverPassError(response.data.estado.glosa));
                }
            },
            (err) => { dispatch(sendRecoverPassError(err)); }
        );
    }
}

function sendRecoverPassStart() {
    return {
        type: 'SEND_RECOVER_PASS_START'
    }
}

function sendRecoverPassEnd(message) {
    return {
        type: 'SEND_RECOVER_PASS_END',
        message,
        messageStatus: 'success'
    }
}

function sendRecoverPassError(message) {
    return {
        type: 'SEND_RECOVER_PASS_ERROR',
        message,
        messageStatus: 'error'
    }
}

//DASHBOARD
export function getDashboardData(args) {
    //  apiUrl_asistente, apiUrl_campanas, apiUrl_plantillas, apiUrl_auth 
    var url_endpoint = ''
    switch (args['endpoint'].split('/')[0]) {
        case 'auth': url_endpoint = apiUrl_auth + '/' + args['endpoint'].split('/')[1]; break;
        case 'asistente': url_endpoint = apiUrl_asistente + '/' + args['endpoint'].split('/')[1]; break;
        case 'campanas': url_endpoint = apiUrl_campanas + '/' + args['endpoint'].split('/')[1]; break;
        case 'plantillas': url_endpoint = apiUrl_plantillas + '/' + args['endpoint'].split('/')[1]; break;
    }

    return function action(dispatch, getStates) {
        dispatch(loadingDashboardData(args.slug));
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            // url: apiUrl + args.endpoint, 
            url: url_endpoint,
            data: args.data
        });
//setTimeout(() => {
        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    const metrics = data.data.data;

                    if (args.ord) {
                        metrics.sort((a, b) => {
                            if (a[args.ord] < b[args.ord])
                                return -1;
                            if (a[args.ord] > b[args.ord])
                                return 1;
                            return 0;
                        });
                    }

                    if (args.parseDays) {
                        metrics.map((day, index) => {
                            metrics[index].name = getDays(day.name);
                        });
                    }

                    dispatch(loadingDashboardDataEnd(metrics, data.data.kpis, data.data.b64, args.slug))
                } else {
                    dispatch(loadingDashboardDataEnd([], [], '', args.slug))
                }
            },
            (err) => {
                dispatch(loadingDashboardDataEnd([], [], '', args.slug))
            }
        )
 //        }, 2000)
    }
}

function loadingDashboardData(slug) {
    return {
        type: 'LOADING_DASHBOARD_DATA',
        slug
    }
}

function loadingDashboardDataEnd(data, kpis, b64, slug) {
    return {
        type: 'LOADING_DASHBOARD_DATA_END',
        data,
        kpis,
        b64,
        slug
    }
}

function getDays(day) {
    switch (day) {
        case 'sun':
            return 'Dom';
        case 'mon':
            return 'Lun';
        case 'tue':
            return 'Mar';
        case 'wed':
            return 'Mie';
        case 'thu':
            return 'Jue';
        case 'fri':
            return 'Vie';
        case 'sat':
            return 'Sab';
        default:
            return day;
    }
}

export function getClientResponsesById(usuario, id_campana, fecha_inicio, fecha_termino) {
    return function action(dispatch, getStates) {
        dispatch(getClientResponsesByIdStart());

        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'DASHBOARD_RESPUESTAS',
            data: {
                usuario,
                id_campana,
                fecha_inicio,
                fecha_termino,
                empresa: getIdEnterprise(getStates())
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(getClientResponsesByIdEnd(data.data));
                } else {
                    dispatch(getClientResponsesByIdEnd([]));
                }
            },
            (err) => {
                dispatch(getClientResponsesByIdEnd([]));
            }
        );
    }
}

function getClientResponsesByIdStart() {
    return {
        type: 'GET_CLIENTS_RESPONSES_BY_ID_START'
    }
}

function getClientResponsesByIdEnd(data) {
    return {
        type: 'GET_CLIENTS_RESPONSES_BY_ID_END',
        data
    }
}

export function getClientResponses(usuario, fecha_inicio, fecha_termino) {
    return function action(dispatch, getStates) {
        dispatch(getClientsResponsesStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'DASHBOARD_RESPUESTAS',
            data: {
                usuario,
                id_campana: '',
                fecha_inicio,
                fecha_termino,
                empresa: getIdEnterprise(getStates())
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(getClientsResponsesEnd(data.data));
                } else {
                    dispatch(getClientsResponsesEnd([]));
                }
            },
            (err) => {
                dispatch(getClientsResponsesEnd([]));
            }
        );
    }
}

function getClientsResponsesStart() {
    return {
        type: 'GET_CLIENTS_RESPONSES_START'
    }
}

function getClientsResponsesEnd(data) {
    return {
        type: 'GET_CLIENTS_RESPONSES_END',
        data
    }
}

export function getSingleCampaignSatisfaction(id) {
    return function action(dispatch) {
        dispatch(getSingleCampaignSatisfactionStart());

        setTimeout(() => {
            const data = [
                { name: 'Satisfacción', data: 113 },
                { name: 'Neutro', data: 24 },
                { name: 'Insatisfacción', data: 35 }
            ];

            const kpis = [
                {
                    key: 'Promdeio',
                    value: 3.7
                },
                {
                    key: 'Satisfacción neta',
                    value: '45.3%'
                }
            ];

            dispatch(getSingleCampaignSatisfactionEnd(data, kpis))
        }, 1000);
    }
}


function getSingleCampaignSatisfactionStart() {
    return {
        type: 'GET_SINGLE_CAMPAIGN_SATISFACTION_START'
    }
}

function getSingleCampaignSatisfactionEnd(data, kpis) {
    return {
        type: 'GET_SINGLE_CAMPAIGN_SATISFACTION_END',
        data,
        kpis
    }
}

export function getClientSatisfaction(starDate, endDate) {
    return function action(dispatch) {
        dispatch(getClientSatisfactionStart());
        setTimeout(() => {
            const data = [
                { name: 'Satisfacción', data: 113 },
                { name: 'Neutro', data: 24 },
                { name: 'Insatisfacción', data: 35 }
            ];
            const kpis = [
                {
                    key: 'Promdeio',
                    value: 3.7
                },
                {
                    key: 'Satisfacción neta',
                    value: '45.3%'
                }
            ]
            dispatch(getClientSatisfactionEnd(data, kpis))
        }, 1000)
    }
}

function getClientSatisfactionStart() {
    return {
        type: 'GET_CLIENT_SATISFACTION'
    }
}

function getClientSatisfactionEnd(data, kpis) {
    return {
        type: 'GET_CLIENT_SATISFACTION_END',
        data,
        kpis
    }
}

//TEMPLATES
export function getTemplates(user, empresa) {
    return function action(dispatch, getStates) {
           setTimeout(() => {
        dispatch(getTemplatesStart());
            const request = axios({
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                url: apiUrl_plantillas + 'LISTAR_PLANTILLAS_CAMPANA',
                data: {
                    usuario: user,
                    empresa: empresa
                }
            });

            return request.then(
                (response) => {
                    const data = response.data;
                    if (data.estado.codigo === 200) {
                        const templates = data.data.plantillas,
                            categories = data.data.campos.filter((field) => {
                                return field.tipo_dato === 'categoria';
                            }),
                            customFields = data.data.campos.filter((field) => {
                                return field.tipo_dato === 'custom';
                            }),
                            uniqueFields = data.data.campos.filter((field) => {
                                return field.tipo_dato === 'unicos';
                            });
                        dispatch(getTemplatesEnd(templates, categories, customFields, uniqueFields));
                    } else {
                        dispatch(getTemplatesError('Hubo un error al obtener las plantillas, intente nuevamente'))
                    }
                },
                (err) => {
                    dispatch(getTemplatesError('Error de conexión con el servidor, intente nuevamente'))
                }
            );
         }, 1700); 
    }
}

function getTemplatesStart() {
    return {
        type: 'GET_TEMPLATES',
    }
}

function getTemplatesEnd(data, categories, customFields, uniqueFields) {
    return {
        type: 'GET_TEMPLATE_END',
        data,
        categories,
        customFields,
        uniqueFields
    }
}

function getTemplatesError(error) {
    return {
        type: 'GET_TEMPLATES_ERROR',
        error
    }
}

export function addTemplateCategory(nombre, tipo_dato, usuario, empresa) {
    return function action(dispatch, getStates) {
        if (tipo_dato === 'categoria')
            dispatch(addTemplateCategoryStart());
        else
            dispatch(addTemplateCustomFieldStart());

        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_plantillas + 'CREAR_CAMPO',
            data: {
                nombre,
                tipo_dato,
                usuario,
                empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;

                if (data.estado.codigo === 200) {
                    const newArgs = {
                        id_campo: data.data,
                        nombre,
                        tipo_dato
                    }
                    if (tipo_dato === 'categoria') {
                        dispatch(addTemplateCategoryEnd(newArgs));
                    } else {
                        dispatch(addTemplateCustomFieldEnd(newArgs));
                    }
                } else {
                    if (tipo_dato === 'categoria')
                        dispatch(addTemplateCategoryError(data.estado.glosa))
                    else
                        dispatch(addTemplateCustomFieldError(data.estado.glosa))
                }
            },
            (err) => {
                if (tipo_dato === 'categoria')
                    dispatch(addTemplateCategoryError('Error de conexión con el servidor, intente nuevamente'))
                else
                    dispatch(addTemplateCustomFieldError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}


function addTemplateCategoryStart() {
    return {
        type: 'ADD_TEMPLATE_CATEGORY_START'
    }
}

function addTemplateCategoryEnd(data) {
    return {
        type: 'ADD_TEMPLATE_CATEGORY_END',
        data
    }
}

function addTemplateCategoryError(error) {
    return {
        type: 'ADD_TEMPLATE_CATEGORY_ERROR',
        error
    }
}

export function addTemplateCustomField(element) {
    return function action(dispatch) {
        dispatch(addTemplateCustomFieldStart());
        setTimeout(() => {
            const data = {
                id: Math.random(),
                name: element
            }

            dispatch(addTemplateCustomFieldEnd(data));
            // dispatch(addTemplateCategoryError('Hubo un error o la categoría ya está registrada'))
        }, 1000)
    }
}

function addTemplateCustomFieldStart() {
    return {
        type: 'ADD_TEMPLATE_CUSTOM_FIELD_START'
    }
}

function addTemplateCustomFieldEnd(data) {
    return {
        type: 'ADD_TEMPLATE_CUSTOM_FIELD_END',
        data
    }
}

function addTemplateCustomFieldError(error) {
    return {
        type: 'ADD_TEMPLATE_CUSTOM_FIELD_ERROR',
        error
    }
}

export function createTemplate(args) {
    return function action(dispatch, getStates) {
        dispatch(createTemplateStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_plantillas + 'CREAR_PLANTILLA',
            data: args
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    args.campanas = [];
                    args.id_plantilla = data.data;
                    args.b64 = data.b64;
                    dispatch(createTemplateEnd(args));
                    dispatch(setModal(false));
                } else {
                    dispatch(createTemplateError(data.estado.glosa))
                }
            },
            (err) => {
                dispatch(createTemplateError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}


function createTemplateStart() {
    return {
        type: 'CREATE_TEMPLATE_START'
    }
}

function createTemplateEnd(data) {
    return {
        type: 'CREATE_TEMPLATE_END',
        data
    }
}

function createTemplateError(error) {
    return {
        type: 'CREATE_TEMPLATE_ERROR',
        error
    }
}

export function editUserData(userData) {
    return function action(dispatch, getStates) {
        dispatch(editUserDataStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'MODIFICAR_USUARIO',
            data: userData
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(editUserDataEnd(userData));
                    dispatch(setModal(false));
                } else {
                    dispatch(editUserDataError(data.estado.glosa))
                }
            },
            (err) => {
                dispatch(editUserDataError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}

function editUserDataStart() {
    return {
        type: 'EDIT_USER_DATA_START'
    }
}

function editUserDataEnd(data) {
    return {
        type: 'EDIT_USER_DATA_END',
        data
    }
}

function editUserDataError(error) {
    return {
        type: 'EDIT_USER_ERROR',
        error
    }
}

export function editTemplateData(id, data) {
    return function action(dispatch) {
        dispatch(editTemplateStart());
        dispatch(setModal(false));
        setTimeout(() => {
            data.id = id;
            dispatch(editTemplateEnd(data));
        }, 1000)
    }
}

function editTemplateStart() {
    return {
        type: 'EDIT_TEMPLATE_START'
    }
}

function editTemplateEnd(data) {
    return {
        type: 'EDIT_TEMPLATE_END',
        data
    }
}

export function deleteTemplate(index, id_plantilla, usuario, empresa) {
    return function action(dispatch, getStates) {
        dispatch(deleteTemplateStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_plantillas + 'DESACTIVA_PLANTILLAS',
            data: {
                id_plantilla,
                usuario,
                empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(deleteTemplateEnd(index));
                    dispatch(setModal(false));
                } else {
                    dispatch(deleteTemplateError(data.estado.glosa))
                }
            },
            (err) => {
                dispatch(deleteTemplateError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}

function deleteTemplateStart() {
    return {
        type: 'DELETE_TEMPLATE_START'
    }
}

function deleteTemplateEnd(index) {
    return {
        type: 'DELETE_TEMPLATE_END',
        index
    }
}

function deleteTemplateError(error) {
    return {
        type: 'DELETE_TEMPLATE_ERROR',
        error
    }
}


//CAMPAIGNS
export function changeChannelData(target, editorState, targetMarkup) {
    return {
        type: 'CHANGE_CHANNEL_DATA',
        target,
        editorState,
        targetMarkup
    }
}

export function selectEmailTemplateCampaign(value) {
    return {
        type: 'SELECT_EMAIL_TEMPLATE_CAMPAIGN',
        value
    }
}

export function getCampaigns(usuario, offset, limit, empresa, id_plantilla, estado, search_string) {

    return function action(dispatch, getStates) {
        
        return new Promise((resolve, reject) => {
             
            dispatch(getCampaignsStart());

            const data = {
                usuario,
                limit,
                offset,
                id_plantilla,
                estado,
                search_string,
                empresa,
            };
            setTimeout(() => {
            const request = axios({
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                url: apiUrl_campanas + 'INFO_CAMPANAS',
                data,
            });

            
                request.then(
                    (response) => {
                        const data = response.data;
                        if (data.estado.codigo === 200) {
                            dispatch(getCampaignsEnd(data.data));
                            resolve(data.data);
                        } else {
                            dispatch(getCampaignsError(data.estado.glosa));
                            reject(data.estado.glosa);
                        }
                    },
                    (err) => {
                        dispatch(getCampaignsError('Error de conexión con el servidor, intente nuevamente'));
                        reject(err);
                    }
                );
                 }, 1900);
            });
    };
};

export function getCampaign(id) {
    return function action(dispatch) {
        dispatch(getCampaignsStart());
        setTimeout(() => {
            const data = [
                {
                    id,
                    status: 'En curso',
                    startDate: '2018/04/05',
                    endDate: '2018/04/15',
                    created: '2018/03/18',
                    createdBy: 'Jorge Codina',
                    userCanSelectConcurrency: true,
                    concurrency: 'Una sola vez',
                    webpay: true,
                    urlPayValue: 'http://www.google.cl',
                    urlPay: true,
                    sms: true,
                    email: true,
                    voz: true,
                    template: 1,
                    payDays: [0, 1, 2, 3, 4],
                    clients: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    contactedClients: [1, 2, 3, 4, 5, 6, 7]
                }
            ]

            dispatch(getCampaignsEnd(data));
        }, 1000)
    }
}

function getCampaignsStart() {
    return {
        type: 'GET_CAMPAIGNS_START'
    }
}

function getCampaignsEnd(data) {
    return {
        type: 'GET_CAMPAIGNS_END',
        data
    }
}

function getCampaignsError(error) {
    return {
        type: 'GET_CAMPAIGNS_ERROR',
        error
    }
}

export function getMoreCampaigns(usuario, offset, limit, id_plantilla = '', estado = '', search_string = '', empresa) {
    return function action(dispatch, getStates) {
        dispatch(getMoreCampaignsStart());

        const data = {
            usuario,
            limit,
            offset,
            id_plantilla,
            estado,
            search_string,
            empresa,
        }

        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'INFO_CAMPANAS',
            data,
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(getMoreCampaignsEnd(data.data));
                } else {
                    dispatch(getCampaignsError(data.estado.glosa))
                }
            },
            (err) => {
                dispatch(getCampaignsError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}

function getMoreCampaignsStart() {
    return {
        type: 'GET_MORE_CAMPAIGNS_START'
    }
}

function getMoreCampaignsEnd(data) {
    return {
        type: 'GET_MORE_CAMPAIGNS_END',
        data
    }
}

export function submitCampaignUpdateFile(username, file, id_campaña) {
    return function action(dispatch) {
        dispatch(submitCampaignUploadFileStart());
        setTimeout(() => {
            const data = {
                clientes_contactados: [1, 2],
                formas_pago: [],
                contactados: 2,
                fecha_inicio: "2018/10/18",
                no_contactados: 4,
                url_pay_value: "",
                usuario: "ag@ag.cl",
                id_campana: 2,
                fecha_creacion: "2018/10/18",
                deuda_total: 19765416,
                dias_excepcion: "",
                excluidos: 5,
                estado_campana: "en curso",
                fecha_termino: "2018/10/25",
                concurrencia: "Todos los días",
                hr_envio: "08",
                id_plantilla: 40,
            }
            dispatch(submitCampaignUploadFileEnd(data, 'Archivo cargado exitosamente'));
            dispatch(setModal(false));
            // dispatch(submitCampaignUploadFileError('Error de conexión, por favor inténtalo'));
        }, 1000);
    }
}

function submitCampaignUploadFileStart() {
    return {
        type: 'SUBMIT_CAMPAIGN_UPLOAD_FILE_START'
    }
}

function submitCampaignUploadFileError(error) {
    return {
        type: 'SUBMIT_CAMPAIGN_UPLOAD_FILE_ERROR',
        error
    }
}

function submitCampaignUploadFileEnd(data, msg) {
    return {
        type: 'SUBMIT_CAMPAIGN_UPLOAD_FILE_DATA',
        data,
        msg
    }
}

export function submitCampaignCSVFile(username, file, id_plantilla, id_empresa) {
    return async function action(dispatch, getStates) {
        dispatch(submitCampaignCSVFileStart());
        const formData = new FormData();
        formData.append('usuario', username);
        formData.append('file', file);
        formData.append('id_plantilla', id_plantilla);
        formData.append('id_empresa', id_empresa);
        const config = {
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Access-Control-Allow-Origin': '*',
                'content-type': 'multipart/form-data'
            }
        }

        //const request = post(apiUrl_plantillas + 'LEER_ARCHIVO', formData, config);
        //console.log("apiUrl_integracion: " + apiUrl_integracion)
        const request = post(apiUrl_integracion + id_empresa + '/leer-archivo', formData, config);

        return request.then(
            (response) => {
                const data = response.data;
                //console.log("return integracion leer-archivo type data: " + typeof (data));
                //console.log("return integracion leer-archivo type response: " + typeof (response.data));
                //console.log("return integracion leer-archivo type response: " + JSON.stringify(response.data));
                //console.log("return integracion leer-archivo response.data.data:  %O", data);
                if (data.estado.codigo === 200) {

                    //dispatch(submitCampaignCSVFileEnd(data.data));
                    const messages = {
                        success: data.data,
                        warnings: data.data.warnings,
                        errors: { title: data.data.error }
                    }
                    dispatch(submitCampaignCSVFileEnd(messages));
                } else {
                    const messages = {
                        success: data.data.success,
                        warnings: data.data.warnings,
                        //errors: { title: data.data.error }
                        errors: data.data.errors
                    }
                    dispatch(submitCampaignCSVFileEnd(messages));
                }
            },
            (err) => { console.log('error carga de archivo') }
        )
    }
}

function submitCampaignCSVFileStart() {
    return {
        type: 'SUBMIT_CAMPAIGN_CSV_FILES_START'
    }
}

function submitCampaignCSVFileEnd(data) {
    return {
        type: 'SUBMIT_CAMPAIGN_CSV_FILE_END',
        data
    }
}

export function submitSingleCampaignCSVFile(file) {
    return function action(dispatch) {
        dispatch(submitSingleCampaignCSVFileStart());
        setTimeout(() => {

            const data = {
                success: {},
                warnings: {},
                errors: {
                    title: 'Lorem ipsum dolor sit amet consectetur'
                }
            }

            dispatch(submitSingleCampaignCSVFileEnd(data));
        }, 3000);
    }
}

function submitSingleCampaignCSVFileStart() {
    return {
        type: 'SUBMIT_SINGLE_CAMPAIGN_CSV_FILES_START'
    }
}

function submitSingleCampaignCSVFileEnd(data) {
    return {
        type: 'SUBMIT_SINGLE_CAMPAIGN_CSV_FILE_END',
        data
    }
}

export function createCampaign(args, history) {
    return function action(dispatch, getStates) {
        return new Promise((resolve, reject) => {
            dispatch(createCampaignStart());

            const request = axios({
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                url: apiUrl_campanas + 'CREAR_CAMPANA',
                data: args
            });

            return request.then(
                (response) => {
                    const data = response.data;
                    if (data.estado.codigo === 200) {
                        args.id_campana = data.data;
                        args.clientes_contactados = [];
                        dispatch(createCampaignEnd(args));
                        dispatch(setModal(false));
                        dispatch(getCampaigns(args.usuario, 0, 20, args.empresa, '', '', ''));
                        dispatch(getTemplates(args.usuario, args.empresa));
                        history.push('/campanas')
                    } else {
                        dispatch(createCampaignError(data.estado.glosa))
                    }
                },
                (err) => {
                    dispatch(createCampaignError('Error de conexión con el servidor, intente nuevamente'))
                }
            );
        });
    }
}

function createCampaignStart() {
    return {
        type: 'CREATE_CAMPAIGN_START'
    }
}

function createCampaignEnd(data) {
    return {
        type: 'CREATE_CAMPAIGN_END',
        data
    }
}

function createCampaignError(error) {
    return {
        type: 'CREATE_CAMPAIGN_ERROR',
        error
    }
}

export function cancelCampaign(usuario, id_campana, empresa) {
    return function action(dispatch, getStates) {
        dispatch(cancelCampaignStart());

        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'CANCELAR_CAMPANA',
            data: {
                usuario,
                id_campana,
                empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(cancelCampaignEnd(id_campana));
                    dispatch(setModal(false))
                } else {
                    dispatch(cancelCampaignError(data.estado.glosa));
                }
            },
            (err) => {
                dispatch(cancelCampaignError('error de coneión, intentalo nuevamente'));
            }
        );
    }
}

function cancelCampaignStart() {
    return {
        type: 'CANCEL_CAMPAIGN_START'
    }
}

function cancelCampaignEnd(id_campana) {
    return {
        type: 'CANCEL_CAMPAIGN_END',
        id_campana
    }
}

function cancelCampaignError(error) {
    return {
        type: 'CANCEL_CAMPAIGN_ERROR',
        error
    }
}

//USERS
export function getUsers(usuario, empresa, id_usuario = '') {
    return function action(dispatch, getStates) {
        dispatch(getUsersStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'VER_USUARIO',
            data: {
                usuario,
                id_usuario,
                empresa
            }
        });

        return request.then((response) => {
            dispatch(getUsersEnd(response.data.data));
        }).catch((error) => {
            if (error.response && error.response.status == 401) {
                dispatch(logout())
            }
            else {
                dispatch(getUsersError(error.response ? error.response.data.estado.glosaEstado : errorMessage));
            }
        });
    }
}

function getUsersStart() {
    return {
        type: 'GET_USERS_START'
    }
}

function getUsersEnd(data) {
    return {
        type: 'GET_USERS_END',
        data
    }
}

function getUsersError(error) {
    return {
        type: 'GET_USER_ERROR',
        error
    }
}

export function createUser(userData) {
    return function action(dispatch, getStates) {
        dispatch(createUserStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'CREAR_USUARIO',
            data: userData
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    userData.password = '';
                    dispatch(createUserEnd(userData));
                    dispatch(setModal(false))
                } else {
                    dispatch(createUserError(data.estado.glosa))
                }
            },
            (err) => {
                dispatch(createUserError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}

function createUserStart() {
    return {
        type: 'CREATE_USER_START'
    }
}

function createUserEnd(data) {
    return {
        type: 'CREATE_USER_END',
        data
    }
}

function createUserError(error) {
    return {
        type: 'CREATE_USER_ERROR',
        error
    }
}

export function deleteUser(index, id_usuario, currentUser, empresa) {
    return function action(dispatch, getStates) {
        dispatch(deleteUserStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'DESACTIVAR_USUARIO',
            data: {
                usuario: currentUser,
                id_usuario,
                empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(deleteUserEnd(index));
                    dispatch(setModal(false));
                } else {
                    dispatch(deleteUserError(data.estado.glosa))
                }
            },
            (err) => {
                dispatch(deleteUserError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}

function deleteUserStart() {
    return {
        type: 'DELETE_USER_START'
    }
}

function deleteUserEnd(index) {
    return {
        type: 'DELETE_USER_END',
        index
    }
}

function deleteUserError(error) {
    return {
        type: 'DELETE_USER_END',
        error
    }
}

export function sessionAuth(userData, features) {
    return function action(dispatch, getStates) {
        dispatch(sessionAuthStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'VALIDAR_SESION',
            data: {
                token: userData.token,
                username: userData.username,
                empresa: userData.empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;

                if (data.estado.codigo === 200) {
                    dispatch(fetchLoginEnd(userData, features));
                } else {
                    dispatch(logout())
                }
            },
            (err) => {
                dispatch(logout())
            }
        );
    }
}

function sessionAuthStart() {
    return {
        type: 'SESSION_AUTH_START'
    }
}

function sessionAuthError(error) {
    return {
        type: 'SESSION_AUTH_ERROR',
        error
    }
}

export function logoutSessionAuth(userData) {
    return function action(dispatch, getStates) {
        dispatch(sessionAuthStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'DESACTIVAR_SESION',
            data: {
                token: userData.token,
                username: userData.username,
                empresa: userData.empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;

                if (data.estado.codigo === 200) {
                    dispatch(logout())
                } else {
                    console.log('error al cerrar sesión');
                }
            },
            (err) => {
                dispatch(deleteUserError('Error de conexión con el servidor, intente nuevamente'))
            }
        );
    }
}

export function downloadCampaignClientsCSV(usuario, id_campana, empresa, numero_campana) {
    return function action(dispatch, getStates) {
        dispatch(downloadCampaignClientsCSVStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'CSV_RESUMEN_CAMPANA',
            data: {
                usuario,
                id_campana,
                empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    let a = document.createElement('a');
                    a.href = dataURIToBlob(data.data);
                    a.setAttribute('download', 'clientes-campana-' + numero_campana + '.csv');
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    dispatch(downloadCampaignClientsCSVEnd());
                } else {
                    console.log('error en la descarga');
                }
            },
            (err) => {
                console.log('error en la descarga')
            }
        );
    }
}

export function downloadChartTypeCSV(usuario, id_campana, empresa, tipo, fecha_inicio, fecha_termino, reagendamiento) {
    return function action(dispatch, getStates) {
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'csv',
            data: {
                usuario,
                id_campana,
                empresa,
                tipo,
                fecha_inicio,
                fecha_termino,
                reagendamiento,
            }
        });

        return request.then(
            (response) => {
                console.warn("response: ", response)
                const data = response.data;
                if (data.estado.codigo === 200) {
                    let a = document.createElement('a');
                    a.href = dataURIToBlob(data.data);
                    a.setAttribute('download', tipo + '.csv');
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    console.log('error en la descarga');
                }
            },
            (err) => {
                console.log('error en la descarga')
            }
        );
    }
}



export function downloadCampaignCSV(usuario, id_plantilla, id_campana, empresa, numero_campana) {
    return function action(dispatch, getStates) {
        dispatch(downloadCampaignCSVStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'DESCARGAR_CSV',
            data: {
                usuario,
                id_plantilla,
                id_campana,
                empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    let a = document.createElement('a');
                    a.href = dataURIToBlob(data.data);
                    a.setAttribute('download', 'clientes-campana-' + numero_campana + '.csv');
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    dispatch(downloadCampaignCSVEnd());
                } else {
                    console.log('error en la descarga');
                }
            },
            (err) => {
                console.log('error en la descarga')
            }
        );
    }
}

function downloadCampaignCSVStart() {
    return {
        type: 'DOWNLOAD_CAMPAIGN_CSV'
    }
}

function downloadCampaignCSVEnd() {
    return {
        type: 'DOWNLOAD_CAMPAIGN_CSV_END'
    }
}

function downloadCampaignClientsCSVStart() {
    return {
        type: 'DOWNLOAD_CAMPAIGN_CLIENTS_CSV'
    }
}

function downloadCampaignClientsCSVEnd() {
    return {
        type: 'DOWNLOAD_CAMPAIGN_CLIENTS_CSV_END'
    }
}

export function getCustomAssistantData(data) {
    return function action(dispatch) {
        dispatch(getCustomAssistantDataStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_asistente + 'GET_CUSTOM',
            data
        });

        request.then(
            (response) => {
                if (response.data.estado && response.data.estado.codigoEstado != 200) {
                    dispatch(getCustomAssistantDataError(response.data.estado.glosa))
                } else {

                    dispatch(getCustomAssistantDataEnd(response.data.data))
                }
            },
            (err) => {
                dispatch(getCustomAssistantDataError('error de conexión'))
            }
        );
    }
}

function getCustomAssistantDataStart() {
    return {
        type: 'GET_CUSTOM_ASSISTANT_DATA_START'
    }
}

function getCustomAssistantDataEnd(data) {
    return {
        type: 'GET_CUSTOM_ASSISTANT_DATA_END',
        data
    }
}

function getCustomAssistantDataError(error) {
    return {
        type: 'GET_CUSTOM_ASSISTANT_DATA_ERROR',
        error
    }
}

export function submitCustomization(args) {
    return function action(dispatch) {
        dispatch(submitCustomizationStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_asistente + 'UPDATE_CUSTOM',
            data: args
        });

        request.then(
            (response) => {
                dispatch(submitCustomizationEnd());
            },
            (err) => {
                console.log('error')
            }
        );
    }
}


function submitCustomizationStart() {
    return {
        type: 'SUBMIT_CUSTOMIZATION_START'
    }
}

function submitCustomizationEnd() {
    return {
        type: 'SUBMIT_CUSTOMIZATION_END'
    }
}

export function getAssistantInteractions(args) {
    return function action(dispatch, getStates) {
        dispatch(getAssistantInteractionsStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_asistente + 'INTERACTIONS',
            data: args
        });

        request.then(
            (response) => {
                if (response.data.estado.codigo !== 200) {
                    dispatch(getAssistantInteractionsError(response.data.estado.glosaEstado))
                } else {
                    const data = response.data.data,
                        conversation = data.conversacion,
                        calendarDates = data.array_fechas ? data.array_fechas : [];

                    conversation[conversation.length - 1].showButton = true
                    const lastOutput = conversation[conversation.length - 1].output[conversation[conversation.length - 1].output.length - 1];

                    if (lastOutput.search('%%%END SESSION%%%') != -1) {
                        dispatch(endAssistantSession(data.cid, conversation))
                    } else {
                        let endSession = false;
                        if ((lastOutput.search('%%%') != -1) || calendarDates.length)
                            endSession = true;

                        dispatch(getAssistantInteractionFirstEnd(data.cid, conversation, endSession, calendarDates))
                    }
                }
            },
            (err) => {
                console.log('error')
            }
        );
    }
}

function getAssistantInteractionsStart() {
    return {
        type: 'GET_ASSISTANT_INTERACTIONS_START'
    }
}

function getAssistantInteractionsError(error) {
    return {
        type: 'GET_ASSISTANT_INTERACTIONS_ERROR', error
    }
}

function getAssistantInteractionFirstEnd(cid, conversation, endSession, calendarDates) {
    return {
        type: 'GET_ASSISTANT_INTERACTIONS_FIRST_END',
        cid,
        conversation,
        endSession,
        calendarDates
    }
}

function getAssistantInteractionsEnd(cid, index, i, endSession, conversation, calendarDates) {
    return {
        type: 'GET_ASSISTANT_INTERACTIONS_END',
        cid,
        conversation,
        index,
        i,
        endSession,
        calendarDates
    }
}

function endAssistantSession(cid, conversation) {
    return {
        type: 'END_ASSISTANT_SESSION',
        cid,
        conversation
    }
}

export function sendAssistantMessage(args, index, i) {
    return function action(dispatch) {
        dispatch(sendAssistantMessageStart(index, i, [
            {
                input: {
                    text: args.input
                },
                output: []
            }
        ]))
        const request = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_asistente + 'DIALOG',
            data: args
        });
        request.then(
            (response) => {
                const data = response.data.data;
                if (response.data.code == 200) {
                    const lastMessage = response.data.data.text[response.data.data.text.length - 1],
                        calendarDates = data.array_fechas ? data.array_fechas : [];

                    let endSession = false;
                    if (lastMessage.search('%%%') != -1 || calendarDates.length) {
                        endSession = true;
                    }

                    dispatch(getAssistantInteractionsEnd(args.cid, index, i, endSession, [
                        {
                            input: {
                                text: ''
                            },
                            showButton: true,
                            output: response.data.data.text
                        }
                    ], calendarDates))
                } else if (response.data.code == 400) {
                    dispatch(getAssistantInteractionsEnd(args.cid, index, i, true, [
                        {
                            input: {
                                text: ''
                            },
                            output: [response.data.estado.glosa]
                        }
                    ], []))
                }
            },
            (err) => {
                console.log('error')
            }
        );
    }
}


function sendAssistantMessageStart(index, i, conversation) {
    return {
        type: 'SEND_ASSISTANT_MESSAGE_START',
        conversation,
        index,
        i
    }
}


export function cleanAssistant(args) {
    return function action(dispatch, getStates) {
        dispatch(getAssistantInteractionsStart());
        dispatch(cleanAssistantEnd(args))
        sessionStorage.setItem('assistantType', args.tipo)
        const request = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_asistente + 'LIMPIAR_ASISTENTE',
            data: args
        });

        request.then(
            (response) => {
                const data = response.data.data;
                if (response.data.estado.codigo == 200) {
                    dispatch(getAssistantInteractions({
                        contextID: 'TESTS',
                        tipo: args.tipo,
                        empresa: getIdEnterprise(getStates())
                    }));
                }
            },
            (err) => {
                console.log('error')
            }
        );
    }
}

function cleanAssistantEnd(args) {
    return {
        type: 'CLEAN_ASSISTANT_END',
        args
    }
}

//EMAIL TEMPLATES
export function editEmailTemplate(data) {
    return {
        type: 'EDIT_EMAIL_TEMPLATE',
        data
    }
}

export function selectEmailTemplateHTMLFile(value, file, fileContent) {
    return {
        type: 'SELECT_EMAIL_TEMPLATE_HTML_FILE',
        value,
        file,
        fileContent
    }
}

export function deleteEmailTemplate(index, id_plantilla, usuario, empresa) {
    return function action(dispatch, getStates) {
        dispatch(deleteEmailTemplateStart());

        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'DESACTIVAR_PLANTILLA_EMAIL',
            data: {
                usuario,
                id_plantilla,
                empresa
            }
        });

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200) {
                    dispatch(deleteEmailTemplateEnd(index));
                    dispatch(setModal(false));
                } else {
                    dispatch(deleteTemplateError(data.estado.glosa))
                }
            },
            (err) => {
                dispatch(deleteTemplateError('Error de conexión, por favor inténtalo nuevamente'))
            }
        );
    }
}

function deleteEmailTemplateStart() {
    return {
        type: 'DELETE_EMAIL_TEMPLATE_START'
    }
}

function deleteEmailTemplateEnd(index) {
    return {
        type: 'DELETE_EMAIL_TEMPLATE_END',
        index
    }
}

export function submitEmailTemplateHTMLFile(username, file, fileName, fileContent, idEmpresa) {
    return function action(dispatch, getStates) {
        dispatch(submitEmailTemplateHTMLFileStart());
        const formData = new FormData();
        formData.append('usuario', username);
        formData.append('nombre', fileName);
        formData.append('file', file);
        formData.append('file_content', fileContent.replace(/'/g, "%27"));
        formData.append('empresa', idEmpresa);

        const config = {
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'content-type': 'multipart/form-data'
            }
        }

        const request = post(apiUrl_campanas + 'AGREGAR_PLANTILLA_EMAIL', formData, config);

        return request.then(
            (response) => {
                const data = response.data;
                if (data.estado.codigo === 200)
                    dispatch(submitEmailTemplateHTMLFileEnd(data.data));
                else
                    dispatch(emailTemplateError(data.estado.glosa))
            },
            (err) => {
                dispatch(emailTemplateError('Error de conexión, inténtelo nuevamente'))
            }
        );
    }
}

function submitEmailTemplateHTMLFileStart() {
    return {
        type: 'SUBMIT_EMAIL_TEMPLATE_HTML_START'
    }
}

function submitEmailTemplateHTMLFileEnd(data) {
    return {
        type: 'SUBMIT_EMAIL_TEMPLATE_HTML_END',
        data
    }
}

function emailTemplateError(error) {
    return {
        type: 'EMAIL_TEMPLATE_ERROR',
        error
    }
}

export function getEmailTemplatesConfig(usuario, empresa) {
    return function action(dispatch, getStates) {
        dispatch(getEmailTemplatesConfigStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'email-config',
            data: {
                usuario,
                empresa
            }
        });

        request.then(
            (response) => {
                const data = response.data.data;
                if (response.data.estado.codigo == 200) {
                    dispatch(getEmailTemplatesConfigEnd(data));
                } else {
                    dispatch(getEmailTemplatesConfigError('Ha ocurrido un error al obtener la data, por favor intentalo nuevamente'));
                }
            },
            (err) => {
                dispatch(getEmailTemplatesConfigError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
            }
        );
    }
}

function getEmailTemplatesConfigStart() {
    return {
        type: 'GET_EMAIL_TEMPLATES_CONFIG_START'
    }
}

function getEmailTemplatesConfigError(error) {
    return {
        type: 'GET_EMAIL_TEMPLATES_CONFIG_ERROR',
        error
    }
}

function getEmailTemplatesConfigEnd(data) {
    return {
        type: 'GET_EMAIL_TEMPLATES_CONFIG_END',
        data
    }
}


export function getEmailTemplates(usuario, empresa) {
    return function action(dispatch, getStates) {
        dispatch(getEmailTemplatesStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_campanas + 'VER_PLANTILLA_EMAIL',
            data: {
                usuario,
                empresa
            }
        });

        request.then(
            (response) => {
                const data = response.data.data;
                if (response.data.estado.codigo == 200) {
                    dispatch(getEmailTemplateEnd(data));
                } else {
                    dispatch(getEmailTemplateError('Ha ocurrido un error al obtener la data, por favor intentalo nuevamente'));
                }
            },
            (err) => {
                dispatch(getEmailTemplateError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
            }
        );
    }
}


function getEmailTemplatesStart() {
    return {
        type: 'GET_EMAIL_TEMPLATES_START'
    }
}

function getEmailTemplateEnd(data) {
    return {
        type: 'GET_EMAIL_TEMPLATES_END',
        data
    }
}

function getEmailTemplateError(error) {
    return {
        type: 'GET_EMAIL_TEMPLATE_ERROR',
        error
    }
}

export function onChangeChannel(step) {
    return {
        type: 'ON_CHANGE_CHANNEL',
        step
    }
}

export function setButtonColor(data) {
    return function action(dispatch) {
        dispatch({ type: "SET_BUTTON_COLOR", data: data.hex })
    }
}

export function setButtonBackground(data) {
    return function action(dispatch) {
        dispatch({ type: "SET_BUTTON_BACKGROUND_COLOR", data: data.hex })
    }
}

export function setButtonText(data) {
    return function action(dispatch) {
        dispatch({ type: "SET_BUTTON_TEXT", data })
    }
}

//ADMIN ENTERPRISES
export function getEnterprises() {
    return function action(dispatch, getStates) {
        dispatch(getEnterprisesStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'ver-empresa',
        });

        request.then(
            (response) => {
                const data = response.data.data;
                if (response.data.estado.codigo == 200) {
                    // se deja por defecto que tome la primera empresa con la que está logueado
                    const sessionEnterprise = parseInt(window.sessionStorage.getItem('enterprise'));
                    //SI YA TIENE EL SESSIONSTORAGE, QUEDA CON ESE
                    const idEnterpriseLoged = !isNaN(sessionEnterprise) ? sessionEnterprise : getStates().currentUser.get('userData').get('id_empresa');
                    window.sessionStorage.setItem('enterprise', idEnterpriseLoged);
                    const enterpriseLogued = data.filter(enterprise => enterprise.id === idEnterpriseLoged);
                    dispatch({ type: "CHANGE_ENTERPRISE", data: enterpriseLogued[0] });
                    dispatch(getEnterprisesEnd(data));
                }
            },
            (err) => {
                dispatch(getEnterprisesError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
            }
        );
    }
}

function getEnterprisesEnd(data) {
    return { type: 'GET_ENTERPRISES_END', data }
}

function getEnterprisesError(error) {
    return { type: 'GET_ENTERPRISES_ERROR', error }
}

function getEnterprisesStart() {
    return { type: 'GET_ENTERPRISES_START' }
}

export function changeEnterprise(data) {
    return function action(dispatch) {
        dispatch({ type: "CHANGE_ENTERPRISE", data })
    }
}

export function getAllFeatures() {
    return function action(dispatch, getStates) {
        dispatch(getAllFeaturesStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'get-features',
        });

        request.then(
            (response) => {
                const data = response.data.data;
                if (response.data.estado.codigo == 200) {
                    dispatch(getAllFeaturesEnd(data));
                }
            },
            (err) => {
                dispatch(getAllFeaturesError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
            }
        );
    }
}

function getAllFeaturesStart() {
    return { type: 'GET_FEATURES' }
}

function getAllFeaturesEnd(data) {
    return { type: 'GET_FEATURES_END', data }
}

function getAllFeaturesError(data) {
    return { type: 'GET_FEATURES_ERROR', data }
}

export function removeEnterpriseFeature(index) {
    return {
        type: 'REMOVE_ENTERPRISE_FEATURE',
        index
    }
}

export function addEnterpriseFeature(value) {
    return {
        type: 'ADD_ENTERPRISE_FEATURE',
        value
    }
}

export function removeEnterpriseModalFeature(index) {
    return {
        type: 'REMOVE_ENTERPRISE_MODAL_FEATURE',
        index
    }
}

export function addEnterpriseModalFeature(value) {
    return {
        type: 'ADD_ENTERPRISE_MODAL_FEATURE',
        value
    }
}

export function changeFormFieldsEnterprise(target, value) {
    return {
        type: 'CHANGE_ENTERPRISE_FIELDS',
        target,
        value
    }
}
export function changeFormModalFieldsEnterprise(target, value) {
    return {
        type: 'CHANGE_ENTERPRISE_MODAL_FIELDS',
        target,
        value
    }
}
export function changeFormEnterprise(data) {
    return {
        type: 'CHANGE_ENTERPRISE_FORM',
        data
    }
}

export function changeFormFieldsVariablesEnterprise(target, value) {
    return {
        type: 'CHANGE_ENTERPRISE_VARIABLES_FIELDS',
        target,
        value
    }
}

export function resetFormModalEnterprise() {
    return {
        type: 'RESET_ENTERPRISE_FORM_MODAL'
    }
}

export function createEnterprise(data, type) {
    return function action(dispatch, getStates) {
        dispatch(createEnterpriseStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'add-empresa',
            data
        });
        request.then(
            (response) => {
                if (response.data.estado.codigoEstado == 200) {
                    dispatch(createEnterpriseEnd(response.data.estado.glosa));
                    if (type === "create") {
                        dispatch(setModal(false));
                        let enterprises = getStates().entities.get('enterprises').toJS()
                        enterprises.push(response.data.data);
                        dispatch(getEnterprisesEnd(enterprises));
                        dispatch({ type: "CHANGE_ENTERPRISE", data: response.data.data });
                    }
                } else {
                    dispatch(createEnterpriseError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
                }
            },
            (err) => {
                dispatch(createEnterpriseError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
            }
        );
    }
}

function createEnterpriseStart() {
    return { type: 'CREATE_ENTERPRISE_START' }
}

function createEnterpriseEnd(message) {
    return { type: 'CREATE_ENTERPRISE_END', message }
}
function createEnterpriseError(error) {
    return { type: 'CREATE_ENTERPRISE_ERROR', error }
}

export function changeAdminEnterpriseTab(index) {
    return {
        type: 'CHANGE_ENTERPRISE_TAB',
        index
    }
}

export function editVariablesEnterprise(data) {
    return function action(dispatch, getStates) {
        dispatch(editVariablesEnterpriseStart());
        const request = axios({
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${getStates().currentUser.get('token')}`,
                'Content-Type': 'application/json;charset=UTF-8'
            },
            url: apiUrl_auth + 'variables',
            data
        });

        request.then(
            (response) => {
                if (response.data.estado.codigoEstado == 200) {
                    dispatch(editVariablesEnterpriseEnd(response.data.estado.glosa));
                } else {
                    dispatch(editVariablesEnterpriseError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
                }
            },
            (err) => {
                dispatch(editVariablesEnterpriseError('Ha ocurrido un error de conexión, por favor intentalo nuevamente'));
            }
        );
    }
}

function editVariablesEnterpriseStart() {
    return { type: 'EDIT_ENTERPRISE_VARIABLES_START' }
}

function editVariablesEnterpriseEnd(message) {
    return { type: 'EDIT_ENTERPRISE_VARIABLES_END', message }
}
function editVariablesEnterpriseError(error) {
    return { type: 'EDIT_ENTERPRISE_VARIABLES_ERROR', error }
}

// END ADMIN ENTERPRISES
