import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

import IsFetching from '../modules/is-fetching';
import InnerHeader from '../modules/inner-header';
import SettingsAssistantContent from './settings-assistant-content';
import { getIdEnterprise } from '../../utilities/utilities';

export default class AssistantSettings extends Component{
  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeAssistantTitle = this.changeAssistantTitle.bind(this);
    this.submitFileHeader = this.submitFileHeader.bind(this);
    this.submitFileAvatar = this.submitFileAvatar.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentWillMount(){
    this.fetchData();
  }
  fetchData(){
    const { currentUser } = this.props;
    this.props.getCustomAssistantData({empresa:getIdEnterprise(this.props)});
  }
  inputHandler(target, event){
    this.props.setAssistantSettingsState(target, event.target.value);
  }
  submitFileHeader(event){
    const { assistantSettingsStates } = this.props,
          file = assistantSettingsStates.get('headerFile');

    if(/\.(jpe?g|png|gif)$/i.test(file.name)){
      const reader = new FileReader();

      reader.addEventListener("load", (event) => {
        this.props.changeAssistantHeaderFileUrl(event.target.result);
      }, false);

      reader.readAsDataURL(file);

    }
  }
  submitFileAvatar(event){
    const { assistantSettingsStates } = this.props,
          file = assistantSettingsStates.get('assistantFile');
    
    if(/\.(jpe?g|png|gif)$/i.test(file.name)){
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        this.props.changeAssistantAvatarFileUrl(event.target.result);
      }, false);

      reader.readAsDataURL(file);

    }
  }
  handleSubmit(event){
    event.preventDefault();
    const { assistantSettingsStates, currentUser } = this.props,
          args = {
            tag:"asistente-digital",
            tamano_letra:"15",
            font_type:"helvetica1",
            imagen_cabecera: assistantSettingsStates.get('headerFileUrl'),
            subtitulo: assistantSettingsStates.get('assistantTitle'),
            header_color_fondo: assistantSettingsStates.get('headerColor'),
            color_botones: assistantSettingsStates.get('buttonsColor'),
            avatar_url: assistantSettingsStates.get('assistantFileUrl'),
            color_titulos: assistantSettingsStates.get('titleColor'),
            user_audit: currentUser.get('userData').get('username'),
            color_titulo_encabezado: assistantSettingsStates.get('titleColor'),
            color_fondo_burbuja: assistantSettingsStates.get('bubbleBackgroundColor'),
            color_texto_burbuja: assistantSettingsStates.get('bubbleColor'),
            empresa: getIdEnterprise(this.props)
          }

    this.props.submitCustomization(args);
  }
  changeAssistantTitle(event){
    this.props.changeAssistantTitle(event.target.value);
  }
  render(){
    const { assistantSettingsStates } = this.props,
          error = assistantSettingsStates.get('fetchError');

    return(
      <IsFetching isFetching={ assistantSettingsStates.get('isFetching') } error={ error } errorFunc={ this.fetchData }>
        <InnerHeader title="Asistente de cobranza" >
          <div className="header-actions-holder">
            <button className="btn btn-small" onClick={ this.handleSubmit }>Guardar</button>
          </div>
        </InnerHeader>
        <SettingsAssistantContent { ...this.props } />
      </IsFetching>
    );
  }
}