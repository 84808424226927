import React, { Component } from "react";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import ContentEditable from "../../content-editable/content-editable";
import ColorPicker from "../../modules/color-picker";

export default class StepFormChannelContentEmail extends Component {
  constructor(props) {
    super(props);

    this.selectTemplate = this.selectTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeButtonText = this.changeButtonText.bind(this);

    this.iframeData = React.createRef();
  }
  componentDidMount() {
    this.setupIframe();
  }
  componentDidUpdate() {
    this.setupIframe();
  }
  setupIframe() {
    const { campaignStates, entities, channelsConfigStates } = this.props,
      emailConfig = channelsConfigStates.get("emailConfig"),
      campaignForm = campaignStates.get("campaignForm"),
      emailId = campaignForm.get("emailTemplate"),
      emailTemplate = entities.get("emailTemplates").filter(data => {
        return data.get("id") == emailId;
      }),
      content = emailTemplate.get("0")
        ? emailTemplate.get("0").get("file_content")
        : "",
      iframe = this.iframeData.current;

    if (!iframe) {
      return false;
    }
    const document = iframe.contentDocument;
    const newContent = content
      ? content
          .replace(
            "email_markup",
            campaignForm.get("emailMarkup") +
              '<a href="#" style="display: inline-block; background-color: ' +
              emailConfig.get("buttonBackgroundColor") +
              "; border-radius: 50px; color: " +
              emailConfig.get("buttonTextColor") +
              '; padding:15px 60px; font-weight: bold; font-size: 16px; text-decoration: none;">' +
              emailConfig.get("buttonText") +
              "</a>"
          )
          .replace(/Â/g, "")
      : content;

    document.body.innerHTML = newContent;
  }
  handleChange(target, editorstate, event) {
    const rawData = convertToRaw(editorstate.getCurrentContent());

    this.props.changeChannelData(
      target + "Markup",
      draftToHtml(rawData),
      editorstate.getCurrentContent().getPlainText()
    );
  }
  selectTemplate(event) {
    this.props.selectEmailTemplateCampaign(event.target.value);
  }
  changeButtonText(event){
    this.props.setButtonText(event.target.value);
  }
  getEmailTemplatesOptionas() {
    const { entities } = this.props,
      emailTemplates = entities.get("emailTemplates");

    return emailTemplates.map(template => {
      return (
        <option
          key={"template-value-" + template.get("id")}
          value={template.get("id")}
        >
          {template.get("name")}
        </option>
      );
    });
  }
  getIframeData() {
    const { campaignStates, entities } = this.props,
      campaignForm = campaignStates.get("campaignForm"),
      emailTemplate = entities.get("emailTemplates").filter(data => {
        return data.get("id") == campaignForm.get("emailTemplate");
      });

    if (emailTemplate.get(0)) {
      return (
        <div className="box no-shadow m-b-20">
          <iframe
            sandbox="allow-same-origin"
            className="campaign-email-iframe"
            ref={this.iframeData}
          />
        </div>
      );
    } else {
      return null;
    }
  }
  getTemplateFields() {
    const { campaignStates, entities } = this.props,
      campaignForm = campaignStates.get("campaignForm"),
      templateId = campaignForm.get("template"),
      template = entities.get("templates").filter(data => {
        return data.get("id_plantilla") === parseInt(templateId, 0);
      });

    let fields = [];

    entities.get("uniqueFields").forEach(field => {
      fields.push({
        name: field.get("nombre_despliegue"),
        type: "uniqueField"
      });
    });

    if (template.get("0")) {
      template
        .get("0")
        .get("campos")
        .forEach(field => {
          const fieldData = entities.get("customFields").filter(data => {
            return data.get("id_campo") === field;
          });
          fields.push({
            name: fieldData.get("0").get("nombre"),
            type: "customField"
          });
        });
    }

    return fields;
  }
  labelComponentRender(props) {
    const { mention, theme, searchValue, isFocused, ...parentProps } = props;

    return <div {...parentProps}>{mention.name}</div>;
  }
  getHtmlEdition() {
    const { campaignStates } = this.props,
      campaignForm = campaignStates.get("campaignForm"),
      templateFields = this.getTemplateFields();

    if (!campaignForm.get("emailTemplate")) return null;

    return (
      <div className="m-b-20">
        <ContentEditable
          id={"email-editor"}
          title={"Contenido del email:"}
          editorState={campaignForm.get("emailMarkupPlain")}
          onChangeFunc={this.handleChange.bind(null, "email")}
          labelComponentRender={this.labelComponentRender}
          placeholder={"Ingresa el contenido del email..."}
          mentions={templateFields}
        />
        <span className="field-disclaimer">
          Para agregar o buscar <strong>custom fields: @customField</strong>
        </span>
      </div>
    );
  }
  getButtonEdit() {
    const { campaignStates, channelsConfigStates, featuresStates } = this.props,
      emailConfig = channelsConfigStates.get("emailConfig"),
      campaignForm = campaignStates.get("campaignForm"),
      buttonBackgroundColor = emailConfig.get("buttonBackgroundColor"),
      buttonTextColor = emailConfig.get("buttonTextColor"),
      buttonText = emailConfig.get("buttonText"),
      features = featuresStates.get('features'),
      editButtonEmail = features.getIn(['campaignsFeatures','editButtonEmail']);

    if (!campaignForm.get("emailTemplate")) return null;

    if(editButtonEmail===0) return null;

    return (
      <div className="m-b-20">
        <div className="margin-bottom-wrapp">
          <div className="grid-6 margin-bottom-wrapp">
            <ColorPicker
              title={"Color fondo botón:"}
              color={buttonBackgroundColor}
              changeColorFunc={this.props.setButtonBackground}
            />
          </div>
          <div className="grid-6 margin-bottom-wrapp">
            <ColorPicker
              title={"Color texto botón:"}
              color={buttonTextColor}
              changeColorFunc={this.props.setButtonColor}
            />
          </div>
          <label className="grid-12">
            <span className="label-like">Texto botón:</span>
            <input
              type="text"
              name="button-title"
              value={buttonText}
              onChange={this.changeButtonText}
              placeholder={"Ingrese un título para el botón"}
            />
          </label>
        </div>
      </div>
    );
  }
  render() {
    
    const { campaignStates } = this.props,
      campaignForm = campaignStates.get("campaignForm"),
      emailTemplatesOptions = this.getEmailTemplatesOptionas(),
      iframeData = this.getIframeData(),
      htmlEdition = this.getHtmlEdition(),
      buttonEdit = this.getButtonEdit();
    return (
      <div className="wrapper clearfix">
        <h4>Configuración de email</h4>
        <div className="grid-4 no-gutter-left">
          <select
            className="m-b-20"
            name="campaign-template"
            value={campaignForm.get("emailTemplate")}
            onChange={this.selectTemplate}
          >
            <option value="">Seleccionar plantilla</option>
            {emailTemplatesOptions}
          </select>
          {htmlEdition}
          {buttonEdit}
        </div>
        <div className="grid-8 no-gutter-right">{iframeData}</div>
      </div>
    );
  }
}
