import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FilterField extends Component{
  getOptions(){
    const { options } = this.props;

    return options.map((data) => {
      return <option key={'option-'+data.key+'-'+data.value} value={ data.key }>{ data.value }</option>
    })

  }
  render(){
    const options = this.getOptions();
    return (
      <label className={ this.props.labelClass }>
        <span className="label-like">{ this.props.label }</span>
        <select className={ this.props.fieldClass } name={ this.props.name }  value={ this.props.value } onChange={ this.props.onChangeFunc }>
          { options }
        </select> 
      </label>
    );
  }
}

FilterField.propTypes = {
  labelClass: PropTypes.string,
  fieldClass: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChangeFunc: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string
}

FilterField.defaultProps = {
  labelClass: 'relative-position inline-element',
  fieldClass: 'filter-field'
}