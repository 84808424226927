import * as Immutable from 'immutable';
import moment from 'moment';

export function dashboardStates(state = Immutable.fromJS({
  startDate: moment().subtract(30, 'days'),
  endDate: moment(),
  activeTab: 0,
  data: {
    openingCampaignsPercent: [],
    openingCampaignsDay: [],
    openningCampaignsHour: [],
    clientsContacted: [],
    clientResponses: [],
    clientSatisfaction: [],
    retainedPayments: [],
    interactionsAssistant: [],
    recoverData: []
  },
  kpis: {
    openingCampaignsPercent: [],
    clientsContacted: [],
    satisfaction: []
  },
  b64: {
    openingCampaignsPercent: '',
    clientsContacted: '',
    retainedPayments: '',
    openingCampaignsHour: '',
    openingCampaignsDay: '',
    interactionsAssistant: '',
    recoverData: ''
  },
  isFetchingData: {
    openingCampaignsPercent: true,
    openingCampaignsDay: true,
    openingCampaignsHour: true,
    clientsContacted: true,
    clientResponses: true,
    clientSatisfaction: true,
    retainedPayments: true,
    interactionsAssistant: true,
    recoverData: true
  },
  tabs: {
    opening: 0,
    clients: 0
  }
}), action){
  switch(action.type){
    case 'SET_DASHBOARD_START_DATE':
      return state.set('startDate', action.date);
    case 'SET_DASHBOARD_END_DATE':
      return state.set('endDate', action.date);
    case 'CHANGE_DASHBOARD_TAB':
      return state.set('activeTab', action.index);
    case 'GET_OPENING_CAMPAIGN_PERCENT':
      return state.setIn(['isFetchingData', 'openingCampaignsPercent'], true);
    case 'GET_OPENING_CAMPAIGN_PERCENT_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'openingCampaignsPercent'], Immutable.fromJS(action.data))
          .setIn(['kpis', 'campaigns'], Immutable.fromJS(action.kpis))
          .setIn(['isFetchingData', 'openingCampaignsPercent'], false)
          .setIn(['b64', 'openingCampaignsPercent'], action.b64)
      });
    case 'GET_OPENING_CAMPAIGN_BY_DAY':
      return state.setIn(['isFetchingData', 'openingCampaignsDay'], true);
    case 'GET_OPENING_CAMPAIGN_BY_DAY_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'openingCampaignsDay'], Immutable.fromJS(action.data))
          .setIn(['isFetchingData', 'openingCampaignsDay'], false)
          .setIn(['b64', 'daysCampaign'], action.b64)
      });
    case 'GET_OPENING_CAMPAIGN_BY_HOUR':
      return state.setIn(['isFetchingData', 'openingCampaignsHour'], true);
    case 'GET_OPENING_CAMPAIGN_BY_HOUR_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'openingCampaignsHour'], Immutable.fromJS(action.data))
          .setIn(['isFetchingData', 'openingCampaignsHour'], false)
          .setIn(['b64', 'hourCampaign'], action.b64)
      });
    case 'GET_CLIENTS_CONTACTED':
      return state.setIn(['isFetchingData', 'clientsContacted'], true);
    case 'GET_CLIENTS_CONTACTED_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'clientsContacted'], Immutable.fromJS(action.data))
          .setIn(['kpis', 'clients'], Immutable.fromJS(action.kpis))
          .setIn(['isFetchingData', 'clientsContacted'], false)
          .setIn(['b64', 'clientsContacted'], action.b64)
      });
    case 'GET_CLIENTS_RESPONSES_START':
      return state.setIn(['isFetchingData', 'clientResponses'], true);
    case 'GET_CLIENTS_RESPONSES_END':
      return state.withMutations((map)=>{
        map.setIn(['isFetchingData', 'clientResponses'], false)
          .setIn(['data', 'clientResponses'], Immutable.fromJS(action.data))
      });
    case 'GET_CLIENT_SATISFACTION':
      return state.setIn(['isFetchingData', 'clientSatisfaction'], true);
    case 'GET_CLIENT_SATISFACTION_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'clientSatisfaction'], Immutable.fromJS(action.data))
          .setIn(['kpis', 'satisfaction'], Immutable.fromJS(action.kpis))
          .setIn(['isFetchingData', 'clientSatisfaction'], false);
      });
    case 'GET_INTERACTION_ASSISTANT_DASHBOARD_START':
      return state.setIn(['isFetchingData', 'interactionsAssistant'], true);
    case 'GET_INTERACTION_ASSISTANT_DASHBOARD_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'interactionsAssistant'], Immutable.fromJS(action.data))
          .setIn(['b64', 'interactionAssistant'], Immutable.fromJS(action.b64))
          .setIn(['isFetchingData', 'interactionsAssistant'], false);
      });
    case 'GET_RETAINED_PAYMENT_START':
      return state.setIn(['isFetchingData', 'retainedPayments'], true);
    case 'GET_RETAINED_PAYMENT_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'retainedPayments'], Immutable.fromJS(action.data))
          .setIn(['b64', 'retainedPayments'], Immutable.fromJS(action.b64))
          .setIn(['isFetchingData', 'retainedPayments'], false);
      });
    case 'GET_RECOVER_DATA_START':
      return state.setIn(['isFetchingData', 'recoverData'], true);
    case 'GET_RECOVER_DATA_END':
      return state.withMutations((map) => {
        map.setIn(['data', 'recoverData'], Immutable.fromJS(action.data))
          .setIn(['b64', 'recoverData'], Immutable.fromJS(action.b64))
          .setIn(['isFetchingData', 'recoverData'], false);
      });
    case 'CHANGE_TABS_DASHBOARD':
      return state.setIn(['tabs', action.target], action.index);
    case 'LOADING_DASHBOARD_DATA':
      return state.setIn(['isFetchingData', action.slug], true);
    case 'LOADING_DASHBOARD_DATA_END':
      return state.withMutations((map) => {
        map.setIn(['data', action.slug], Immutable.fromJS(action.data))
          .setIn(['b64', action.slug], Immutable.fromJS(action.b64))
          .setIn(['kpis', action.slug], Immutable.fromJS(action.kpis))
          .setIn(['isFetchingData', action.slug], false);
      });
    default:
      return state;
  }
}