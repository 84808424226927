import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Disclaimer extends Component{
  render(){
    const { additionalContent, additionalClass, content, type } = this.props;

    if(!content){ return null; }

    return (
      <div className={ 'disclaimer animated fadeInUp '+ type + ' '+ additionalClass }>
        <p className="disclaimer-description">{ content }</p>
        { additionalContent }
      </div>
    );
  }
}

Disclaimer.propTypes = {
  content: PropTypes.any,
  type: PropTypes.string,
  additionalClass: PropTypes.string,
  additionalContent: PropTypes.element
}