import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import InnerHeader from '../modules/inner-header';
import Tabs from '../modules/tabs';
import DasboardCampaigns from './dashboard-campaigns';
import DashboardClients from './dashboard-clients';
import DashboardAssistant from './dashboard-assistant';
import { getIdEnterprise } from '../../utilities/utilities';

export default class Dashboard extends Component{
  constructor(props){
    super(props);

    this.getDashboardCampaignData = this.getDashboardCampaignData.bind(this);
    this.filterHandler = this.filterHandler.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }
  handleChangeStart(date){
    this.props.setDashboardStartDate(date);
  }
  handleChangeEnd(date){
    this.props.setDashboardEndDate(date);
  }
  filterHandler(event){
    const { dashboardStates } = this.props,
          currentTab = dashboardStates.get('activeTab');

    if(currentTab === 0)      
      this.getDashboardCampaignData();
    else if(currentTab === 1)
      this.getDashboardClientData();
    else
      this.getDashboardAssistantData();
  }
  getDashboardCampaignData(){
    const { dashboardStates, currentUser } = this.props,
          startDate = dashboardStates.get('startDate').format('YYYY/MM/DD'), 
          endDate = dashboardStates.get('endDate').format('YYYY/MM/DD');
          
    //Campaigns data
    this.props.getDashboardData({
      slug: 'openingCampaignsPercent',
      endpoint: 'campanas/DASHBOARD_APERTURA',
      ord: 'ord',
      data: {
        usuario: currentUser.get('userData').get('username'),
        id_campana: '',
        fecha_inicio: startDate,
        fecha_termino: endDate,
        reagendamiento: !!dashboardStates.get('tabs').get('opening'),
        empresa: getIdEnterprise(this.props)
      }
    });

    this.props.getDashboardData({
      slug: 'openingCampaignsDay',
      endpoint: 'campanas/DASHBOARD_DIAS_APERTURA',
      parseDays: true,
      data: {
        usuario: currentUser.get('userData').get('username'),
        id_campana: '',
        fecha_inicio: startDate,
        fecha_termino: endDate,
        empresa: getIdEnterprise(this.props)
      }
    });

    this.props.getDashboardData({
      slug: 'openingCampaignsHour',
      endpoint: 'campanas/DASHBOARD_HORAS_APERTURA',
      ord: 'name',
      data: {
        usuario: currentUser.get('userData').get('username'),
        id_campana: '',
        fecha_inicio: startDate,
        fecha_termino: endDate,
        empresa: getIdEnterprise(this.props)
      }
    });
  }
  getDashboardClientData(){
    const { dashboardStates, currentUser } = this.props,
          startDate = dashboardStates.get('startDate').format('YYYY/MM/DD'), 
          endDate = dashboardStates.get('endDate').format('YYYY/MM/DD');
          
    this.props.getDashboardData({
      slug: 'clientsContacted',
      endpoint: 'campanas/DASHBOARD_CONTACTADOS',
      ord: 'ord',
      data: {
        usuario: currentUser.get('userData').get('username'),
        id_campana: '',
        fecha_inicio: startDate,
        fecha_termino: endDate,
        reagendamiento: !!dashboardStates.get('tabs').get('clients'),
        empresa: getIdEnterprise(this.props)
      }
    });

    this.props.getDashboardData({
      slug: 'recoverData',
      endpoint: 'campanas/DASHBOARD_RECUPERO',
      data: {
        usuario: currentUser.get('userData').get('username'),
        id_campana: '',
        fecha_inicio: startDate,
        fecha_termino: endDate,
        empresa: getIdEnterprise(this.props)
      }
    })

    this.props.getDashboardData({
      slug: 'retainedPayments',
      endpoint: 'campanas/DASHBOARD_PAGOS',
      ord: 'ord',
      data: {
        usuario: currentUser.get('userData').get('username'),
        id_campana: '',
        fecha_inicio: startDate,
        fecha_termino: endDate,
        empresa: getIdEnterprise(this.props)
      }
    });

    this.props.getClientResponses(currentUser.get('userData').get('username'), startDate, endDate,getIdEnterprise(this.props)) 
  }
  getDashboardAssistantData(){
    const { dashboardStates, currentUser } = this.props,
          startDate = dashboardStates.get('startDate').format('YYYY/MM/DD'), 
          endDate = dashboardStates.get('endDate').format('YYYY/MM/DD');

    this.props.getDashboardData({
      slug: 'interactionsAssistant',
      endpoint: 'asistente/DASHBOARD_INTERACCIONES',
      ord: 'ord',
      data: {
        usuario: currentUser.get('userData').get('username'),
        id_campana: '',
        fecha_inicio: startDate,
        fecha_termino: endDate,
        empresa: getIdEnterprise(this.props)
      }
    })
    
  }
  render(){
    const { dashboardStates } = this.props;

    return (
      <div>
        <InnerHeader title="Dashboard" additionalClass="with-tabs">
          <div className="header-actions-holder">
            <label className="relative-position inline-element m-r-10">
              <i className="icon-element calendar inline-left inner-field-icon"></i>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selected={dashboardStates.get('startDate')}
                selectsStart
                startDate={dashboardStates.get('startDate')}
                endDate={dashboardStates.get('endDate')}
                onChange={this.handleChangeStart}
                locale="fr"
              />
            </label>
            <label className="relative-position inline-element">
              <i className="icon-element calendar inline-left inner-field-icon"></i>
              <DatePicker
                dateFormat="DD/MM/YYYY"
                selected={dashboardStates.get('endDate')}
                selectsEnd
                startDate={dashboardStates.get('startDate')}
                endDate={dashboardStates.get('endDate')}
                onChange={this.handleChangeEnd}
              />
            </label>
            <button className="btn btn-small btn-secondary m-l-10" onClick={ this.filterHandler }>Filtrar</button>
          </div>
        </InnerHeader>
        <div className="inner-content animated fadeIn">
          <Tabs 
            activeTab={dashboardStates.get('activeTab')} 
            changeDashboardTab={this.props.changeDashboardTab}
            tabs = {[
              {
                title: 'Campañas',
                content: () => {
                  return <DasboardCampaigns {...this.props} />
                }
              },
              // comentado para consorcio
              // {
              //   title: 'Clientes',
              //   content: () => {
              //     return <DashboardClients {...this.props} />
              //   }
              // },
              // hasta aqui
              {
                title: 'Asistente',
                content: () => {
                  return <DashboardAssistant { ...this.props } />
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }
}