import React, { Component } from 'react';

import ChartHolder from '../charts/chart-holder';

export default class CampaignEmailTemplate extends Component{
  constructor(props){
    super(props);

    this.iframeEmail = React.createRef();
  }
  componentDidMount(){
    const { currentCampaign, entities } = this.props,
          emailId = currentCampaign.get('plantilla_email'),
          emailTemplate =  entities.get('emailTemplates').filter((data) => {
            return data.get('id') === emailId
          }),
          content = emailTemplate.get('0') ? emailTemplate.get('0').get('file_content') : '',
          iframe = this.iframeEmail.current;

    if(!iframe){ return false; }
    
    const document = iframe.contentDocument;
    const newContent = content ? content.replace('email_markup', currentCampaign.get('email_markup')).replace(/Â/g, '') : content;
    
    document.body.innerHTML = newContent;
  }
  getIframeData(){
    const { currentCampaign, entities } = this.props,
          emailTemplate = entities.get('emailTemplates').filter((data) => {
            return data.get('id') === currentCampaign.get('plantilla_email');
          });

    if(emailTemplate.get(0)){
      return <iframe title={ 'email-template' } className="box no-shadow campaign-email-iframe" ref={ this.iframeEmail }></iframe>
    }else{
      return null;
    }
  }
  render(){
    const iframeEmail = this.getIframeData();

    return(
      <ChartHolder
        title={ 'Plantilla de email' }
      >
        { iframeEmail }
      </ChartHolder>
    );
  }
}