import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Calendar from '../modules/calendar';
import { getConcurrency } from '../../utilities/utilities';

export default class CalendarCampaign extends Component{
  getMonthData(){

    const { months } = this.props;

    let monthData = [];

    months.forEach((month) => {
      const startDay = moment(month).clone().startOf('month').startOf('week'),
            endDay = moment(month).clone().endOf('month').endOf('week');

      let calendar = [];
      const index = startDay.clone();
      
      while (index.isBefore(endDay, 'day')) {
          calendar.push(
              new Array(7).fill(0).map(
                (n, i) => {
                  const parsedDate = index.add(1, 'day').date();
                  return {
                    date: parsedDate,
                    dayName: moment(index).format('d'),
                    formatedDate: moment(index).format('YYYY-MM-DD'),
                    monthNumber: moment(index).format('MM'),
                    dateMoment: moment(index)
                  };
                }
              )
          );
      }

      monthData.push({
        month: moment(month).format('MMM'),
        year: moment(month).format('YYYY'),
        monthNumber: moment(month).format('MM'),
        calendar
      });
    });

    return monthData;
  }
  getCalendar(monthData){
    const { startDate, endDate, days, concurrency, campaignType, exceptionDays, exceptionDate, dateHandler } = this.props;

    return monthData.map((data) => {
      return (
        <div className="calendar-holder" key={ 'month-'+ data.month +' '+ data.year }>
          <div className="calendar-title-holder">
            <span className="calendar-title">{ data.month +' '+ data.year }</span>
          </div>
          <div className="calendar-table-holder">
            <Calendar 
              weeksData={data.calendar}
              monthNumber={ data.monthNumber }
              startDate={ startDate }
              endDate={ endDate }
              exceptionDays={ days }
              concurrency={ getConcurrency(concurrency)  }
              campaignType={ campaignType }
              exceptionDate={ exceptionDate }
              dateHandler={ dateHandler }
            />
          </div>
        </div>
      )
    })
  }
  render(){
    const monthData = this.getMonthData(),
          calendar = this.getCalendar(monthData);

    return (
      <div>
        { calendar }
      </div>
    );
  }
}

CalendarCampaign.propTypes = {
  months: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  days: PropTypes.array,
  concurrency: PropTypes.any,
  exceptionDate: PropTypes.any,
  dateHandler: PropTypes.func
}