import React, { Component } from 'react';
import { getIdEnterprise } from '../../utilities/utilities';

import Header from './header';
import Body from './body';
import Form from './form';

export default class Assistant extends Component{
  constructor(props){
    super(props);
  }
  componentWillMount(){
    const { assistantSettingsStates, currentUser } = this.props;
    this.props.getAssistantInteractions({
      contextID: 'TESTS',
      tipo: assistantSettingsStates.get('filteredChannel'),
      empresa: getIdEnterprise(this.props)
    })
  }
  render(){
    const { assistantSettingsStates } = this.props,
          channelClass = assistantSettingsStates.get('filteredChannel')

    return (
      <div className="relative-position">
        <div className={`assistant-holder ${ channelClass }`}>
          <Header { ...this.props }/>
          <Body { ...this.props } />
          <Form { ...this.props } />
        </div>
      </div>
    );
  }
}