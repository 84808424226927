import * as Immutable from 'immutable';

export function clientsStates(state = Immutable.fromJS({
  isFetching: false,
}), action){
  switch(action.type){
    case 'GET_CLIENTS_START':
      return state.set('isFetching', true);
    case 'GET_CLIENTS_END':
      return state.set('isFetching', false);
    default:
      return state;
  }
}