import moment from "moment";

//POLYFILL
Array.prototype.clean = function(deleteValue) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == deleteValue) {
      this.splice(i, 1);
      i--;
    }
  }
  return this;
};

(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty("remove")) {
      return;
    }
    Object.defineProperty(item, "remove", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

if (!Element.prototype.matches)
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    value: function(searchElement, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" es null o no está definido');
      }

      // 1. Dejar que O sea ? ToObject(this value).
      var o = Object(this);

      // 2. Dejar que len sea ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. Si len es 0, devuelve false.
      if (len === 0) {
        return false;
      }

      // 4. Dejar que n sea ? ToInteger(fromIndex).
      //    (Si fromIndex no está definido, este paso produce el valor 0.)
      var n = fromIndex | 0;

      // 5. Si n ≥ 0, entonces
      //  a. Dejar que k sea n.
      // 6. Else n < 0,
      //  a. Dejar que k sea len + n.
      //  b. Si k < 0, Dejar que k sea 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return (
          x === y ||
          (typeof x === "number" &&
            typeof y === "number" &&
            isNaN(x) &&
            isNaN(y))
        );
      }

      // 7. Repite, mientras k < len
      while (k < len) {
        // a. Dejar que elementK sea el resultado de ? Get(O, ! ToString(k)).
        // b. Si SameValueZero(searchElement, elementK) es true, devuelve true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Incrementa k por 1.
        k++;
      }

      // 8. Devuelve false
      return false;
    }
  });
}
//END POLYFILL

export function getIndexBy(id, entity) {
  return entity.findIndex(i => i.get("id") === id);
}

export function getTemplatesIndexBy(id, entity) {
  return entity.findIndex(i => i.get("id_plantilla") === id);
}

export function getIndex(value, collection) {
  return collection.findIndex(i => {
    return value == i;
  });
}

export function getTemplateElementById(id, entities) {
  return entities.filter(data => {
    return data.get("id_plantilla") == id;
  });
}

export function getEntitieElementById(id, entities) {
  return entities.filter(data => {
    return data.get("id") == id;
  });
}

export function getCampaignStatus(status) {
  switch (status) {
    // case "en curso":
    //   return "success";
    // case "no iniciada":
    //   return "inactive";
    // case "finalizada":
    //   return "finished";
    // case "cancelada":
    //   return "error";
    // default:
    //   return status;
  }
}

export function getMonths() {
  return [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ];
}

export function getCalendarData(start, end) {
  const months = getMonths(),
    startDate = moment(start),
    endDate = moment(end);

  let monthsData = [];

  while (endDate > startDate || startDate.format("M") === endDate.format("M")) {
    monthsData.push(startDate.format("YYYY-MM"));
    startDate.add(1, "month");
  }

  return monthsData;
}

export function getConcurrency(concurrency) {
  switch (concurrency) {
    case "Una sola vez":
      return 1;
    case "Cada dos días":
      return 2;
    case "Cada tres días":
      return 3;
    case "Cada cuatro días":
      return 4;
    case "Cada cinco días":
      return 5;
    default:
      return false;
  }
}

export function getMonthData(startDate, endDate) {
  const months = getCalendarData(startDate, endDate);

  let monthData = [];

  months.forEach(month => {
    const startDay = moment(month)
        .clone()
        .startOf("month")
        .startOf("week"),
      endDay = moment(month)
        .clone()
        .endOf("month")
        .endOf("week");

    let calendar = [];
    const index = startDay.clone();
    while (index.isBefore(endDay, "day")) {
      calendar.push(
        new Array(7).fill(0).map((n, i) => {
          const parsedDate = index.add(1, "day").date();
          return {
            date: parsedDate,
            dayName: moment(index).format("d"),
            formatedDate: moment(index).format("YYYY-MM-DD"),
            dateMoment: moment(index)
          };
        })
      );
    }

    monthData.push({
      month: moment(month).format("MMM"),
      year: moment(month).format("YYYY"),
      calendar
    });
  });

  return monthData;
}

export function getSendDates(campaignForm) {
  const startDate = campaignForm
      .get("startDate")
      .startOf("day")
      .format("YYYY/MM/DD"),
    endDate = campaignForm
      .get("endDate")
      .startOf("day")
      .format("YYYY/MM/DD"),
    concurrency = getConcurrency(campaignForm.get("concurrency")),
    monthsData = getMonthData(startDate, endDate),
    exceptionDays = campaignForm.get("dias_envio").toJS(),
    exceptionDate = campaignForm.get("exceptionDays"),
    // weeksData = monthsData.calendar,
    today = moment();
    console.log(today)

  let cont = 1,
    sendDates = [];

  monthsData.forEach(data => {
    const weeksData = data.calendar;
    weeksData.forEach((week, index) => {
      week.forEach(w => {
        const diff = moment(w.formatedDate).diff(today.startOf("day"), "days"),
          startDateDiff = moment(startDate).startOf("day"),
          endDateDiff = moment(endDate).startOf("day"),
          dayName =
            parseInt(w.dayName, 10) - 1 >= 0 ? parseInt(w.dayName, 10) - 1 : 6,
          isSeted = exceptionDays
            ? exceptionDays.includes((dayName + 1).toString())
            : true,
          isNotDeleted = exceptionDate
            ? !exceptionDate.includes(w.formatedDate)
            : true,
          isBetween = moment(w.formatedDate).isBetween(
            startDateDiff,
            endDateDiff,
            "days",
            "[]"
          ),
          dayClass = diff == 0 ? "today" : "",
          pastDayClass = diff < 0 && isSeted && isNotDeleted ? "past-day" : "";

        let activeCampaignClass = "",
          isActiveItem = false;

        if (isBetween && isSeted && isNotDeleted) isActiveItem = true;

        if (isBetween && concurrency) {
          if (cont !== 1) {
            if (cont % concurrency != 1) isActiveItem = false;
          }
          cont++;
        }

        if (isActiveItem) {
          sendDates.push(w.formatedDate);
        }
      });
    });
  });

  return sendDates;
}

export function cleanResponse(response) {
  //Formato de respuesta: Texto ... ¿pregunta? %%%boton1%%% %%%boton2%%%
  let myRegexp = /^.*?\[\[\[(.*?)\]\]\]\(\(\((.*?)\)\)\).*?$/g,
    results = myRegexp.exec(response),
    url = results ? results[2] : null,
    urlText = results ? results[1] : null,
    btnSeparator = "$/^";

  let buttonGroup = [];

  if (url) {
    response = response.replace("(((", "");
    response = response.replace(")))", "");
    response = response.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");
    response = response.replace(
      urlText,
      '<a href="' + url + '" target="_blank">' + urlText + "</a>"
    );
    response = response.replace("[[[", "");
    response = response.replace("]]]", "");
  }

  const splitedResponse = response.split(btnSeparator),
    answerGroup = splitedResponse[splitedResponse.length - 1],
    answerGroupSplit = answerGroup.split("%%%").clean("");

  if (answerGroupSplit.length > 1)
    buttonGroup = answerGroupSplit[answerGroupSplit.length - 1].split("/");

  splitedResponse[splitedResponse.length - 1] = answerGroupSplit[0];

  response = splitedResponse;

  return {
    response,
    buttonGroup
  };
}

export function getSimbology(whatsapp, voz) {
  const data = [
    {
      name: "SMS",
      color: "#FF53A2"
    },
    {
      name: "Email",
      color: "#37C3F7"
    }
  ];
  if (whatsapp) {
    data.push({
      name: "Whatsapp",
      color: "#2BB741"
    });
  }
  if (voz) {
    data.push({
      name: "Voz",
      color: "#e74c3c",
    });
  }

  return data;
}

export function getBarChartSetup(features) {
  const data = ["email", "sms"];
  if (features.get("enableWhatsapp")) data.push("whatsapp");
  if (features.get("enableVoice")) data.push("voz");
  return data;
}

export function getLineChartSetup(features) {
  const data = [
    {
      key: "email",
      color: "#37C3F7"
    },
    {
      key: "sms",
      color: "#FF53A2"
    }
  ];

  if (features.get("enableWhatsapp"))
    data.push({
      key: "whatsapp",
      color: "#2BB741"
    });

  if (features.get("enableVoice"))
    data.push({
      key: "voz",
      color: "#e74c3c",
    });

  return data;
}

//TOKEN
export function isValidToken(date){
  if(!date){ return true };
  const dateLastToken = moment(date,"YYYY-M-D H:m:s"),
    diff = moment().diff(dateLastToken, "seconds");
  return diff <= 600;
}

export function removeSessionStorage(){
  sessionStorage.removeItem('loggIn');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('timer');
}

//VERIFICA SI EL USUARIO TIENE PERMISOS "ADMIN"
export function getIdEnterprise(props){
  const permissions = props.currentUser.getIn(['userData','viewsPermissions']);
  if(permissions.includes('admin')){
    return props.adminEnterprisesStates.getIn(['selectedEnterprise','id']);
  }
  return props.currentUser.getIn(['userData','id_empresa']);
}

export function dataURIToBlob(dataURI) {
  if (!dataURI) {
    return "#";
  }

   const binStr = atob(dataURI.split(",")[1]);

   let len = binStr.length,
    arr = new Uint8Array(len);

   const mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

   for (var i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

   const blob = new Blob([arr], {
    type: mimeString
  });

   return URL.createObjectURL(blob);
}
