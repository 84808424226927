import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Table extends Component{
  getColumns(){
    const {columns} = this.props;

    return columns.map((column, index) => {
      return (
        <th key={'column-'+index+'-'+column.title}>{ column.title }</th>
      );
    });

  }
  getContent(){
    const { columns, data, rowFunc } = this.props;
    
    return data.map((item, index) => {
      const row = columns.map((col, i) => {
        return <td key={ 'td-' + i }>{ col.func(item) }</td>
      })
      return <tr onClick={ rowFunc ? rowFunc.bind(null, item) : undefined } key={ 'tr-' + index }>{ row }</tr>;
    });

  }
  render(){
    const columns = this.getColumns(),
          content = this.getContent();

    return(
      <table className={ this.props.tableClass }>
        <thead>
          <tr key={ 'main-tr-header' }>
            { columns }
          </tr>
        </thead>
        <tbody>
          { content }
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.array,
  tableClass: PropTypes.string,
  data: PropTypes.object,
  rowFunc: PropTypes.func
}

Table.defaultProps = {
  tableClass: ''
}