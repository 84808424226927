import * as Immutable from 'immutable';

export function generalStates(state = Immutable.fromJS({
  modal: false,
  isFetchingForm: false,
  isFetchingAuth: true,
  formError: '',
  confirmModalContent: {
    text: '',
    func: ''
  },
  disclaimer: {
    show: false,
    status: '',
    description: ''
  }
}), action){
  switch(action.type){
    case 'SET_MODAL':
      return state.withMutations((map) => {
        map.set('modal', action.modalSlug)
        .set('isFetchingForm', false)
        .set('formError', '')
      });
    case 'CONFIRM_DATA':
      return state.withMutations((map) => {
        map.set('modal', 'confirmData')
          .setIn(['confirmModalContent', 'text'], action.text)
          .setIn(['confirmModalContent', 'func'], action.func)
      });
    case 'SEND_RECOVER_PASS_END':
      return state.set('modal', false);
    case 'SEND_RECOVER_PASS_ERROR':
      return state.set('modal', false);
    case 'EDIT_TEMPLATE':
      return state.set('modal', 'templateForm')
    case 'EDIT_CAMPAIGN':
      return state.set('modal', 'campaignForm')
    case 'EDIT_USER':
      return state.set('modal', 'userForm');
    case 'EDIT_EMAIL_TEMPLATE':
      return state.set('modal', 'emailPrevisualization')
    case 'DELETE_USER_START':
      return state.set('isFetchingForm', true);
    case 'DELETE_USER_END':
      return state.set('isFetchingForm', false);
    case 'DELETE_TEMPLATE_START':
      return state.set('isFetchingForm', true);
    case 'DELETE_TEMPLATE_END':
      return state.set('isFetchingForm', false);
    case 'SESSION_AUTH_START':
      return state.set('isFetchingAuth', true);
    case 'SESSION_AUTH_END':
      return state.set('isFetchingAuth', false);
    case 'LOGOUT':
      return state.set('isFetchingAuth', false);
    case 'FETCH_LOGIN_END':
      return state.set('isFetchingAuth', false);
    case 'DELETE_TEMPLATE_ERROR':
      return state.withMutations((map) => {
        map.set('formError', action.error)
          .set('isFetchingForm', false);
      });
    case 'CANCEL_CAMPAIGN_START':
      return state.set('isFetchingForm', true);
    case 'CANCEL_CAMPAIGN_END':
      return state.set('isFetchingForm', false);
    case 'CANCEL_CAMPAIGN_ERROR':
      return state.withMutations((map) => {
        map.set('formError', action.error)
            .set('isFetchingForm', false);
        });
    case 'DELETE_EMAIL_TEMPLATE_START':
      return state.set('isFetchingForm', true)
    case 'DELETE_EMAIL_TEMPLATE_START':
      return state.set('isFetchingForm', false)
    default:
      return state;
  }
}