import * as Immutable from 'immutable';

export function templatesStates(state = Immutable.fromJS({
  isFetching: true,
  isFetchingCategories: false,
  isFetchingCustomFields: false,
  isFetchingForm: false,
  isEditing: false,
  formError:'',
  filters: {
    search: ''
  },
  error: '',
  errors: {
    customFields: '',
    categories: ''
  },
  selectedElements: {
    selectedCategories: [],
    selectedCustomFields: []
  },
  templateForm: {
    title: '',
    description: '',
    repaired: false,
    categorySearch: '',
    customFieldsSearch: '',
    categoryAdd: '',
    customFieldAdd: ''
  } 
}), action){
  switch(action.type){
    case 'GET_TEMPLATES':
      return state.set('isFetching', true);
    case 'GET_TEMPLATE_END':
      return state.set('isFetching', false)
                  .set('error', "");
    case 'CHANGE_TEMPLATE_FIELD': 
      return state.setIn(['templateForm', action.target ], action.value)
    case 'ADD_TEMPLATE_CATEGORY_START':
      return state.set('isFetchingCategories', true)
    case 'ADD_TEMPLATE_CATEGORY_END':
      return state.withMutations((map) => {
        map.updateIn(['selectedElements', 'selectedCategories'], data => data.push(action.data.id_campo))
          .set('isFetchingCategories', false)
          .set('errors', Immutable.fromJS({
            customFields: '',
            categories: ''
          }))
      });
    case 'ADD_TEMPLATE_CATEGORY_ERROR':
      return state.withMutations((map) => {
        map.setIn(['errors', 'categories'], action.error)
          .set('isFetchingCategories', false)
      }); 
    case 'SELECT_TEMPLATE_CATEGORY':
      return state.updateIn(['selectedElements', 'selectedCategories'], data => data.push(action.id));
    case 'REMOVE_TEMPLATE_CATEGORY':
      return state.updateIn(['selectedElements', 'selectedCategories'], data => data.filter((d) => d != action.id))
    case 'SELECT_CUSTOM_FIELD':
      return state.updateIn(['selectedElements', 'selectedCustomFields'], data => data.push(action.id)); 
    case 'REMOVE_CUSTOM_FIELD':
      return state.updateIn(['selectedElements', 'selectedCustomFields'], data => data.filter((d) => d != action.id)); 
    case 'SEARCH_LIST_FILTER':
      return state.withMutaiton((map) => {
        map.setIn(['templateForm', action.target], action.value)
      });
    case 'ADD_TEMPLATE_CUSTOM_FIELD_START':
      return state.withMutations((map) => {
        map.setIn(['errors', 'customFields'], '')
        .set('isFetchingCustomFields', true)
      });
    case 'ADD_TEMPLATE_CUSTOM_FIELD_END':
      return state.withMutations((map) => {
        map.updateIn(['selectedElements', 'selectedCustomFields'], data => data.push(action.data.id_campo))
          .set('isFetchingCustomFields', false)
          .setIn(['templateForm', 'customFieldAdd'], '')
      });
    case 'ADD_TEMPLATE_CUSTOM_FIELD_ERROR':
      return state.withMutations((map) => {
        map.setIn(['errors', 'customFields'], action.error)
          .set('isFetchingCustomFields', false)
      }); 
    case 'SET_MODAL':
      return state.withMutations((map) => {
        if(!action.modalSlug){
          map.set('templateForm', Immutable.fromJS({
            title: '',
            description: '',
            repaired: false,
            categorySearch: '',
            customFieldsSearch: '',
            categoryAdd: '',
            customFieldAdd: '',
            category: [],
            customFields: []
          }))
          .set('selectedElements', Immutable.fromJS({
            selectedCategories: [],
            selectedCustomFields: []
          }))
          .set('errors', Immutable.fromJS({
            customFields: '',
            categories: ''
          }))
          .set('isEditing', false)
          .set('isFetchingForm', false)
          .set('formError', '')
        }else{
          return state;
        }
      })
    case 'CREATE_TEMPLATE_START':
      return state.set('isFetchingForm', true);
    case 'CREATE_TEMPLATE_END':
      return state.set('isFetchingForm', false);
    case 'CREATE_TEMPLATE_ERROR':
      return state.withMutations((map) => {
        map.set('formError', action.error)
          .set('isFetchingForm', false);
      })
    case 'EDIT_TEMPLATE_START':
      return state.set('isFetching', true);
    case 'EDIT_TEMPLATE_END':
      return state.set('isFetching', false);
    case 'DELETE_TEMPLATE_START':
      return state.set('isFetchingForm', true);
    case 'DELETE_TEMPLATE_END':
      return state.set('isFetching', false);
    case 'EDIT_TEMPLATE': 
      return state.withMutations((map) => {
        map.set('templateForm', action.data)
          .set('isEditing', action.data.get('id'))
          .setIn(['selectedElements', 'selectedCategories'], action.categories)
          .setIn(['selectedElements', 'selectedCustomFields'], action.customFields)
      })
    case 'FILTER_TEMPLATE_BY_STRING':
      return state.setIn(['filters', 'search'], action.value);
    case 'GET_TEMPLATES_ERROR':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('error', action.error);
      })
    default:
      return state;
  }
}