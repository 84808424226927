import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class IsFetching extends Component{
  render(){
    let content;

    if(this.props.isFetching){
      content = (
        <div className="assistant-spinner-holder">
          <div className="assistant-spinner" style={ { borderLeftColor: this.props.loaderColor } }></div>
        </div>
      );
    }else{
      content = this.props.children;
    }

    return content;
  }
}

IsFetching.propTypes = {
  loaderColor: PropTypes.string,
  isFetching: PropTypes.bool
}