import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Bubble extends Component{
  render(){
    const avatar = this.props.origin == 'from' ? <img className="assistant-response-image rounded-img elastic-img" src={ this.props.avatar } /> : null;

    return (
      <div className={'assistant-conversation-bubble '+ this.props.origin +' animated fadeInUp'}>
        { avatar }
        <p className="assistant-bubble" style={ { backgroundColor: this.props.backgroundColor, color: this.props.color } } dangerouslySetInnerHTML={{__html: this.props.content}} />
      </div>
    );
  }
}

Bubble.propTypes = {
  origin: PropTypes.string,
  avatar: PropTypes.string,
  content: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string
}

Bubble.defaultProps = {
  origin: 'from',
  backgroundColor: '#e7e7e7',
  color: '#6b6a6f'
}