import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../modules/dropdown';
import UserDropdownNavigation from './user-dropdown-navigation';
import EnterpriseDropdownNavigation from './enterprise-dropdown-navigation';
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIATNYYSUBYH2XNDQMK",
  secretAccessKey: "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",
  region: "us-east-2",
});

export default class SecondaryNavigation extends Component{
  constructor(props){
    super(props);
    this.logout = this.logout.bind(this);
    this.state = {
      logoUrl: null,
    };
  }
  
  componentDidMount() {
    this.fetchLogo();
  }

  fetchLogo() {
    const { adminEnterprisesStates } = this.props;
    const enterpriseId = adminEnterprisesStates.getIn(['selectedEnterprise', 'id']);
    const s3 = new AWS.S3();
    const bucketName = 'imagenes-correos-gestorcampana-bucket';
    const fileName = `logos/${enterpriseId}.png`;
    const params = {
      Bucket: bucketName,
      Key: fileName,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        if (err.code === 'NoSuchKey') {
          // console.log('El logo no existe en el bucket de S3.');
        } else {
          // console.error('Error al obtener el logo:', err);
        }
      } else {
        const logoUrl = URL.createObjectURL(new Blob([data.Body], { type: 'image/png' }));
        this.setState({ logoUrl });
      }
    });
  }

  logout(event){
    event.preventDefault();
    const userData = JSON.parse(sessionStorage.getItem('loggIn'))
    this.props.logoutSessionAuth(userData);
  }
  fillEnterpriseSelect = () =>{
    const  { featuresStates, } = this.props,
    features = featuresStates.get('features');
    if(!features.get('admin')) return null;

    return(
    //   <Dropdown
    //   handlerClass="btn btn-small btn-user-navigation"
    //   icon={ 'icon-element inline-left arrow-down' }
    //   text={"Empresas"}
    // >
      <EnterpriseDropdownNavigation {...this.props}/>
    // </Dropdown>
    )
  }


  render(){
    const { adminEnterprisesStates } = this.props;
    const enterpriseId = adminEnterprisesStates.getIn(["selectedEnterprise", "id"]);
   
    const { logoUrl } = this.state;
    const showLogo = enterpriseId === 154;
  
    return (
      <div className="user-navigation">
        <span>{this.props.userName}</span>
        {this.fillEnterpriseSelect()}
        <Dropdown
          handlerClass="btn btn-small btn-user-navigation"
          icon={ 'icon-element inline-left settings' }
        >
          <UserDropdownNavigation { ...this.props }/>
        </Dropdown>
      
        {logoUrl && (
        <img className="logo-icon" src={logoUrl} alt="Logo" />
      ) }
         
        <button className="btn btn-user-logout" onClick={ this.logout }></button>
      </div>
    );
  }
}

SecondaryNavigation.propTypes = {
  userName: PropTypes.string
}
