import React, { Component } from 'react';
import { getIdEnterprise } from '../../utilities/utilities';

import { cleanResponse, getCalendarData } from '../../utilities/utilities';

import IsFetching from './is-fetching';
import Bubble from './bubble';
import CalendarAssistant from './calendar-assistant';

export default class Body extends Component{
  constructor(props){
    super(props);

    this.buttonHandler = this.buttonHandler.bind(this);
  }
  componentDidUpdate(){
    this.holder.scrollTop = this.holder.scrollHeight - this.holder.clientHeight;
  }
  buttonHandler(message, index, i, event){
    const { assistantStates, assistantSettingsStates, currentUser } = this.props;

    this.props.sendAssistantMessage({
      cid: assistantStates.get('cid'),
      input: message,
      contextID: 'TESTS',
      tipo: assistantSettingsStates.get('filteredChannel'),
      empresa: getIdEnterprise(this.props)
    }, index, i);
  }
  getCalendar(){
    const { assistantStates, assistantSettingsStates, currentUser } = this.props,
          calendarDates = assistantStates.get('calendarDates');

    return (
      <CalendarAssistant 
        key={'calendar-holder'} 
        days={ calendarDates.toJS() } 
        func={(date)=>{
          this.props.sendAssistantMessage({
            cid: assistantStates.get('cid'),
            input: date,
            contextID: 'TESTS',
            tipo: assistantSettingsStates.get('filteredChannel'),
            empresa: getIdEnterprise(this.props)
          }, -1, -1);
        }}
        { ...this.props }
      />
    );
  }
  getContent(){
    const { assistantSettingsStates, assistantStates } = this.props,
          conversation = assistantStates.get('conversation'),
          error = assistantStates.get('errorFetching');
      if(error!==""){
        return (
          <div className="error-assistant">{error}</div>
        )
      }

    let conversationData = [];

    conversation.forEach((item, index) => {
      if(item.get('input').get('text')){
        conversationData.push(
          <Bubble 
            key={ 'input-' + index }
            avatar={ assistantSettingsStates.get('assistantFileUrl') } 
            origin={ 'to' }
            content={ item.get('input').get('text') } 
            backgroundColor={ assistantSettingsStates.get('bubbleBackgroundColor') }
            color={ assistantSettingsStates.get('bubbleColor')  }
          />
        );
      }

      if(item.get('output')){
        const showButtons = item.get('showButton');
      
        item.get('output').forEach((output, i) => {
          if(output){
            const outputCleaned = cleanResponse(output);

            conversationData.push(
              <Bubble 
                key={ 'output-'+ index +'-'+ i  }
                avatar={ assistantSettingsStates.get('assistantFileUrl') } 
                content={ outputCleaned.response[0] } 
              />
            );

            if(showButtons){
              let buttons = [];
              outputCleaned.buttonGroup.forEach((button, bi) => {
                if(button != 'END SESSION'){
                  buttons.push(
                    <button key={ 'button-'+ index +'-'+ i +'-'+ bi } onClick={ this.buttonHandler.bind(null, button, index, i) } className="assistant-btn" style={ {backgroundColor: assistantSettingsStates.get('buttonsColor') }}>{ button }</button>
                  );
                }
              })

              conversationData.push(
                <div key={ 'button-holder'+ index +'-'+ i } className="assistant-conversation-bubble align-center from animated fadeInUp">
                  { buttons }
                </div>
              )
            }
          }
        });
      }
    });

    if(assistantStates.get('calendarDates') && assistantStates.get('calendarDates').size){
      conversationData.push(this.getCalendar())
    }

    return conversationData;
  }
  render(){
    const { assistantSettingsStates, assistantStates } = this.props,
          content = this.getContent();
          
    return (
      <section className="assistant-conversation-holder" ref={holder => (this.holder = holder)}>
        <IsFetching isFetching={ assistantStates.get('isFetching') } loaderColor={ assistantSettingsStates.get('buttonsColor') }>
          { content }
        </IsFetching>
      </section>
    )
  }
}