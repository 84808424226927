import React, { Component } from 'react';

import SingleCampaignSendData from './single-campaign-send-data';

import IsFetching from '../../modules/is-fetching';
import CampaignBoxUserResume from '../campaign-box-user-resume';
import Kpi from '../../modules/kpi';
import { getIdEnterprise } from '../../../utilities/utilities';

export default class SingleCampaignAsideData extends Component{
  constructor(props){
    super(props);

    this.downloadCSVClients = this.downloadCSVClients.bind(this);
    
  }
  downloadCSVClients(){
    const { currentCampaign, currentUser } = this.props;

    // this.props.downloadCampaignClientsCSV(currentUser.get('userData').get('username'), currentCampaign.get('id_campana'), currentCampaign.get('numero_campana'), getIdEnterprise(this.props))
    this.props.downloadCampaignClientsCSV(currentUser.get('userData').get('username'), currentCampaign.get('id_campana'), getIdEnterprise(this.props), currentCampaign.get('numero_campana'))
  }
  render(){
    const { currentCampaign, campaignStates } = this.props;

    return(
      <div className="grid-3">
        {/* <SingleCampaignSendData { ...this.props } /> */}
        <div className="box inner">
          <CampaignBoxUserResume campaign={ currentCampaign } {...this.props} />
          <div className="align-center">
            <Kpi keyData={ 'Contactados' } valueData={ currentCampaign.get('contactados') } />
            <Kpi keyData={ 'No Contactados' } valueData={ currentCampaign.get('no_contactados') } />
          </div>
          <div className="campaign-box-wrapper  align-center relative-position">
            <IsFetching isFetching={ campaignStates.get('isFetchingClientCampaignCSV') } showChildren={ true }>
              <button className="btn btn-secondary" onClick={ this.downloadCSVClients }>Descargar CSV</button>
            </IsFetching>
          </div>
        
        </div>
        <SingleCampaignSendData { ...this.props } />
      </div>
    );
  }
}