import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IsFetching from '../modules/is-fetching.jsx';
import ChartHolder from '../charts/chart-holder';
import Tooltip from '../modules/tooltip';
import DashboardLineChart from '../charts/bar-chart';

export default class ResponseChart extends Component{
  componentWillMount(){
    this.props.dataFunc()
  }

  dashboardTitle(title) {
    switch(title){
      case 'Razón de no pago': return 'no_puede';
      case 'Compromisos de pago': return 'compromete';
      default: return '';
    }
  }
  
  getContent(){
    const { chartsData, dataForCSVCall } = this.props;

    return chartsData.map((data) => {
      return (
        <div className={ this.props.chartHoldersClass } key={ 'client-chart-response-'+ data.get('title') }>
          <ChartHolder
            title={ data.get('title') }
            chartHolderClass="chart-holder chart-vertical"
            csvType= {this.dashboardTitle(data.get('title'))}
            dataForCSVCall={dataForCSVCall}
          >
            <DashboardLineChart 
              data={ data.get('data') }
              setup={['data']} 
              yAxisLabel={ 'Clientes' }/>
          </ChartHolder>
        </div>
      )
    });
  }
  render(){
    const content = this.getContent();
    return(
      <IsFetching isFetching={ this.props.isFetching } >
        { content }
      </IsFetching>
    );
  }
}

ResponseChart.propTypes = {
  isFetching: PropTypes.bool,
  chartsData: PropTypes.object,
  chartHoldersClass: PropTypes.string,
  dataFunc: PropTypes.func,
  dataForCSVCall: PropTypes.object,
}