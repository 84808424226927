import * as Immutable from 'immutable';

export function entities(state = Immutable.fromJS({
  templates: [],
  campaigns: [],
  clients: [],
  customFields: [],
  templateCategories: [] ,
  users: [],
  emailTemplates: [],
  uniqueFields: [],
  enterprises: [],
  enterprisesIsFetching: false
}), action){
  switch(action.type){
    case 'GET_TEMPLATE_END':
      return state.withMutations((map) => {
        map.set('templates', Immutable.fromJS(action.data))
          .set('customFields', Immutable.fromJS(action.customFields))
          .set('templateCategories', Immutable.fromJS(action.categories))
          .set('uniqueFields', Immutable.fromJS(action.uniqueFields))
      });
    case 'ADD_TEMPLATE_CATEGORY_END':
      return state.update('templateCategories', data => data.push(Immutable.fromJS(action.data)))
    case 'ADD_TEMPLATE_CUSTOM_FIELD_END':
      return state.update('customFields', data => data.push(Immutable.fromJS(action.data)))
    case 'CREATE_TEMPLATE_END':
      return state.update('templates', data => data.push(Immutable.fromJS(action.data))); 
    case 'EDIT_TEMPLATE_END':
      const index = state.get('templates').findIndex(i => i.get('id') === action.data.id);
      return state.setIn(['templates', index.toString()], Immutable.fromJS(action.data))
    case 'EDIT_USER_DATA_END':
      const indexUser = state.get('users').findIndex(i => i.get('id') === action.data.id);
      return state.setIn(['users', indexUser.toString()], Immutable.fromJS(action.data))
    case 'DELETE_TEMPLATE_END':
      return state.deleteIn(['templates', action.index.toString()])
    case 'DELETE_USER_END':
      return state.deleteIn(['users', action.index.toString()])
    case 'GET_CAMPAIGNS_END':
      return state.setIn(['campaigns'], Immutable.fromJS(action.data));
    case 'GET_MORE_CAMPAIGNS_END':
      return state.mergeIn(['campaigns'], state.get('campaigns').concat(Immutable.fromJS(action.data))) 
    case 'CREATE_CAMPAIGN_END':
      return state.update('campaigns', data => data.push(Immutable.fromJS(action.data))); 
    case 'GET_USERS_END':
      return state.setIn(['users'], Immutable.fromJS(action.data));
    case 'GET_CLIENTS_END':
      return state.setIn(['clients'], Immutable.fromJS(action.data))
    case 'CANCEL_CAMPAIGN_END':
      return state.setIn(['campaigns', '0', 'estado_campana'], 'cancelada')
    case 'CREATE_USER_END':
      return state.update('users', data => data.push(Immutable.fromJS(action.data)));
    case 'SUBMIT_EMAIL_TEMPLATE_HTML_END':
      return state.update('emailTemplates', data => data.push(Immutable.fromJS(action.data)));
    case 'GET_EMAIL_TEMPLATES_END':
      return state.set('emailTemplates', Immutable.fromJS(action.data));
    case 'DELETE_EMAIL_TEMPLATE_END':
      return state.deleteIn(['emailTemplates', action.index.toString()])
    case 'SUBMIT_CAMPAIGN_UPLOAD_FILE_DATA':
      return state.mergeIn(['campaigns', '0'], Immutable.fromJS(action.data))
    case 'GET_ENTERPRISES_END':
      return state.withMutations(map=>{
        map.set('enterprises',Immutable.fromJS(action.data))
          .set('enterprisesIsFetching',false)
      })
    case 'GET_ENTERPRISES_START':
      return state.set('enterprisesIsFetching',true)
    default:
      return state;
  }
}