import React, { Component } from 'react';

import IsFetching from '../../modules/is-fetching';
import { getIdEnterprise } from '../../../utilities/utilities';

export default class SingleCampaignActionButtons extends Component{
  constructor(props){
    super(props);

    this.confirmCancel = this.confirmCancel.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
  }
  confirmCancel(){
    const { currentCampaign, currentUser } = this.props, 
          text = '¿Estás seguro que quieres cancelar la campaña '+ currentCampaign.get('numero_campana') +'?',
          func = this.props.cancelCampaign.bind(null, currentUser.get('userData').get('username'), currentCampaign.get('id_campana'));
    
    this.props.confirmData(text, func);
  }
  downloadCSV(){
    const { currentCampaign, currentUser } = this.props;

    this.props.downloadCampaignCSV(currentUser.get('userData').get('username'), currentCampaign.get('id_plantilla'), currentCampaign.get('id_campana'), currentCampaign.get('numero_campana'), getIdEnterprise(this.props))
  }
  render(){
    const { currentCampaign, campaignStates } = this.props;

    let cancelCampaignButton = null

    if(currentCampaign.get('estado_campana') === 'en curso' || currentCampaign.get('estado_campana') === 'no iniciada'){
      cancelCampaignButton = <button className="btn btn-small btn-error" onClick={ this.confirmCancel }>Cancelar campaña</button>
    }

    return (
      <div className="header-actions-holder">
        <div className="inline-element relative-position m-r-10">
          <IsFetching isFetching={ campaignStates.get('isFetchingCampaignCSV') } showChildren={ true }>
            <button className="btn btn-small btn-secondary" onClick={ this.downloadCSV }>Archivo de carga</button>
          </IsFetching>
        </div>
        { cancelCampaignButton }
      </div>
    );
  }
}