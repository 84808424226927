import React, { Component } from 'react';
import NotFoundData from '../modules/not-found-data';

export default class NotFound extends Component{
  render(){
    return(
      <div className="row inner-content clearfix">
        <div className="box inner m-t-30">
          <NotFoundData 
            text={ 'Esta página no existe o no tienes permisos para porder acceder' }
          />
        </div>
      </div>
    );
  }
}