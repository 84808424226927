import React, { Component } from 'react';

export default class Header extends Component{
  render(){
    const { assistantSettingsStates } = this.props;

    return (
      <header className="assistant-main-header" style={{ background: assistantSettingsStates.get('headerColor') }}>
        <div className="assistant-main-header-col left">
          <img className="rounded-img elastic-img" src={ assistantSettingsStates.get('headerFileUrl') } alt="logo" />
        </div>
        <div className="assistant-main-header-col right">
          <h1 className="assistant-title" style={ { color: assistantSettingsStates.get('titleColor')  } }>Asistente digital de cobranza</h1>
          <span className="assistant-subtitle" style={ { color: assistantSettingsStates.get('titleColor')  } }>{ assistantSettingsStates.get('assistantTitle') }</span>
        </div>
      </header>
    );
  }
}