import React, { Component } from 'react';
import { getIdEnterprise } from '../../utilities/utilities';

import UploadButton from '../modules/upload-button';
import ColorPicker from '../modules/color-picker';

export default class AssistantWrapperFilters extends Component{
  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeAssistantTitle = this.changeAssistantTitle.bind(this);
    this.submitFileHeader = this.submitFileHeader.bind(this);
    this.submitFileAvatar = this.submitFileAvatar.bind(this);
    this.inputHandler = this.inputHandler.bind(this)

  }
  inputHandler(target, event){
    this.props.setAssistantSettingsState(target, event.target.value);
  }
  submitFileHeader(event){
    const { assistantSettingsStates } = this.props,
          file = assistantSettingsStates.get('headerFile');

    if(/\.(jpe?g|png|gif)$/i.test(file.name)){
      const reader = new FileReader();

      reader.addEventListener("load", (event) => {
        this.props.changeAssistantHeaderFileUrl(event.target.result);
      }, false);

      reader.readAsDataURL(file);

    }
  }
  submitFileAvatar(event){
    const { assistantSettingsStates } = this.props,
          file = assistantSettingsStates.get('assistantFile');
    
    if(/\.(jpe?g|png|gif)$/i.test(file.name)){
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        this.props.changeAssistantAvatarFileUrl(event.target.result);
      }, false);

      reader.readAsDataURL(file);

    }
  }
  handleSubmit(event){
    event.preventDefault();
    const { assistantSettingsStates, currentUser } = this.props,
          args = {
            tag:"asistente-digital",
            tamano_letra:"15",
            font_type:"helvetica1",
            imagen_cabecera: assistantSettingsStates.get('headerFileUrl'),
            subtitulo: assistantSettingsStates.get('assistantTitle'),
            header_color_fondo: assistantSettingsStates.get('headerColor'),
            color_botones: assistantSettingsStates.get('buttonsColor'),
            avatar_url: assistantSettingsStates.get('assistantFileUrl'),
            color_titulos: assistantSettingsStates.get('titleColor'),
            user_audit: currentUser.get('userData').get('username'),
            color_titulo_encabezado: assistantSettingsStates.get('titleColor'),
            color_fondo_burbuja: assistantSettingsStates.get('bubbleBackgroundColor'),
            color_texto_burbuja: assistantSettingsStates.get('bubbleColor'),
            empresa: getIdEnterprise(this.props)
          }

    this.props.submitCustomization(args);
  }
  changeAssistantTitle(event){
    this.props.changeAssistantTitle(event.target.value);
  }
  render(){
    const { assistantSettingsStates } = this.props;
    
    return(
      <div className="grid-6">
        <div className="box clearfix margin-bottom-wrapp">
          <h3>Configuración:</h3>
          <form className="row" method="POST" action="#" noValidate onSubmit={ this.handleSubmit }>
            <label className="grid-12">
              <span className="label-like">Título del asistente</span>
              <input type="text" name="assistant-title" value={ assistantSettingsStates.get('assistantTitle') } onChange={ this.changeAssistantTitle } placeholder={'Ingrese un título para el asistente'} />
            </label>
            <div className="margin-bottom-wrapp">
              <div className="grid-4 margin-bottom-wrapp">
                <ColorPicker 
                  title={ 'Color del encabezado' }
                  color={ assistantSettingsStates.get('headerColor') }
                  changeColorFunc={ this.props.changeAssistantHeaderColor }
                />
              </div>
              <div className="grid-4 margin-bottom-wrapp">
                <ColorPicker 
                  title={ 'Color de los títulos' }
                  color={ assistantSettingsStates.get('titleColor') }
                  changeColorFunc={ this.props.changeAssistantTitleColor }
                />
              </div>
              <div className="grid-4 margin-bottom-wrapp">
                <ColorPicker 
                  title={ 'Color de los botones' }
                  color={ assistantSettingsStates.get('buttonsColor') }
                  changeColorFunc={ this.props.changeAssistantButtonColor }
                />
              </div>
              <div className="grid-4 margin-bottom-wrapp">
                <ColorPicker 
                  title={ 'Color de fondo de la burbuja' }
                  color={ assistantSettingsStates.get('bubbleBackgroundColor') }
                  changeColorFunc={ this.props.changeAssistantBubbleBackgroundColor }
                />
              </div>
              <div className="grid-4 margin-bottom-wrapp">
                <ColorPicker 
                  title={ 'Color del texto de la burbuja' }
                  color={ assistantSettingsStates.get('bubbleColor') }
                  changeColorFunc={ this.props.changeAssistantBubbleColor }
                />
              </div>
            </div>
            <div className="clearfix margin-bottom-wrapp">
              <div className="grid-12">
                <span className="label-like">Imagen del encabezado</span>
                <UploadButton 
                  selectCSVFile={ this.props.selectAssistantHeaderImage }
                  fileName={ assistantSettingsStates.get('headerFileName') }
                  acceptData={ 'image/x-png,image/gif,image/jpeg' }
                  submitFunc={ this.submitFileHeader }
                />
              </div>
              <div className="grid-12">
                <span className="label-like">Avatar del asistente</span>
                <UploadButton 
                  selectCSVFile={ this.props.selectAssistantImage }
                  fileName={ assistantSettingsStates.get('assistantFileName') }
                  acceptData={ 'image/x-png,image/gif,image/jpeg' }
                  submitFunc={ this.submitFileAvatar }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}