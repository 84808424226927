import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getBarChartSetup, getIdEnterprise } from '../../utilities/utilities';

import ResponseCharts from '../charts/response-charts'
import Tabs from '../modules/tabs';
import ChartHolder from '../charts/chart-holder';
import DashboardBarChart from '../charts/bar-chart';
import DashboardLineChart from '../charts/line-chart';
import IsFetching from '../modules/is-fetching';
import DashboardPieChart from '../charts/pie-chart';

export default class SingleClientsTabs extends Component{

  getPaidCustomersAssigned(id){
    const { featuresStates, currentUser, campaignStates } = this.props,
    paidCustomersAssigned = featuresStates.getIn(['features','campaignsFeatures','paidCustomersAssigned']);
    if(paidCustomersAssigned>0){
      return (
        <ChartHolder
          title={ 'Clientes asignados - pagados / Montos asignados - pagados' }
          getData={ this.props.getDashboardData.bind(null, {
            slug: 'paidCustomersAssigned',
            endpoint: 'campanas/DASHBOARD_CLIENTES_ASIGNADOS_PAGADOS',
            ord: 'ord',
            data: {
              usuario: currentUser.getIn(['userData','username']),
              empresa: getIdEnterprise(this.props),
              id_campana: id
            }
          }) }
          b64={ this.props.campaignStates.get('b64').get('paidCustomersAssigned') }
        >
          <IsFetching isFetching={ campaignStates.get('fetchingTabs').get('paidCustomersAssigned')} >
            <DashboardPieChart
              data={ campaignStates.get('tabs').get('paidCustomersAssigned')}
            />
          </IsFetching>
        </ChartHolder>
      )
    }
    return null;
  }


  getTabs(){
    const { currentUser, currentCampaign, campaignStates, featuresStates } = this.props,
    features = featuresStates.get('features').get('campaignsFeatures'),
    id = currentCampaign.get('id_campana'),
    userData = currentUser.get('userData');
    //SI ES TIPO CAMPAÑA === MORA TIENE TODO SINO SÓLO DEBE TENER
    let data = null;
    
    
    
    //COMENTADO PORQUE AÚN NO VA TIPO DE CAMPAÑA
    // if(campaignType==="preventiva"){
      data = [
        {
          title: 'Contactos',
          content: () => {
            return (
              <ChartHolder
                title={ 'Clientes contactados' }
                getData={ this.props.getDashboardData.bind(null, {
                  slug: 'clientsContacted',
                  endpoint: 'campanas/DASHBOARD_CONTACTADOS',
                  ord: 'ord',
                  data: {
                    usuario: currentUser.getIn(['userData','username']),
                    empresa: getIdEnterprise(this.props),
                    id_campana: id,
                    fecha_inicio: currentCampaign.get('fecha_inicio'),
                    fecha_termino: currentCampaign.get('fecha_termino'),
                    reagendamiento: false
                  }
                }) }
                b64={ this.props.campaignStates.get('b64').get('clientsContacted') }
              >
                <IsFetching isFetching={ campaignStates.get('fetchingTabs').get('clientsContacted')} >
                  <DashboardBarChart 
                    data={ campaignStates.get('tabs').get('clientsContacted')} 
                    yAxisLabel={ 'Clientes' }
                    setup={ getBarChartSetup(features) }
                  />
                </IsFetching>
              </ChartHolder>
            )
          }
        },
        {
          title: 'Tasa de recupero',
          content: () => {
            return (
              <React.Fragment>
                {this.getPaidCustomersAssigned(id)}
                <ChartHolder
                  title={ 'Clientes / Tipo de apertura' }
                  getData={ this.props.getDashboardData.bind(null, {
                    slug: 'recoverData',
                    endpoint: 'campanas/DASHBOARD_RECUPERO',
                    ord: 'ord',
                    data: {
                      usuario: currentUser.getIn(['userData','username']),
                      empresa: getIdEnterprise(this.props),
                      id_campana: id,
                      fecha_inicio: currentCampaign.get('fecha_inicio'),
                      fecha_termino: currentCampaign.get('fecha_termino')
                    }
                  }) }
                  b64={ this.props.campaignStates.get('b64').get('recoverData') }
                >
                  <IsFetching isFetching={ campaignStates.get('fetchingTabs').get('recoverData')}>
                    <DashboardBarChart 
                      data={campaignStates.get('tabs').get('recoverData')} 
                      yAxisLabel={ 'Clientes' }
                      setup={["data"]}
                    />
                  </IsFetching>
                </ChartHolder>
              </React.Fragment>
            )
          }
        },
        {
          title: 'Reagendamiento',
          content: () => {
            return (
              <ChartHolder
                title={ 'Pagos reagendados' }
                getData={ this.props.getDashboardData.bind(null, {
                  slug: 'retainedPayments',
                  endpoint: 'campanas/DASHBOARD_PAGOS',
                  ord: 'ord',
                  data: {
                    usuario: currentUser.getIn(['userData','username']),
                    empresa: getIdEnterprise(this.props),
                    id_campana: id,
                    fecha_inicio: currentCampaign.get('fecha_inicio'),
                    fecha_termino: currentCampaign.get('fecha_termino')
                  }
                }) }
                b64={ this.props.campaignStates.get('b64').get('retainedPayments') }
              >
                <IsFetching isFetching={ campaignStates.get('fetchingTabs').get('retainedPayments')} >
                  <DashboardLineChart 
                    data={ campaignStates.get('tabs').get('retainedPayments')} 
                    yAxisLabel={ 'Clientes' }
                    dataKeys={[
                      {
                        key: 'pago',
                        color: '#37C3F7'
                      },
                      {
                        key: 'no_pago',
                        color: '#FF53A2'
                      }
                    ]}
                  />
                </IsFetching>
              </ChartHolder>
            )
          }
        },
        {
          title: 'Respuestas',
          content: () => {
            return (
              // <div className="relative-position scrolled-box biggest min-box">
              <div className="relative-position  biggest min-box">
                <ResponseCharts
                  dataFunc={ this.props.getClientResponsesById.bind(null,userData.get('username'), id, currentCampaign.get('fecha_inicio'), currentCampaign.get('fecha_termino'),getIdEnterprise(this.props)) }
                  chartsData={ campaignStates.get('tabs').get('responses') }
                  isFetching={ campaignStates.get('tabs').get('isFetchingResponses') }
                  { ...this.props }
                />
              </div>
            )
          }
        }
      ]

    // }else{
    //   data = [
    //     {
    //       title: 'Contactos',
    //       content: () => {
    //         return (
    //           <ChartHolder
    //             title={ 'Clientes contactados' }
    //             getData={ this.props.getDashboardData.bind(null, {
    //               slug: 'clientsContacted',
    //               endpoint: 'campanas/DASHBOARD_CONTACTADOS',
    //               ord: 'ord',
    //               data: {
    //                 usuario: currentUser.getIn(['userData','username']),
    //                 empresa: getIdEnterprise(this.props),
    //                 id_campana: id,
    //                 fecha_inicio: currentCampaign.get('fecha_inicio'),
    //                 fecha_termino: currentCampaign.get('fecha_termino'),
    //                 reagendamiento: false
    //               }
    //             }) }
    //             b64={ this.props.campaignStates.get('b64').get('clientsContacted') }
    //           >
    //             <IsFetching isFetching={ campaignStates.get('fetchingTabs').get('clientsContacted')} >
    //               <DashboardBarChart 
    //                 data={ campaignStates.get('tabs').get('clientsContacted')} 
    //                 yAxisLabel={ 'Clientes' }
    //                 setup={ getBarChartSetup(features) }
    //               />
    //             </IsFetching>
    //           </ChartHolder>
    //         )
    //       }
    //     }
    //   ]
    // }

    return data;
  }


  render(){
    const { campaignStates, changeClientsTab } = this.props;

    return (
      <Tabs 
        activeTab={campaignStates.get('tabs').get('clientsActivetab')} 
        changeDashboardTab={changeClientsTab}
        buttonsHolderClass={ 'tabs-buttons-holder small inner' }
        tabsHolderClass={'tabs-panels-holder'}
        tabs = {this.getTabs()}
      />
    );
  }
}

SingleClientsTabs.propTypes = {
  currentCampaign: PropTypes.object
}
