import React, { Component } from 'react';
import { getIdEnterprise } from '../../utilities/utilities';

export default class CampaignLoadMoreButton extends Component{
  constructor(props){
    super(props);

    this.handleMore = this.handleMore.bind(this);
  }
  handleMore(){
    const { currentUser, entities, campaignStates } = this.props,
          userData = currentUser.get('userData'),
          campaignTemplateFilter = parseInt(campaignStates.get('setCampaignTemplateFilter'), 10) || '',
          campaignStatusFilter = campaignStates.get('setCampaignStatusFilter') || '',
          campaignFilter = campaignStates.get('setCampaignFilter') || '';

    this.props.getMoreCampaigns(userData.get('username'), (entities.get('campaigns').size), 20, campaignTemplateFilter, campaignStatusFilter, campaignFilter,getIdEnterprise(this.props));
  }
  render(){
    const { entities, campaignStates } = this.props;

    if(entities.get('campaigns').size >= 20 && !campaignStates.get('error')){
      return (
        <div className="wrapper align-center">
          <button className="btn btn-secondary" onClick={ this.handleMore }>Cargar más</button>
        </div>
      )
    }

    return null;
  }
}