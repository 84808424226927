import * as Immutable from 'immutable';

export function loginStates(state = Immutable.fromJS({
  changeLoginPass: false,
  isFetching: false,
  isFetchingRecoverPass: false,
  formData: {
    email: '',
    password: '',
    emailForgot: '',
    newPassword: ''
  },
  invalidFields: [],
  errorMessage: '',
  errorMessageStatus: ''
}), action) {
  switch (action.type) {
    case 'LOGOUT':
      return state.set('changeLoginPass', false);
    case 'SET_LOGIN_FIELDS':
      const index = state.get('invalidFields').findIndex((item) => {
        return item === action.target
      });
      return state.withMutations((map) => {
        map.setIn(['formData', action.target], action.value)
          .deleteIn(['invalidFields', index.toString()]);
      });
    case 'SET_INVALID_INPUTS':
      return state.set('invalidFields', Immutable.fromJS(action.invalidFields));
    case 'FETCH_LOGIN_START':
      return state.withMutations((map) => {
        map.set('isFetching', true)
          .set('errorMessage', '')
          .set('errorMessageStatus', '')
      });
    case 'FETCH_LOGIN_ERROR':
      return state.withMutations((map) => {
        map.set('errorMessage', action.error)
          .set('isFetching', false)
          .set('errorMessageStatus', action.messageStatus)
      });
    case 'FETCH_LOGIN_END':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('formData', Immutable.fromJS({
            email: '',
            password: '',
            emailForgot: ''
          }));
      });
    case 'REMOVE_INVALID_FIELDS':
      const fieldIndex = state.get('invalidFields').findIndex((item) => {
        return item === action.field
      });
      return state.deleteIn(['invalidFields', fieldIndex.toString()]);
    case 'SEND_RECOVER_PASS_START':
      return state.set('isFetchingRecoverPass', true)
    case 'SEND_RECOVER_PASS_END':
      return state.withMutations((map) => {
        map.set('isFetchingRecoverPass', false)
          .set('errorMessage', action.message)
          .set('errorMessageStatus', action.messageStatus)
      });
    case 'SEND_RECOVER_PASS_ERROR':
      return state.withMutations((map) => {
        map.set('isFetchingRecoverPass', false)
          .set('errorMessage', action.message)
          .set('errorMessageStatus', action.messageStatus)
      });
    case 'FETCH_LOGIN_CHANGE_PASS':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('changeLoginPass', action.user)
      });
    default:
      return state;
  }
}
