import React, { Component } from 'react';

export default class FormButton extends Component{
  render(){
    const { formButton, formButtonHolder } = this.props;

    if(!formButton){ return null; }

    return(
      <div className={ formButtonHolder }>
        <button className={ formButton.buttonClass }>{ formButton.text }</button>
      </div>
    );
  }
}