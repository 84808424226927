import * as Immutable from 'immutable';

export function assistantStates(state = Immutable.fromJS({
  isFetching: false,
  isSendingMessage: false,
  cid: '',
  conversation: [],
  message: '',
  endSession: false,
  calendarDates: [],
  errorFetching: ''
}), action){
  switch(action.type){
    case 'GET_ASSISTANT_INTERACTIONS_START':
      return state.set('isFetching', true)
    case 'GET_ASSISTANT_INTERACTIONS_ERROR':
    return state.withMutations((map) => {
      map.set('isFetching', false)
        .set('errorFetching', action.error)

    });
    case 'GET_ASSISTANT_INTERACTIONS_END':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('cid', action.cid)
          .set('conversation', state.get('conversation').concat(Immutable.fromJS(action.conversation)))
          .set('isSendingMessage', false)
          .set('endSession', action.endSession)
          .set('calendarDates', Immutable.fromJS(action.calendarDates))
      });
    case 'GET_ASSISTANT_INTERACTIONS_FIRST_END':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('cid', action.cid)
          .set('conversation', Immutable.fromJS(action.conversation))
          .set('isSendingMessage', false)
          .set('endSession', action.endSession)
          .set('calendarDates', Immutable.fromJS(action.calendarDates))
      });
    case 'END_ASSISTANT_SESSION':
      return state.withMutations((map) => {
        map.set('isFetching', false)
          .set('cid', action.cid)
          .set('conversation', state.get('conversation').concat(Immutable.fromJS(action.conversation)))
          .set('isSendingMessage', false)
          .set('endSession', true)
      });
    case 'CHANGE_ASSISTANT_STATE':
      return state.set(action.target, action.value)
    case 'SEND_ASSISTANT_MESSAGE_START':
      const index = action.index,
            i = action.i;
      return state.withMutations((map) => {
        map.set('isSendingMessage', true)
          .set('message', '')
          .set('calendarDates', Immutable.fromJS([]))
          .set('conversation', state.get('conversation').concat(Immutable.fromJS(action.conversation)))
          .setIn(['conversation', index.toString(), 'showButton'], false)
      });
    default:
      return state;
  }
}