import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends Component{
  render(){
    const { field, invalidField, InvalidInputClass } = this.props,
          Tag = field.tag;

    return (
      <label className={field.labelClass +' relative-position '+ (invalidField ? InvalidInputClass : '')} key={'input-'+ field.name}>
        {field.positionLabel==='top'?<span className="label-like">{ field.label }</span>:null}
        <div className="checkbox-holder">
          <Tag 
            className={ field.fieldClass } 
            type={ field.type } 
            name={ field.name } 
            placeholder={ field.placeholder } 
            value={ field.value }
            onChange={ field.onChangeFunc }
            checked={ field.checked }
          />
          <span className="slider"></span>
        </div>
        {field.positionLabel!=='top'?<span className="label-like">{ field.label }</span>:null}
      </label>
    );
  }
}

Checkbox.propTypes = {
  field: PropTypes.object,
  invalidField: PropTypes.bool,
  InvalidInputClass: PropTypes.string,
}