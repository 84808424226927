import React, { Component } from 'react';

import InnerHeader from '../modules/inner-header';
import IsFetching from '../modules/is-fetching';
import UsersTable from './users-table';
import NotFoundData from '../modules/not-found-data';
import { getIdEnterprise } from '../../utilities/utilities';

export default class Users extends Component{
  constructor(props){
    super(props);

    this.handleNewUserButton = this.handleNewUserButton.bind(this);
  }
  componentWillMount(){
    const  { currentUser } = this.props;
    this.props.getUsers( currentUser.get('userData').get('username') , getIdEnterprise(this.props));
  }
  handleNewUserButton(){
    this.props.setModal('userForm');
  }
  getContent(){
    const { usersStates, entities, currentUser } = this.props,
          error = usersStates.get('error');
    if(error){
       return (
        <NotFoundData 
          text={ error }
          buttonText="Intentar nuevamente"
          buttonFunc={ this.props.getUsers.bind(currentUser.get('userData').get('username'), getIdEnterprise(this.props) ) }
        />
       );
    }else{
      return (
        <div>
          <InnerHeader title="Usuarios" counter={ entities.get('users').size } >
            <div className="header-actions-holder">
              <button className="btn btn-small m-l-10" onClick={ this.handleNewUserButton }>Nuevo Usuario</button>
            </div>
          </InnerHeader>
          <div className="inner-content">
            <div className="box inner">
              <UsersTable { ...this.props } />
            </div>
          </div>
        </div>
      );
    }
  }
  render(){
    const { usersStates } = this.props,
          content = this.getContent();

    return (
      <IsFetching isFetching={ usersStates.get('isFetching') }>
        { content }
      </IsFetching>
    );
  }
}