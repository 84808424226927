import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getEntitieElementById, getCampaignStatus, getCalendarData } from '../../utilities/utilities';

import InnerHeader from '../modules/inner-header';
import SingleCampaignTabs from './single-campaign-tabs';
import SingleClientsTabs from './single-clients-tabs';
import Simbology from '../modules/simbology';
import UsersCampaignList from './users-campaign-list';
import CampaignUserList from './campaign-user-list';
import CampaignReports from './campaign-reports';

import SingleCampaignActionButtons from './single-campaign/single-campaign-action-buttons';
import SingleCampaignAsideData from './single-campaign/single-campaign-aside-data';

import CampaignSingleContentChannels from './campaign-single-content-channels';

export default class CampaignSingleContent extends Component{
  constructor(props){
    super(props);
    
    this.editCampaign = this.editCampaign.bind(this);
  }
  editCampaign(){
    const { currentCampaign } = this.props;
    this.props.editCampaign(currentCampaign);
  }
  getCampaignReports(){
    const { currentCampaign } = this.props,
          status = currentCampaign.get('estado_campana');
    if(status == 'en curso' || status == 'finalizada' || status == 'cancelada'){
      return (
        <CampaignReports 
            title={ 'Estadísticas de apertura' }
            simbology={ true }
            {...this.props}
          >
            <SingleCampaignTabs
              currentCampaign={ currentCampaign }
              { ...this.props }
              />
          </CampaignReports>
      );
    }

    return null;
  }
  getClientsReports(){
    const { currentCampaign } = this.props,
          status = currentCampaign.get('estado_campana');

    if(status == 'en curso' || status == 'finalizada' || status == 'cancelada'){
      return (
        <CampaignReports
            simbology={ true }
            {...this.props}
          >
            <SingleClientsTabs 
                currentCampaign={currentCampaign}
                { ...this.props }
              />
          </CampaignReports>
      );
    }

    return null;
  }
  render(){
    if(!this.props.currentCampaign){ return null; }

    const { currentCampaign, campaignStates } = this.props,
          campaignStatus = getCampaignStatus(currentCampaign.get('estado_campana')),
          campaignReports = this.getCampaignReports(),
          clientsReports = this.getClientsReports();

    return(
      <div>
        <InnerHeader 
            title={'Campaña '+ currentCampaign.get('numero_campana')}
            disclaimerText={ currentCampaign.get('estado_campana') } 
            subtitle={ 'Creada el '+  currentCampaign.get('fecha_creacion') +' por '+ currentCampaign.get('usuario') }
            status={ campaignStatus }
            backButton={ this.props.history.goBack }
          >
          <SingleCampaignActionButtons { ...this.props } />
        </InnerHeader>
        <div className="row inner-content">
          <SingleCampaignAsideData { ...this.props } />
          <div className="grid-9">
            { campaignReports }
            { /* --comentado para Consorcio--    clientsReports */}
            <CampaignSingleContentChannels {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

CampaignSingleContent.propTypes = {
  currentCampaign: PropTypes.object
}
