import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { getIndex, getIdEnterprise } from '../../utilities/utilities';

import FormValidizr from '../form-validizr/form-validizr';
import Tooltip from '../modules/tooltip';
import IsFetching from '../modules/is-fetching';
import Disclaimer from '../modules/disclaimer';
import { getEnterprises } from '../../actions';

export default class UsersForm extends Component{
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.checkboxHandler = this.checkboxHandler.bind(this);
  }
  checkboxHandler(target, event){
    const { usersStates } = this.props,
          userForm = usersStates.get('userForm'),
          permissions = userForm.get('viewsPermissions'),
          value = event.target.value;

    if(permissions.includes(value)){
      const index = getIndex(value, permissions);
      this.props.removeUserPermission(index);
    }else{
      this.props.addUserPermission(value);
    }
    
  }
  handleSubmit(){
    const { usersStates, currentUser } = this.props,
          currentUserData = currentUser.get('userData'),
          userForm = usersStates.get('userForm');
    
    const userData = userForm.toJS();

    userData.permisos = 15;
    userData.usuario = currentUserData.get('username');
    userData.correo = userData.username;
    userData.rut = '';
    userData.empresa = getIdEnterprise(this.props);
    
    if(!usersStates.get('isEditing')){
      this.props.createUser(userData);
    }else{
      this.props.editUserData(userData);
    }
  }
  inputHandler(target, event){
    const { usersStates } = this.props,
          userForm = usersStates.get('userForm');

    let value = event.target.value;

    if(event.target.type == 'checkbox')
      value = !userForm.get(target);

    this.props.changeFormFields(target, value)
  }
  getDisclaimer(){
    const { usersStates } = this.props,
          error = usersStates.get('formError');

    if(error){
      return (
        <div className="wrapper horizontal">
          <Disclaimer type={ 'error' } content={ error } />
        </div>
      ) 
    }
    return null;
  }
  getFormFieldsets(){
    const { usersStates, currentUser, featuresStates } = this.props,
          userForm = usersStates.get('userForm'),
          userData = currentUser.get('userData'),
          permissions = userData.get('viewsPermissions'),
          features = featuresStates.get('features');
          

    let formFields = [
      {
        title: 'Datos personales',
        fieldsetClass: 'grid-6',
        data: [
          {
            tag: 'input',
            type: 'text',
            name: 'user-name',
            labelClass: 'grid-10',
            invalidSetup: {
              icons: {
                valid: 'icon-element check field-icon',
                invalid: 'icon-element rounded info red field-icon',
              },
              tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un nombre para el usuario" />
            },
            placeholder: 'Ingrese el nombre del usuario',
            value: userForm.get('name'),
            label: 'Nombre y apellido:',
            onChangeFunc: this.inputHandler.bind(null, 'name'),
            validators: ['required']
          },
          {
            tag: 'input',
            type: 'text',
            name: 'username',
            labelClass: 'grid-10',
            invalidSetup: {
              icons: {
                valid: 'icon-element check field-icon',
                invalid: 'icon-element rounded info red field-icon',
              },
              tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un email válido" />
            },
            placeholder: 'Ej: example@example.com',
            value: userForm.get('username'),
            label: 'Email:',
            onChangeFunc: this.inputHandler.bind(null, 'username'),
            validators: ['required', 'email']
          },
          {
            tag: 'input',
            type: 'password',
            name: 'user-password',
            labelClass: 'grid-10',
            invalidSetup: {
              icons: {
                valid: 'icon-element check field-icon',
                invalid: 'icon-element rounded info red field-icon',
              },
              tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar una contraseña con mínimo 8 caracteres" />
            },
            placeholder: 'Ingrese una contraseña',
            value: userForm.get('password'),
            label: 'Contraseña:',
            onChangeFunc: this.inputHandler.bind(null, 'password'),
            validators: usersStates.get('isEditing') ? ['minCharacters8'] : ['required', 'minCharacters8']
          }
        ]
      }
    ];
    
    if(permissions.includes('users')){
      const checkboxFields = [];

      if(features.get('dashboard')){
        checkboxFields.push({
          tag: 'input',
          type: 'checkbox',
          name: 'permissionDashboard',
          checked: userForm.get('viewsPermissions').includes('dashboard'),
          value: 'dashboard',
          label: 'Dashboard',
          onChangeFunc: this.checkboxHandler.bind(null, 'dashboard')
        });
      }

      if(features.get('templates')){
        checkboxFields.push({
          tag: 'input',
          type: 'checkbox',
          name: 'permissionTemplates',
          checked: userForm.get('viewsPermissions').includes('templates'),
          value: 'templates',
          label: 'Plantillas',
          onChangeFunc: this.checkboxHandler.bind(null, 'templates')
        })
      }

      if(features.get('campaigns')){
        checkboxFields.push({
          tag: 'input',
          type: 'checkbox',
          name: 'permissionCampaigns',
          checked: userForm.get('viewsPermissions').includes('campaigns'),
          value: 'campaigns',
          label: 'Campañas',
          onChangeFunc: this.checkboxHandler.bind(null, 'campaigns')
        });
      }

      if(features.get('users')){
        checkboxFields.push({
          tag: 'input',
          type: 'checkbox',
          name: 'permissionUsers',
          checked: userForm.get('viewsPermissions').includes('users'),
          value: 'users',
          label: 'Usuarios',
          onChangeFunc: this.checkboxHandler.bind(null, 'users')
        })
      }

      formFields.push({
        title: 'Permisos de usuario',
        fieldsetClass: 'grid-6',
        data: checkboxFields
      })
    }

    return formFields;
  }
  render(){
    const { usersStates } = this.props,
          userForm = usersStates.get('userForm'),
          title = usersStates.get('isEditing') ? 'Editar usuario' : 'Nuevo usuario',
          buttonText = usersStates.get('isEditing') ? 'Editar usuario' : 'Crear usuario',
          disclaimer = this.getDisclaimer(),
          formFieldsets = this.getFormFieldsets();

    return (
      <div>
        <header className="modal-header">
          <h3 className="modal-title">{ title }</h3>
        </header>
        { disclaimer }
        <IsFetching isFetching={ usersStates.get('isFetchingForm') } showChildren={ true }>
          <FormValidizr 
            formSubmitHandler={ this.handleSubmit }
            formButton={{
              buttonClass: 'btn',
              text: buttonText,
              holder: 'align-center'
            }}
            formFields={formFieldsets}
          />
        </IsFetching>
      </div>
    );
  }
}