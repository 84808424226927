export const apiUrl_auth = process.env.REACT_APP_API_URL_AUTH;
export const apiUrl_asistente = process.env.REACT_APP_API_URL_ASISTENTE;
export const apiUrl_campanas = process.env.REACT_APP_API_URL_CAMPANAS;
export const apiUrl_plantillas = process.env.REACT_APP_API_URL_PLANTILLAS;
export const apiUrl_integracion = process.env.REACT_APP_API_URL_INTEGRACION;
export const baseUrlEmailTemplate = process.env.REACT_APP_BASE_URL_EMAIL_TEMPLATE;


//export const baseUrlEmailTemplate = process.env.REACT_APP_BASE_URL_EMAIL_TEMPLATE;
// export const apiUrl_auth = "https://auth-dev.mycognitiva.io/"
// export const apiUrl_asistente = "https://campana-dev.mycognitiva.io/"
// export const apiUrl_campanas = "https://campana-dev.mycognitiva.io/"
// export const apiUrl_plantillas = "https://plantillas-dev.mycognitiva.io/"
// export const apiUrl_integracion = "https://integraciones-dev.mycognitiva.io/"
// export const baseUrlEmailTemplate = "https://campana-dev.mycognitiva.io/email-config"



