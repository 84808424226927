import React, { Component } from 'react';
import NotFoundData from '../modules/not-found-data';
import Tooltip from '../modules/tooltip';
import FormValidizr from '../form-validizr/form-validizr';
import Disclaimer from '../modules/disclaimer';
import { getIndex, getIdEnterprise } from '../../utilities/utilities';

export default class AdminEnterpriseEdit extends Component {
    constructor(props) {
        super(props);
        this.checkboxHandler = this.checkboxHandler.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    checkboxHandler(target, event) {
        const { adminEnterprisesStates } = this.props,
            features = adminEnterprisesStates.getIn(['enterpriseForm', 'features']),
            value = event.target.value;
        if (features.includes(value)) {
            const index = getIndex(value, features);
            this.props.removeEnterpriseFeature(index);
        } else {
            this.props.addEnterpriseFeature(value);
        }
    }
    handleSubmit() {
        const { adminEnterprisesStates } = this.props,
            data = adminEnterprisesStates.get('enterpriseForm').toJS(),
            featuresEnterprise = adminEnterprisesStates.getIn(['enterpriseForm', 'features']),
            allFeatures = adminEnterprisesStates.get('allFeatures');
        allFeatures.forEach(feat => {
            data[feat] = featuresEnterprise.includes(feat) ? 1 : 0;
        });
        data.activada = data.activada ? 1 : 0;
        delete data.features;
        this.props.createEnterprise(data, "edit");
    }
    inputHandler(target, event) {
        const { adminEnterprisesStates } = this.props,
            enterpriseForm = adminEnterprisesStates.get('enterpriseForm');
        let value = event.target.value;

        if (event.target.type == 'checkbox')
            value = !enterpriseForm.get(target);

        this.props.changeFormFieldsEnterprise(target, value)
    }
    getFormFieldsets() {
        const { adminEnterprisesStates } = this.props,
            enterpriseForm = adminEnterprisesStates.get('enterpriseForm');

        let formFields = [
            {
                title: 'Datos empresa',
                fieldsetClass: 'grid-6',
                data: [
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'enterprise-nombre',
                        labelClass: 'grid-10',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un nombre para la empresa" />
                        },
                        placeholder: 'Ingrese el nombre de la empresa',
                        value: enterpriseForm.get('nombre'),
                        label: 'Nombre:',
                        onChangeFunc: this.inputHandler.bind(null, 'nombre'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'checkbox',
                        name: 'activada',
                        labelClass: 'grid-10',
                        checked: enterpriseForm.get('activada'),
                        value: enterpriseForm.get('activada'),
                        label: 'Activar',
                        positionLabel: 'top',
                        onChangeFunc: this.inputHandler.bind(null, 'activada')
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'enterprise-workspace-id',
                        labelClass: 'grid-10',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un workspace id para la empresa" />
                        },
                        placeholder: 'Ingrese el workspace id de la empresa',
                        value: enterpriseForm.get('workspace_id'),
                        label: 'Workspace ID:',
                        onChangeFunc: this.inputHandler.bind(null, 'workspace_id'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'enterprise-workspace-user',
                        labelClass: 'grid-10',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un workspace user para la empresa" />
                        },
                        placeholder: 'Ingrese el workspace user de la empresa',
                        value: enterpriseForm.get('workspace_user'),
                        label: 'Workspace user:',
                        onChangeFunc: this.inputHandler.bind(null, 'workspace_user'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'enterprise-workspace-password',
                        labelClass: 'grid-10',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un workspace password para la empresa" />
                        },
                        placeholder: 'Ingrese el workspace password de la empresa',
                        value: enterpriseForm.get('workspace_password'),
                        label: 'Workspace password:',
                        onChangeFunc: this.inputHandler.bind(null, 'workspace_password'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'enterprise-database-name',
                        labelClass: 'grid-10',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un database name para la empresa" />
                        },
                        placeholder: 'Ingrese el database name de la empresa',
                        value: enterpriseForm.get('database_name'),
                        label: 'Database name:',
                        onChangeFunc: this.inputHandler.bind(null, 'database_name'),
                        validators: ['required'],
                        disabled: true
                    },
                ]
            },

        ];

        formFields.push({
            title: 'Features de empresa',
            fieldsetClass: 'grid-6',
            data: this.fillFeatures()
        })
        return formFields;
    }
    getLabelFeature = (feature) => {
        switch (feature) {
            case 'enable_dashboard':
                return 'Dashboard'
            case 'enable_templates':
                return 'Plantillas'
            case 'enable_campaigns':
                return 'Campañas'
            case 'enable_users':
                return 'Usuarios'
            case 'enable_assistant':
                return 'Asistente'
            case 'enable_concurrency_setup':
                return 'Concurrency setup'
            case 'enable_payment_method':
                return 'Método de pago'
            case 'enable_whatsapp':
                return 'Whatsapp'
            case 'enable_edit_button':
                return 'Editar botón correo'
            case 'enable_customers_assigned':
                return 'Clientes asignados'
            case 'enable_channel_voice':
                return 'Voz'
            default:
                break;
        }
    }
    fillFeatures = () => {
        const { adminEnterprisesStates, } = this.props,
            features_form = adminEnterprisesStates.getIn(['enterpriseForm', 'features']),
            allFeatures = adminEnterprisesStates.get('allFeatures');
        return allFeatures.map(feat => {
            return (
                {
                    tag: 'input',
                    type: 'checkbox',
                    name: feat,
                    checked: features_form.includes(feat),
                    value: feat,
                    label: this.getLabelFeature(feat),
                    onChangeFunc: this.checkboxHandler.bind(null, feat)
                }
            )
        });
    }
    //Debe crear empresas y sus features
    getContent = () => {
        const { adminEnterprisesStates } = this.props,
            error = adminEnterprisesStates.get('error'),
            formFieldsets = this.getFormFieldsets();
        let content = null;

        if (error) {
            content = (
                <NotFoundData
                    text={error}
                    buttonText="Intentar nuevamente"
                    buttonFunc={this.fetchData}
                />
            );
        } else {
            content = (
                <FormValidizr
                    formSubmitHandler={this.handleSubmit}
                    formButton={{
                        buttonClass: 'btn',
                        text: "Editar empresa",
                        holder: 'align-center'
                    }}
                    formFields={formFieldsets}
                    formButtonHolder='align-center m-t-20'
                    formHolderClass="inner-header-content-holder row"
                />
            )
        }
        return content;
    }
    getSuccessMessage() {
        const { adminEnterprisesStates } = this.props,
            message = adminEnterprisesStates.get('messageForm');
        if (message.length > 0)
            return <Disclaimer type={'success'} content={message} />
    }
    getWarningMessage() {
        const { adminEnterprisesStates } = this.props,
            message = adminEnterprisesStates.get('formError');
        if (message.length > 0)
            return <Disclaimer type={'error'} content={message} />
    }
    render() {
        const content = this.getContent(),
            successMessage = this.getSuccessMessage(),
            warningMessage = this.getWarningMessage();
        return (
            <div className="inner-content">
                {successMessage}
                {warningMessage}
                <div className="box">
                    {content}
                </div>
            </div>
        )
    }
}
