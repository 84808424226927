import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class UserDropdownNavigation extends Component{
  
  render(){
    const  { featuresStates } = this.props,
    features = featuresStates.get('features');
    const adminLink = features.get('admin')?<li><Link to={'/admin-enterprise'}>Administración de empresas</Link></li>:null;
    return (
      <ul>
        {adminLink}
        <li><Link to={'/users'}>Usuarios</Link></li>
        <li><Link to={'/asistente'}>Asistente</Link></li>
        <li><Link to={'/configuracion/email'}>Plantillas de email</Link></li>
      </ul>
    );
  }
}