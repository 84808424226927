import React, { Component } from 'react';
import moment from 'moment';

import ResponseCharts from '../charts/response-charts'
import { getIdEnterprise } from '../../utilities/utilities';

export default class ClientResponses extends Component{
  render(){
    const { dashboardStates, currentUser } = this.props,
          startDate = moment(dashboardStates.get('startDate')).format('YYYY/MM/DD'),
          endDate = moment(dashboardStates.get('endDate')).format('YYYY/MM/DD');
    const dataForDownloadCSV = {
      usuario: currentUser.getIn(['userData','username']),
      fecha_inicio: startDate,
      fecha_termino: endDate,
      id_campana: '',
      empresa: getIdEnterprise(this.props)
    }

    return (
      <div className="chart-holder position-relative">
        <ResponseCharts
          dataFunc={ this.props.getClientResponses.bind(null, currentUser.get('userData').get('username'), startDate, endDate,getIdEnterprise(this.props)) }
          chartsData={ dashboardStates.get('data').get('clientResponses') }
          chartHoldersClass={'grid-6'}
          isFetching={ dashboardStates.get('isFetchingData').get('clientResponses') }
          { ...this.props }
          dataForCSVCall={dataForDownloadCSV}
        />
      </div>
    );
  }
}