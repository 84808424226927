import React, { Component } from 'react';
import Immutable from 'immutable';
import { getSendDates, getIdEnterprise } from '../../../utilities/utilities';

import NavigationPrompt from '../../modules/navigation-prompt'
import IsFetching from '../../modules/is-fetching';
import InnerHeader from '../../modules/inner-header';
import StepForm from '../../modules/step-form';
import StepFormTemplates from '../step-form-templates';
import StepFormSettings from '../step-form-settings';
import StepFormConfirm from '../step-form-confirm';
import StepFormChannels from '../step-form-channels';
import CustomPrompt from '../../modules/custom-prompt';

export default class NewCampaign extends Component{
  constructor(props){
    super(props);

    this.state = {
      sendDates: []
    }
  }
  componentWillReceiveProps(nextProps){
    const sendDates = getSendDates(nextProps.campaignStates.get('campaignForm')),
          oldSendDates = getSendDates(this.props.campaignStates.get('campaignForm'));

    if(!Immutable.is(sendDates, oldSendDates)){
      this.setState({ sendDates: sendDates })
    }
  }
  componentWillMount(){
    const  { currentUser } = this.props,
           userData = currentUser.get('userData');

    this.props.cleanNewCampaign();
    this.props.getTemplates(userData.get('username'),getIdEnterprise(this.props));
    this.props.getEmailTemplates(userData.get('username'),getIdEnterprise(this.props));
    this.props.getEmailTemplatesConfig(userData.get('username'),getIdEnterprise(this.props));
  }
  render(){
    const { campaignStates, templatesStates, channelsConfigStates } = this.props;

    return(
      <IsFetching isFetching={templatesStates.get('isFetching') || channelsConfigStates.get('isFetching') ||channelsConfigStates.get('isFetchingConfig') }>
        <NavigationPrompt when={campaignStates.get('activeStep') != 0}>
          {({ onConfirm, onCancel }) => (
            <CustomPrompt
              title={ '¿Estas seguro que quieres salir de esta página?' }
              description={ 'Se perderán los cambios realizados' }
              onConfirm={ onConfirm }
              onCancel={ onCancel }
            />
          )}
        </NavigationPrompt>
        <InnerHeader 
          title={'Nueva Campaña'}
          backButton={ this.props.history.goBack }
          additionalClass={ 'no-margin' }
        />
        <StepForm
          changeStepFunc={ this.props.changeCampaignStepForm } 
          activeStep={ campaignStates.get('activeStep') }
          steps={[
            {
              title: '1. Plantilla y clientes',
              content: () => {
                return (
                  <StepFormTemplates {...this.props} />
                )
              }
            },
            {
              title: '2. Configuración',
              content: () => {
                return <StepFormSettings {...this.props}/>
              }
            },
            {
              title: '3. Canales',
              content: () => {
                return (
                  <StepFormChannels {...this.props}/>
                );
              }
            },
            {
              title: '4. Confirmación',
              content: () => {
                return <StepFormConfirm {...this.props} sendDates={ this.state.sendDates }/>
              }
            }
          ]}
        />
      </IsFetching>
    );
  }
}