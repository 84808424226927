import React, { Component } from 'react';
import moment from 'moment';

import { getSimbology, getIdEnterprise } from '../../utilities/utilities';

import Simbology from '../modules/simbology';
import ChartHolder from '../charts/chart-holder';
import Tooltip from '../modules/tooltip';
import DashboardBarChart from '../charts/bar-chart';
import DashboardLineChart from '../charts/line-chart';
import ClientsResponses from './client-responses';
import IsFetching from '../modules/is-fetching.jsx';
import Tabs from '../modules/tabs';

export default class DashboardClients extends Component{
  constructor(props){
    super(props);

    this.changeTabDashboard = this.changeTabDashboard.bind(this);
  }
  changeTabDashboard(index, target, event){
    this.props.changeTabsDashboard(target, index);
  }
  getDataForDownloadCSV(conReagentamiento){
    const { dashboardStates, currentUser } = this.props,
          startDate = moment(dashboardStates.get('startDate')).format('YYYY/MM/DD'),
          endDate = moment(dashboardStates.get('endDate')).format('YYYY/MM/DD');

    const dataForDownloadCSV = {
      usuario: currentUser.getIn(['userData','username']),
      fecha_inicio: startDate,
      fecha_termino: endDate,
      id_campana: '',
      empresa: getIdEnterprise(this.props)
    }
    if(conReagentamiento){
      dataForDownloadCSV['reagendamiento'] = true;
    }
    return dataForDownloadCSV;
  }
  render(){
    const { dashboardStates, currentUser, featuresStates } = this.props,
          features = featuresStates.get('features').get('campaignsFeatures'),
          colors = ['#4EA55D', '#FAAF00', '#F73742'],
          startDate = moment(dashboardStates.get('startDate')).format('YYYY/MM/DD'),
          endDate = moment(dashboardStates.get('endDate')).format('YYYY/MM/DD');
    return(
      <div className="row">
        <div className="m-b-30">
          <h3 className="relative-position">
            Contactos
            <Simbology items = {getSimbology(features.get('enableWhatsapp'), features.get('enableVoice'))} />
          </h3>
          <Tabs 
            activeTab={dashboardStates.get('tabs').get('clients')} 
            changeDashboardTab={this.changeTabDashboard.bind(null, 'clients')}
            buttonsHolderClass={ 'tabs-buttons-holder small inner' }
            tabsHolderClass={'tabs-panels-holder'}
            tabs = {[
              {
                title: 'Agendamiento',
                content: () => {
                  return (
                    <ChartHolder
                      title={ 'Clientes contactados' }
                      getData={ this.props.getDashboardData.bind(null, {
                        slug: 'clientsContacted',
                        endpoint: 'campanas/DASHBOARD_CONTACTADOS',
                        ord: 'ord',
                        data: {
                          usuario: currentUser.getIn(['userData','username']),
                          empresa: getIdEnterprise(this.props),
                          id_campana: '',
                          fecha_inicio: startDate,
                          fecha_termino: endDate,
                          reagendamiento: false
                        }
                      }) }
                      kpis={ this.props.dashboardStates.get('kpis').get('clientsContacted') }
                      dataForCSVCall={this.getDataForDownloadCSV()}
                      csvType={"contactados"}
                    >
                      <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('clientsContacted') } >
                        <DashboardBarChart 
                          data={ dashboardStates.get('data').get('clientsContacted') } 
                          yAxisLabel={ 'Clientes' }
                        />
                      </IsFetching>
                    </ChartHolder>
                  )
                }
              },
              {
                title: 'Reagendamiento',
                content: () => {
                  return (
                    <ChartHolder
                      title={ 'Clientes contactados' }
                      getData={ this.props.getDashboardData.bind(null, {
                        slug: 'clientsContacted',
                        endpoint: 'campanas/DASHBOARD_CONTACTADOS',
                        ord: 'ord',
                        data: {
                          usuario: currentUser.getIn(['userData','username']),
                          empresa: getIdEnterprise(this.props),
                          id_campana: '',
                          fecha_inicio: startDate,
                          fecha_termino: endDate,
                          reagendamiento: true
                        }
                      }) }
                      kpis={ this.props.dashboardStates.get('kpis').get('clientsContacted') }
                      dataForCSVCall={this.getDataForDownloadCSV(true)}
                      csvType={"contactados"}                    >
                      <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('clientsContacted') } >
                        <DashboardBarChart 
                          data={ dashboardStates.get('data').get('clientsContacted') } 
                          yAxisLabel={ 'Clientes' }
                        />
                      </IsFetching>
                    </ChartHolder>
                  )
                }
              }
            ]}
          />
        </div>
        <div className="m-b-30">
          <ChartHolder
            title={'Tasa de recupero'}
            getData={ this.props.getDashboardData.bind(null, {
              slug: 'recoverData',
              endpoint: 'campanas/DASHBOARD_RECUPERO',
              data: {
                usuario: currentUser.getIn(['userData','username']),
                empresa: getIdEnterprise(this.props),
                id_campana: '',
                fecha_inicio: startDate,
                fecha_termino: endDate
              }
            }) }
            dataForCSVCall={this.getDataForDownloadCSV()}
            csvType={"tasa_recupero"} 
            >
            <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('recoverData') } >
              <DashboardLineChart 
                data={ dashboardStates.get('data').get('recoverData') } 
                yAxisLabel={ 'Clientes' }
              />
            </IsFetching>
          </ChartHolder>
        </div>
        <div className="m-b-30">
          <ChartHolder
            title={ 'Pagos reagendados' }
            getData={ this.props.getDashboardData.bind(null, {
              slug: 'retainedPayments',
              endpoint: 'campanas/DASHBOARD_PAGOS',
              ord: 'ord',
              data: {
                usuario: currentUser.getIn(['userData','username']),
                empresa: getIdEnterprise(this.props),
                id_campana: '',
                fecha_inicio: startDate,
                fecha_termino: endDate
              }
            }) }
            dataForCSVCall={this.getDataForDownloadCSV()}
            csvType={"pagos_reagendados"}          
            >
            <IsFetching isFetching={ dashboardStates.get('isFetchingData').get('retainedPayments') } >
              <DashboardLineChart 
                data={ dashboardStates.get('data').get('retainedPayments') } 
                yAxisLabel={ 'Clientes' }
                dataKeys={[
                  {
                    key: 'pago',
                    color: '#37C3F7'
                  },
                  {
                    key: 'no_pago',
                    color: '#FF53A2'
                  }
                ]}
              />
            </IsFetching>
          </ChartHolder>
          <h3 className="relative-position">Respuestas del cliente</h3>
          <ClientsResponses { ...this.props } />
        </div>
      </div>
    );
  }
}