import React, { Component } from 'react';

import IsFetching from '../modules/is-fetching';

export default class AssistantDemo extends Component{
  render(){
    return(
      <IsFetching isFetching={ false }>
        <header className="modal-header">
          <h3 className="modal-title">Demo del asistente</h3>
        </header>
        <div className="modal-body">
          <iframe title="demo-assistant" className="iframe-demo" src="https://dev-cobranzas.mycognitiva.io/asistente/?id=0X4885CBBT0X50E"></iframe> 
        </div>
      </IsFetching>
    );
  }
}
