import React, { Component } from "react";
import PropTypes from "prop-types";
import Tooltip from "../modules/tooltip";

export default class BarPercent extends Component {
  getPercentBarCompleted() {
    const { total, completed, stateCampaign } = this.props;
    const cancelled = stateCampaign === "cancelada";

    if (cancelled && completed > total) {
      return 100;
    }

    return Math.round((completed * 100) / total);
  }

  getBarColor() {
    const { total, completed, stateCampaign } = this.props;
    const cancelled = stateCampaign === "cancelada";
    const procesando = Math.round((completed * 100) / total);
    const end = stateCampaign === "finalizada";

    if (cancelled) {
      return `#f4bcdc `;
    } else if (procesando < 100 && !end) {
      return " #fcecbc ";
    } else if (procesando == 100) {
      return " #e4f4d4 ";
    } else if (procesando < 100 && end) {
      return " #e4f4d4 ";
    }
  }

  render() {
    const percentBarCompleted = this.getPercentBarCompleted();
    const barColor = this.getBarColor();
    const isProcessing = percentBarCompleted < 100 && percentBarCompleted > 0;
    const isCancelled = this.props.stateCampaign === "cancelada";
    const completed = percentBarCompleted === 100;
    const send = percentBarCompleted === 0;
    const { stateCampaign , sendEnd ,campaing } = this.props;
    const  fechaTermino = new Date(campaing.get('fecha_termino'));
    const  fechaActual = new Date();
    const  terminoFecha =  fechaTermino < fechaActual ? true : false;
    const end = stateCampaign === "finalizada";

   
  

    return (
      <div className="box-bar">
        <span className="percent-bar-value right ">
          {percentBarCompleted + "%"}
        </span>
        <div className="percent-bar-holder">
          <div className="percent-bar">
            <span
              className="percent-bar-completed"
              style={{ width: `${percentBarCompleted}%`, background: barColor }}
            />

            {!isCancelled && !end && isProcessing && (
              <span className="processing-text">
                <span className="intermittent-effect">Enviando campaña</span>
              </span>
            )}

            {isCancelled && (
              <span className="cancel-text"> Campaña cancelada</span>
            )}

            {!isCancelled && end && completed &&  (
              <span className="completed-text">
                <Tooltip
                  type="white"
                  additionalClass="small in-field"
                  content={" Envío terminado a las " + sendEnd + " hrs"}
                />
                Campaña enviada
              </span>
            )}

             {!isCancelled &&  terminoFecha && !send && (
              <span className="completed-text">
                <Tooltip
                  type="white"
                  additionalClass="small in-field"
                  content={" Envío terminado a las " + sendEnd + " hrs"}
                />
                Campaña enviada
              </span>
            )} 

            {!isCancelled && send && (
              <span className="send-text"> Campaña no enviada</span>
            )}
            {isCancelled && send && (
              <span className="cancel-text"> Campaña cancelada</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BarPercent.propTypes = {
  total: PropTypes.number,
  completed: PropTypes.number,
  cancelled: PropTypes.bool,
};

BarPercent.defaultProps = {
  showValues: true,
};
