import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterField from '../modules/filter-field';

export default class UsersCampaignList extends Component{
  constructor(props){
    super(props);

    this.filterUser = this.filterUser.bind(this);
    this.loadExclutionList = this.loadExclutionList.bind(this);
  }
  loadExclutionList(event){
    event.preventDefault();
    this.props.setModal('exclutionListCampaign')
  }
  filterUser(event){
    this.props.filterUserCampaign(event.target.value)
  }
  getExclutionHandler(){
    const { campaign } = this.props;

    if(campaign.get('status') === 'Cancelada')
      return null;

    return <a href="#" title="Cargar lista de excluidos para esta campaña" onClick={ this.loadExclutionList }>Cargar lista de excluidos</a>;

  }
  render(){
    const { campaignStates } = this.props,
          exclusionHandler = this.getExclutionHandler();

    return (
      <div>
        <FilterField 
          labelClass="relative-position"
          name="client-filter"
          value={ campaignStates.get('userFilter') }
          onChangeFunc={ this.filterUser }
          placeholder = "Filtrar clientes..."
        />
        { exclusionHandler }
      </div>
    );
  }
}

UsersCampaignList.propTypes = {
  campaign: PropTypes.object
}