import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NotFoundData from './not-found-data';

export default class IsFetching extends Component{
  render(){
    const { isFetching, showChildren, children, error, errorFunc } = this.props;

    // let content;

    if(error){
      return (
          <NotFoundData 
            text={ error } 
            buttonText="Intentar nuevamente"
            buttonFunc={ errorFunc }
          />
      )
    }

    if(!isFetching){ return children }
    
    return(
      <div>
        { showChildren ? children : null }
        <div className="box-loader">
          <div className="loader"></div>
        </div>
      </div>
    );
  }
}

IsFetching.propTypes = {
  isFetching: PropTypes.bool,
  showChildren: PropTypes.bool,
  error: PropTypes.string,
  errorFunc: PropTypes.func
}