import * as Immutable from 'immutable';

export function assistantSettingsStates(state = Immutable.fromJS({
  isFetching: true,
  fetchError: '',
  headerFileName: '',
  headerFile: false,
  headerFileUrl: '',
  assistantFileName: '',
  assistantFile: false,
  assistantFileUrl: '',
  headerColor: '#4A90E2',
  titleColor: '#FFF',
  buttonsColor: '#4A90E2',
  assistantTitle: '',
  bubbleBackgroundColor: '#CCCCCC',
  bubbleColor: '#FFFFFF',
  type: 'persona',
  channel: sessionStorage.getItem('assistantType') ? sessionStorage.getItem('assistantType') : 'web',
  filteredType: 'persona',
  filteredChannel: sessionStorage.getItem('assistantType') ? sessionStorage.getItem('assistantType') : 'web'
}), action){
  switch(action.type){
    case 'SET_ASSISTANT_SETTINGS_STATE':
      return state.set(action.target, action.value)
    case 'SELECT_ASSISTANT_HEADER_IMAGE':
      return state.withMutations((map) => {
        map.set('headerFileName', action.file.name)
          .set('headerFile', Immutable.fromJS(action.file))
      });
    case 'SELECT_ASSISTANT_IMAGE':
      return state.withMutations((map) => {
        map.set('assistantFileName', action.file.name)
          .set('assistantFile', Immutable.fromJS(action.file))
      });
    case 'CHANGE_ASSISTANT_HEADER_COLOR':
      return state.set('headerColor', action.color.hex);
    case 'CHANGE_ASSISTANT_TITLE_COLOR':
      return state.set('titleColor', action.color.hex);
    case 'CHANGE_ASSISTANT_BUTTON_COLOR':
      return state.set('buttonsColor', action.color.hex);
    case 'CHANGE_ASSISTANT_TITLE':
      return state.set('assistantTitle', action.value)
    case 'CHANGE_ASSISTANT_BUBBLE_BACKGROUND_COLOR':
      return state.set('bubbleBackgroundColor', action.value.hex)
    case 'CHANGE_ASSISTANT_BUBBLE_COLOR':
      return state.set('bubbleColor', action.value.hex);
    case 'GET_CUSTOM_ASSISTANT_DATA_START':
      return state.withMutations((map) => {
        map.set('isFetching', true)
          .set('fetchError', '')
      });
    case 'CHANGE_ASSISTANT_BUTTONS_COLOR':
      return state.set('buttonsColor', action.color.hex);
    case 'GET_CUSTOM_ASSISTANT_DATA_ERROR':
      return state.withMutations((map) => {
        map.set('fetchError', action.error)
          .set('isFetching', false)
      });
    case 'GET_CUSTOM_ASSISTANT_DATA_END':
      return state.withMutations((map) => {
        map.set('buttonsColor', action.data.color_botones)
          .set('bubbleBackgroundColor', action.data.color_fondo_burbuja)
          .set('bubbleColor', action.data.color_texto_burbuja)
          .set('headerColor', action.data.header_color_fondo)
          .set('titleColor', action.data.color_titulos)
          .set('assistantTitle', action.data.subtitulo)
          .set('headerFileUrl', action.data.imagen_cabecera)
          .set('assistantFileUrl', action.data.avatar_url)
          .set('isFetching', false)
      });
    case 'CHANGE_ASSISTANT_HEADER_FILE_URL':
      return state.withMutations((map) => {
        map.set('headerFileUrl', action.value)
          .set('headerFileName', '')
          .set('headerFile', false)
      });
    case 'CHANGE_ASSISTANT_AVATAR_FILE_URL':
      return state.withMutations((map) => {
        map.set('assistantFileUrl', action.value)
          .set('assistantFileName', '')
          .set('assistantFile', false)
      });
    case 'SUBMIT_CUSTOMIZATION_START':
      return state.set('isFetching', true);
    case 'SUBMIT_CUSTOMIZATION_END':
      return state.set('isFetching', false);
    case 'CLEAN_ASSISTANT_END':
      return state.withMutations((map) => {
        map.set('filteredType', action.args.tipo_cliente)
          .set('filteredChannel', action.args.tipo)
      })
    default:
      return state;
  }
}