import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import FallbackMessage from '../modules/fallback-message';

export default class DashboardLineChart extends Component{
  getLines(){
    const { dataKeys, id } = this.props;
    return dataKeys.map((data, index) => {
      return <Line key={`${ id }-${ index }`} type="monotone" dataKey={ data.key } stroke={ data.color } />
    });
  }
  render(){
    const data = this.props.data.toJSON(),
          lines = this.getLines();

    let content;

    if(data.length > 0){
      content = (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart width={600} height={300} data={data}
                margin={{top: 0, right: 0, left: 0, bottom: 0}}>
            <XAxis dataKey="name" />
            <YAxis axisLine={false} tickLine={false} tickFormatter={this.props.formatYAxis} label={{ value: this.props.yAxisLabel, angle: -90, position: 'insideLeft' }}/>
            <CartesianGrid vertical={ false }/>
            <Tooltip formatter={this.props.tooltipFormatter} />
            <Line type="monotone" dataKey="data" stroke="#37C3F7" />
            { lines }
          </LineChart>
        </ResponsiveContainer>
      )
    }else{
      content = <FallbackMessage content="No se han encontrado datos para visualizar" />
    }

    return content;
  }
}

DashboardLineChart.propTypes = {
  data: PropTypes.object,
  formatYAxis: PropTypes.func,
  tooltipFormatter: PropTypes.func,
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  dataKeys: PropTypes.array,
  id: PropTypes.string
}

DashboardLineChart.defaultProps = {
  id: `line-chart-${ document.getElementsByClassName("recharts-surface").length }`,
  formatYAxis: (tick) => { return tick },
  tooltip: (data) => { return data },
  dataKeys: [
    {
      key: 'email',
      color: '#37C3F7'
    },
    {
      key: 'sms',
      color: '#FF53A2'
    },
    {
      key: 'whatsapp',
      color: '#FAAF00'
    },
    {
      key: 'voz',
      color: '#e74c3c',
    }
  ]
}