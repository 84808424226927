import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class StepForm extends Component{
  constructor(props){
    super(props);

    this.changeStepFunc = this.changeStepFunc.bind(this);
  }
  changeStepFunc(index, event){
    event.preventDefault();
    const { activeStep, changeStepFunc } = this.props;

    if(activeStep > index)
      changeStepFunc(index);
  }
  getSteps(){
    const { steps, activeStep } = this.props;

    return steps.map((step, index) => {
      let activeClass = '';
      if(index === activeStep)
        activeClass = 'active';
      else if(index < activeStep)
        activeClass = 'success';

      return (
        <li key={'list-step-'+index} className={ activeClass }>
          <a href="/" onClick={this.changeStepFunc.bind(null, index)} >{ step.title }</a>
        </li>
      );
    });
  }
  getStepContent(){
    const { steps, activeStep } = this.props;

    return steps.map((step, index) => {
      let activeClass = '';

      if(index === activeStep){
        return (
          <div key={'panel-'+index} className={'step-form-panel tab-panel active'}>
            { step.content() }
          </div>
        )
      }
    });

  }
  render(){
    const steps = this.getSteps(),
          stepsContent = this.getStepContent();

    return(
      <div className="step-form-holder">
        <ol className="step-list">
         { steps }
        </ol>
        <div className="steps-panel-holder tabs-panels-holder">
          <div className="step-form">
            { stepsContent }
          </div>
        </div>
      </div>
    );
  }
}

StepForm.propTypes = {
  changeStepFunc: PropTypes.func,
  steps: PropTypes.array,
  activeStep: PropTypes.number
}